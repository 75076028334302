<template>
  <div class="pictogram-wrapper">
    <img :src="imagePath" class="pictogramImage" />
    <div v-if="showText">
      <div>{{ $t(pictogram.text) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    imagePath: {
      type: String,
      required: true,
    },
    showText: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["allPictograms"]),
    pictogram() {
      return this.allPictograms.find(
        (pictogram) => pictogram.image === this.imagePath
      );
    },
  },
};
</script>
<style scoped>
.pictogram-wrapper {
  display: flex;
  flex-direction: column;

  text-align: center;
  word-break: break-word;
}
.pictogramImage {
  width: 100%;
}
</style>
