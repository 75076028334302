<template>
  <v-dialog v-model="data.open" max-width="950px">
    <v-card v-if="data.item != null">
      <v-card-title>
        {{ data.item.title }}
        <div class="flex-grow-1"></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon>
              <v-icon
                  color="primary"
                  @click.stop="$emit('sendEmailTicket', data.item)"
                  v-on="on"
              >mail
              </v-icon
              >
            </v-btn>
          </template>
          <span>{{ $t("tickets.view.mailTicket") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon>
              <v-icon
                  color="primary"
                  @click.stop="$emit('editTicket', data.item)"
                  v-on="on"
              >edit
              </v-icon
              >
            </v-btn>
          </template>
          <span>{{ $t("tickets.view.editedTicket") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon>
              <v-icon
                  color="primary"
                  @click.stop="$emit('openDeleteDialog', data.item)"
                  v-on="on"
              >delete
              </v-icon
              >
            </v-btn>
          </template>
          <span>{{ $t("tickets.scrum.deleteTicket") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="9" md="9">
            <ticketViewMain
                @reloadData="$emit('reloadData')"
                :item="data.item"
            />
          </v-col>
          <!--sidebar  -->
          <v-col cols="12" sm="3" md="3" class="mt-4">
            <ticketViewSidebar :item="data.item"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="data.open = false" data-cy="back">
          {{ $t("general.back") }}
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ticketViewMain from "./ticketViewMain.vue";
import ticketViewSidebar from "./ticketViewSidebar.vue";

export default {
  components: {ticketViewMain, ticketViewSidebar},
  props: ["data"],
  data: () => ({}),
  methods: {},
};
</script>
