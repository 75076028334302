<template>
  <div class="d-flex flex-row">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editTicket(item)"
          data-cy="copy"
          v-if="item.type === 'ticket' && item.name != 'Interview wiederholen'"
          >edit</v-icon
        >
      </template>
      <span>{{ $t("tickets.form.editTickets")}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="runJobNow(item)"
          data-cy="copy"
          >play_arrow</v-icon
        >
      </template>
      <span>{{ $t("tickets.form.createTicketNow") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>{{ $t("tickets.form.deleteRecurringTicket") }}</span>
    </v-tooltip>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
    <ticketFormRecurring
      ref="ticketFormRecurring"
      :data="ticketFormRecurring"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
import ticketFormRecurring from "../../../views/tickets/recurringTickets/ticketFormRecurring.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
    ticketFormRecurring,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "Wiederholung löschen",
      description: "",
    },
    ticketFormRecurring: {
      open: false,
      title: "Wiederholende Aufgabe bearbeiten",
      item: null,
      method: "edit",
    },
  }),
  methods: {
    runJobNow(item) {
      this.$store.dispatch("executeJob", item);
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description =
        "Soll die Wiederholung " + item.name + " wirklich gelöscht werden?";
    },
    deleteItem(item) {
      this.$store.dispatch("deleteJob", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
    editTicket(item) {
      this.ticketFormRecurring = {
        open: true,
        title: "Wiederholende Aufgabe bearbeiten",
        item: null,
        method: "edit",
      };
      //clone table item to decouple reference from table
      this.$refs.ticketFormRecurring.triggerEdit(Object.assign({}, item));
    },
  },
};
</script>
