<template>
  <div>
    {{ dateUtil(new Date(item.createdAt), false, "FEHLER") }}
  </div>
</template>
<script>
import { dateUtil } from "../../api/api";
export default {
  props: ["item"],
  methods: {
    dateUtil,
  },
};
</script>
