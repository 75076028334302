<template>
  <!--translate-->
  <div>
    <v-card class="mx-auto my-4" max-width="800" flat outlined>
      <v-container class="mx-4 my-4">
        <v-row>
          <b>{{ $t("settings.profile.header") }}</b>
          <div class="flex-grow-1"></div>
        </v-row>
      </v-container>
      <v-form class="pa-4 pt-0" ref="form" lazy-validation>
        <p class="mt-6 mb-3">{{ $t("settings.profile.subheader") }}</p>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <formComponent
              ref="formComponent"
              :form="userForm"
              v-model="userForm.formData"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary" outlined @click="saveForm(false)">{{
          $t("general.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
function getDefaultData() {
  return {};
}
import formComponent from "../../components/_utils/formComponent/formComponent.vue";
export default {
  components: { formComponent },
  data: () => ({
    userForm: {
      formData: getDefaultData(),
      form: [
        [
          //v-cols
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-0",
            id: 1,
            componentName: "password",
          },
        ],
      ],
    },
  }),
  created() {},
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate forms
      if (this.$refs.formComponent.validateAllFields()) {
        //edit item
        if (this.userForm.formData.password != "") {
          this.$store
            .dispatch("updateUserPassword", this.userForm.formData)
            .then(() => {
              if (createNew) {
                //reset contact data to create new one
                this.resetData();
              } else {
                //close form
                this.closeform();
              }
            });
        }
      }
    },
    resetData() {
      //reset whole form data
      this.userForm.formData = getDefaultData();
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.formComponent.resetForm();
      }
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
    triggerEdit(item) {
      //set edit item from contactOverview Page trigger
      this.userForm.formData = item;
    },
  },
};
</script>
