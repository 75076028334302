<template>
  <div>
    <v-radio-group v-model="value.didStopWork">
      <template v-slot:label>
        {{ $t("incidentReports.incidentReportFormLong.didStopWork.label") }}
      </template>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.didStopWork.no')"
        value="no"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.didStopWork.immediately')"
        value="immediately"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.didStopWork.laterAt')"
        value="laterAt"
      ></v-radio>
      <div v-if="value.didStopWork === 'laterAt'" class="ml-7">
        <v-datetime-picker
          v-model="value.stoppedLaterDate"
          :label="$t('incidentReports.incidentDate')"
          prependIcon="event"
          :date-picker-props="dateProps"
          :time-picker-props="timeProps"
          dateFormat="dd.MM.yyyy"
          clearText="zurück"
          :dense="false"
          :hide-details="false"
        />
        
      </div>
    </v-radio-group>
  </div>
</template>
<script>
import vDatetimePicker from "../../../_utils/formComponent/generalFormComponents/v2DateTimePicker.vue";

export default {
  components: { vDatetimePicker },
  props: ["value"],
  data: () => ({
    datePickerMenu: false,
    timePickerMenu: false,
    dateProps: {
      locale: "de-de",
      "first-day-of-week": "1",
    },
    timeProps: {
      format: "24hr",
    },
  }),
  computed: {
    formattedDate() {
      return this.value.stoppedLaterDate
        ? new Date(this.value.stoppedLaterDate).toLocaleDateString("de-DE")
        : "";
    },
  },
  methods: {
    closeDatePicker() {
      this.datePickerMenu = false;
    },
    closeTimePicker() {
      this.timePickerMenu = false;
    },
  },
};
</script>
