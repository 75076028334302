import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axios from "axios";
import config from "./store/config/index.js";
import VueQuillEditor from "vue-quill-editor";
import { getTokenFromLocalStorage } from "./api.js";
import Embed from "v-video-embed";

import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import TableComponent from "./components/_utils/dataTableHelpers/tableComponent";
import Heading from "./components/layout/heading.vue";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//setup base axios data
axios.defaults.baseURL = config.state.backendServer;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = getTokenFromLocalStorage();

Vue.config.productionTip = false;

Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);
Vue.component("tableComponent", TableComponent);
Vue.component("heading", Heading);
Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(Embed);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
