<template>
  <div>
    <div class="mb-3 ml-4">Mitarbeiter aus einer Gruppe hinzufügen</div>
    <v-row class="mx-1">
      <v-col cols="12" md="9" sm="9">
        <v-autocomplete
          v-model="selectedGroup"
          :items="employeeGroupDataMap"
          :loading="allGroupsPending"
          hide-no-data
          item-text="Description"
          item-value="id"
          :label="$t('managedInstructionAssignment.group')"
          data-cy="group"
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <v-btn color="primary" small @click.stop="addEmployeesFromGroup">{{
          $t("managedInstructionAssignment.addGroup")
        }}</v-btn>
      </v-col>
    </v-row>
    <div>
      <v-card
        flat
        outlined
        v-for="(assignment, i) in value.assignments"
        v-bind:key="assignment.employee"
        class="ma-2"
      >
        <div class="ma-2 mt-5">
          <v-row>
            <v-col cols="12" md="8" sm="8">
              <v-autocomplete
                v-model="assignment.employee"
                :items="employeeOrderDataMap"
                :loading="allEmployeesPending"
                hide-no-data
                item-text="Description"
                item-value="id"
                :label="$t('managedInstructionAssignment.employee')"
                data-cy="employeeOrder"
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-btn color="primary" small @click.stop="addAssignment(i)">{{
                $t("managedInstructionAssignment.addEmployee")
              }}</v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    medium
                    class="ml-3"
                    v-on="on"
                    @click.stop="removeAssignment(i)"
                    data-cy="delete"
                  >
                    delete</v-icon
                  >
                </template>
                <span>{{
                  $t("managedInstructionAssignment.deleteEmployee")
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  components: {},
  data: () => ({
    selectedGroup: "",
  }),
  computed: {
    ...mapGetters([
      "allEmployees",
      "allEmployeesPending",
      "allGroups",
      "allGroupsPending",
    ]),
    employeeOrderDataMap() {
      return this.allEmployees.map((employee) => {
        const Description = employee.name;
        const id = employee._id;
        return Object.assign({}, employee, {
          Description,
          id,
        });
      });
    },
    employeeGroupDataMap() {
      return this.allGroups.map((group) => {
        const Description = group.name;
        const id = group._id;
        return Object.assign({}, group, {
          Description,
          id,
        });
      });
    },
  },
  created() {
    //get getAllEmployees
    this.$store.dispatch("getAllEmployees");
  },
  methods: {
    addAssignment(index) {
      this.value.assignments.splice(index + 1, 0, {
        employee: "",
        status: "ASSIGNED",
      });
    },
    removeAssignment(index) {
      this.value.assignments.splice(index, 1);
    },
    addEmployeesFromGroup() {
      if (this.selectedGroup != "" || this.selectedGroup != null) {
        this.$store
          .dispatch("getSingleEmployeeGroup", this.selectedGroup)
          .then((item) => {
            //if first field is empty, remove it
            if (this.value.assignments[0].employee == "string") {
              this.value.assignments.shift();
            }
            item.members.forEach((element) => {
              this.value.assignments.push({
                employee: element,
                status: "ASSIGNED",
              });
            });
            //clear selectedGroup
            this.selectedGroup = "";
          });
      }
    },
  },
};
</script>
