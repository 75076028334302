import axios from "axios";

const actions = {
  getAllBoards(context) {
    context.commit("SET_ALL_BOARDS");
    return axios
      .get("board")
      .then(function (r) {
        context.commit("SET_ALL_BOARDS", r.data.data);
        return r.data.data;
      })
      .catch(function (e) {
        context.commit("SET_ALL_BOARDS", e);
        context.dispatch("triggerError", e);
      });
  },

  addBoard(context, item) {
    return axios
      .post("board", item)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Board wurde erfolgreich erstellt");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
