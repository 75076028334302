<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="copyItem(item)"
          data-cy="copy"
          >content_copy</v-icon
        >
      </template>
      <span>{{ $t("dangerousGoods.storageLocation.copy") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit</v-icon
        >
      </template>
      <span>{{ $t("dangerousGoods.storageLocation.edit") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>{{ $t("dangerousGoods.storageLocation.delete") }}</span>
    </v-tooltip>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
  </div>
</template>

<script>
import deleteDialog from "../../../_utils/dataTableHelpers/actions/deleteDialog.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "dangerousGoods.storageLocation.delete",
      description: "",
    },
  }),
  methods: {
    editItem(item) {
      this.$router.push({
        name: "dangerousGoodsStorageLocationForm",
        params: { id: item.id, mode: "edit" },
      });
    },
    copyItem(item) {
      this.$router.push({
        name: "dangerousGoodsStorageLocationForm",
        params: { id: item.id, mode: "copy" },
      });
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description = this.$t(
        "dangerousGoods.storageLocation.deleteLong",
        {
          name: item.name,
        }
      );
    },
    deleteItem(item) {
      this.$store
        .dispatch("deleteDangerousGoodsStorageLocation", item)
        .then(() => {
          this.reloadDataFunc();
          this.deleteDialog.open = false;
        });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
  },
};
</script>
