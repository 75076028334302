<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6" class="pb-0">
        <v-text-field
          v-model="inputVal.name"
          :label="$t('settings.name')"
          data-cy="name"
          dense
          :rules="config.rules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="pb-0">
        <v-text-field
          autocomplete="username"
          v-model="inputVal.email"
          :label="$t('settings.email')"
          data-cy="email"
          dense
          :rules="emailRules"
        ></v-text-field>
      </v-col>
    </v-row>
    <password
      v-if="$route.params.mode === 'new'"
      :value="value"
      :config="config"
    />
  </div>
</template>
<script>
import password from "./password.vue";

export default {
  props: ["config", "value"],
  components: { password },
  data: () => ({
    emailRules: [
      (v) => !!v || "E-mail wird benötigt",
      (v) => /.+@.+\..+/.test(v) || "E-mail ungültig",
    ],
    roles: [
      {
        name: "Benutzer (Disponent oder Bürobenutzer)",
        value: "User",
      },
      { name: "Fahrer (nur für Fahrer App)", value: "AppUser" },
    ],
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>
