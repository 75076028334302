<template>
  <div>
    <v-stepper v-model="$route.params.step">
      <v-stepper-header>
        <v-stepper-step :complete="$route.params.step > 1" step="1">
          Paket auswählen
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="$route.params.step > 2" step="2">
          Zahlungsmethode wählen
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Übersicht </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="4"> Abschluss </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <planSelect />
          <v-row class="pb-10">
            <div class="flex-grow-1"></div>
            <v-btn color="primary" @click="increaseTab"> Continue </v-btn>

            <v-btn text @click="decreaseTab"> zurück </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <paymentData />
          <v-row class="pb-6">
            <div class="flex-grow-1"></div>
            <v-btn color="primary" @click="increaseTab"> Continue </v-btn>

            <v-btn text @click="decreaseTab"> zurück </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <div class="flex-grow-1"></div>
            <v-btn color="primary" @click="increaseTab"> Continue </v-btn>

            <v-btn text @click="decreaseTab"> zurück </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="4">
          finish
          <v-row>
            <div class="flex-grow-1"></div>
            <v-btn color="primary" @click="increaseTab"> Continue </v-btn>

            <v-btn text @click="decreaseTab"> zurück </v-btn>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import paymentData from "./paymentData.vue";
import planSelect from "./planSelect.vue";
export default {
  name: "customerDataForm",
  components: { paymentData, planSelect },
  data: () => ({
    portalData: null,
    order: null,
  }),
  mounted() {
    console.log(JSON.parse(this.$route.params.portalData));
  },
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    resetData() {
      //reset whole form data
      this.portalData = null;
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.formComponent.resetForm();
      }
    },
    decreaseTab() {
      this.$router.push({
        name: "changePlan",
        params: {
          portalData: this.$route.params.portalData,
          step: Number(this.$route.params.step) - 1,
          selected: {},
        },
      });
    },
    increaseTab(item) {
      //increase Tab
      this.$router.push({
        name: "changePlan",
        params: {
          portalData: this.$route.params.portalData,
          step: Number(this.$route.params.step) + 1,
          selected: {},
        },
      });
    },
  },
};
</script>
