<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" csm="6" md="6">
        <v-text-field
          v-model="password1"
          :label="$t('settings.password')"
          data-cy="password1"
          :rules="passRules"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          :error="passwordError"
          @click:append="show = !show"
          @input="setPassword()"
          dense
          autocomplete="new-password"
        ></v-text-field>
        <p v-if="password1 !== password2" class="ml-1 pt-2">
          <b>{{ $t("settings.passwordsNotCorrespond") }}</b>
        </p>
        <v-btn small class="ma-1 mt-0" @click="createStrongPassword">
          {{ $t("settings.savePassword") }}
        </v-btn>
      </v-col>
      <v-col cols="12" csm="6" md="6">
        <v-text-field
          v-model="password2"
          :label="$t('settings.reenterPassword')"
          data-cy="password2"
          :rules="passRules"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          :error="passwordError"
          @click:append="show = !show"
          @input="setPassword()"
          dense
          autocomplete="new-password"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["value", "config"],
  data: () => ({
    password1: "",
    password2: "",
    show: "",
    passwordError: false,
    passRules: [
      (v) => !!v || "Ein Passwort wird benötigt",
      (v) =>
        (!!v && v.length >= 8) ||
        "Das Passwort benötigt eine Mindestlänge von 8 Zeichen.",
    ],
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      //reset password when user object password is empty
      if (val.password === "") {
        this.password1 = "";
        this.password2 = "";
      }
    },
  },
  methods: {
    setPassword() {
      if (this.password1 === this.password2) {
        this.value.password = this.password1;
      }
    },
    createStrongPassword() {
      const sPass = Array(14)
        .fill(
          "0123456789ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz!@-#$"
        )
        .map(
          (x) =>
            x[
              Math.floor(
                (crypto.getRandomValues(new Uint32Array(1))[0] /
                  (0xffffffff + 1)) *
                  x.length
              )
            ]
        )
        .join("");
      //emit and set password
      this.value.password = sPass;
      this.password1 = sPass;
      this.password2 = sPass;
      //show password
      this.show = true;

      //try to copy password to clipboard
      navigator.clipboard.writeText(sPass).then(
        () => {
          this.$store.dispatch(
            "triggerSnackbar",
            this.$t("settings.passwordCopied")
          );
        },
        () => {
          console.error(this.$t("settings.passwordNotCopied"));
        }
      );
    },
  },
};
</script>
