<template>
  <div>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
            v-bind:value="computedDate"
            v-bind:label="label"
            v-on:click:clear="onClearInput"
            data-cy="computedDate"
            v-bind:prepend-icon="prependIcon"
            readonly
            v-bind:rules="rules"
            v-on="on"
            dense
            v-bind:disabled="disabled"
            hide-details
            clearable
        ></v-text-field>
      </template>
      <date-picker
          show-weeknumbers
          locale="de-DE"
          is24hr
          v-model="inputVal"
          @input="menu = false"
          color="green"
          :attributes="attrs"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
  props: ["value", "label", "prependIcon", "disabled", "rules"],
  data: () => ({
    menu: false,
    valid: true,
    attrs: [
      {
        key: "today",
        highlight: {
          color: "blue",
          fillMode: "light",
        },
        dates: new Date(),
      },
    ],
  }),

  computed: {
    inputVal: {
      get() {
        if (this.value == null) {
          return "";
        } else {
          return new Date(this.value).toISOString().substr(0, 10);
        }
      },
      set(val) {
        this.$emit("input", val ? new Date(val) : null);
      },
    },
    computedDate() {
      return this.formatDate(this.inputVal);
    },
  },

  methods: {
    onClearInput() {
      this.inputVal = null
    },
    formatDate(date) {
      if (date == null) {
        return "";
      }
      if (date.split("-").length < 3) {
        let dayFirst = date.slice(0, 1);
        let monthFirst = date.slice(3, 4);
        if (dayFirst == 0 || monthFirst == 0) {
          let year = date.slice(-4);
          let withoutYear = date.slice(0, 6);
          withoutYear = withoutYear.replace(/0/g, "");
          date = withoutYear + year;
        }
        return date;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
    },
  },
};
</script>
