<template>
  <div>
    <heading>
      {{ $t("sidebar.employees") }}
    </heading>
    <v-tabs v-model="$route.path">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ headers(tab.id) }}
      </v-tab>

      <v-tab-item
        v-for="tab of tabs"
        :key="tab.id"
        :value="tab.route"
        :transition="false"
        :reverse-transition="false"
      >
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          route: "/app/employee/employeeTable",
        },
        {
          id: 2,
          route: "/app/employee/employeeGroupTable",
        },
      ],
    };
  },
  methods: {
    headers(i) {
      switch (i) {
        case 1:
          return this.$t("employee.tabs.employee");
        default:
          return this.$t("employee.tabs.groups");
      }
    },
  },
};
</script>
