<template>
  <div v-if="value.med">
    <v-text-field
      v-model="value.medUsed"
      :label="$t('medkitlogs.whatWasUsed')"
      data-cy="medUsed"
      :rules="requieredRule"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({ requieredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")] }),
  methods: {},
};
</script>
