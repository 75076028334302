<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.riskAssessment") }}
    </heading>
    <riskAssessmentTable />
  </div>
</template>

<script>
import riskAssessmentTable from "@/views/riskAssessment/riskAssessmentTable.vue";
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
export default {
  name: "riskAssessment",
  components: { riskAssessmentTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.riskAssessment;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.riskAssessment;
      }
      return sidebarTextDE.default.riskAssessment;
    },
  },
};
</script>
