import axios from "axios";

const actions = {
  login(context, loginData) {
    return axios
      .post("login", loginData)
      .then((r) => {
        //set token
        localStorage.setItem("token", r.data.token);
        //set token to axios
        axios.defaults.headers.common["Authorization"] = r.data.token;
        //get user info after 1s timeot
        setTimeout(function () {
          context.dispatch("getUserInfo");
        }, 1000);
        return r.data;
      })
      .catch((err) => {
        context.dispatch("triggerError", err);
        return false;
      });
  },
  register(context, registerData) {
    return axios
      .post("register", registerData)
      .then(function () {
        context.commit("CREATE_SNACKBAR", "Nutzer erfolgreich registriert");
        return true;
      })
      .catch(function (e) {
        //react with individual error message on douplicate email
        if (
          e.response.data.message ===
          "Something went wrong Error Code 300, try using another email"
        ) {
          context.dispatch("triggerUniversalAlert", {
            text: "Diese Email kann für das Anlegen eines neuen Nutzers nicht verwendet werden.",
            heading: "Fehler - Email nicht verfügbar",
            type: "warning",
          });
        } else {
          //normal error
          context.dispatch("triggerError", e);
        }
        return false;
      });
  },

  getUserInfo(context) {
    return axios
      .get("info")
      .then(function (r) {
        //set data
        localStorage.setItem("userData", JSON.stringify(r.data));
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  resetPassword(context, postData) {
    return axios
      .post("reset", postData)
      .then(function (r) {
        context.dispatch("triggerUniversalAlert", {
          text: "Wenn die angegeben Email existiert, wurde dorthin eine Email mit weiteren Anweisungen versendet.",
          heading: "Email mit weiteren Anweisungen versendet",
          type: "info",
        });
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  setNewPassword(context, postData) {
    return axios
      .post("reset/" + postData.id, postData)
      .then(function (r) {
        //don't expect answer for sec reasons
        context.dispatch("triggerUniversalAlert", {
          text: "Das Passwort wurde erfolgreich geändert. Sie können sich nun mit diesem anmelden.",
          heading: "Passwort erfolgreich geändert",
          type: "info",
        });
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
