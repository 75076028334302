<template>
  <div>
    <tableComponent
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      @openForm="openForm"
    />
    <operatingInstructionForm
      ref="operatingInstructionForm"
      :data="operatingInstructionForm"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import tableComponent from "../../components/_utils/dataTableHelpers/tableComponent.vue";
import operatingInstructionForm from "./operatingInstructionForm.vue";
export default {
  components: {
    tableComponent,
    operatingInstructionForm,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "operatingInstructions.add",
          color: "secondary",
          action: "openForm",
          icon: "cloud_upload",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedDocumentType",
          componentName: "formattedDocumentType",
        },
        {
          id: getUniqueId(),
          slotName: "item.operationInstructionCreatedAt",
          componentName: "operationInstructionCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.operatingInstructionAction",
          componentName: "operatingInstructionAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "operatingInstruction",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "operatingInstructionForm",
    },
    operatingInstructionForm: {
      open: false,
      title: "Betriebsanweisung hinzufügen",
      item: null,
      method: "new",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("operatingInstructions.tableHeader.description"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("operatingInstructions.tableHeader.type"),
          value: "formattedDocumentType",
        },
        {
          text: this.$t("operatingInstructions.tableHeader.createdAt"),
          value: "operationInstructionCreatedAt",
        },
        {
          text: "",
          value: "operatingInstructionAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    openForm() {
      this.operatingInstructionForm.open = true;
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store
        .dispatch("deleteMultipleOperatingInstruction", item)
        .then(() => {
          this.reloadDataFunc();
          this.multipleDeleteDialog.open = false;
          this.multipleDeleteDialog.items = [];
        });
    },
  },
};
</script>
