<template>
  <div>
    <v-toolbar class="mx-4 mb-6" flat outlined rounded>
      <v-toolbar-title>{{ $t("tickets.navbar.recurringTickets") }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon color="secondary" target="_blank" href="https://help.safetyplanner.de/help/de-de">
            <v-icon v-on="on">support</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("general.support") }}</span>
      </v-tooltip>
    </v-toolbar>
  </div>
</template>
<script>
export default {};
</script>
