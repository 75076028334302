<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="$emit('submitForm')"
    >
      <v-row v-bind:key="rowIndex" v-for="(row, rowIndex) in form.form">
        <v-col
          v-bind:key="col.id"
          v-for="(col, colIndex) in row"
          :cols="row[colIndex].cols"
          :sm="row[colIndex].sm"
          :md="row[colIndex].md"
          :xl="row[colIndex].xl"
          :class="row[colIndex].class"
        >
          {{ col.vModel }}
          <component
            :is="col.componentName"
            :key="col.id"
            :config="col.config"
            v-model="inputVal"
            :ref="col.componentName"
            :autocomplete="col.autocomplete ?? ''"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import * as components from "../../../../src/components/formSlotComponents/index.js";
export default {
  components: {
    ...components,
  },
  props: ["form", "formData", "value"],
  data: () => ({
    valid: true,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {},
  methods: {
    validateAllFields() {
      return this.$refs.form.validate();
    },
    resetForm() {
      //use resetValidation() insted of reset() to only reset validation and not to remove preset values from fields
      return this.$refs.form.resetValidation();
    },
  },
};
</script>
