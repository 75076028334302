import axios from "axios";
import i18n from "../../i18n";

const actions = {
  getAllExaminations(context) {
    context.commit("SET_ALL_EXAMINATIONS");
    return axios
      .get("examination?analytics=1")
      .then(function (r) {
        context.commit("SET_ALL_EXAMINATIONS", r.data.data);
        return r.data.data;
      })
      .catch(function (e) {
        context.commit("SET_ALL_EXAMINATIONS", e);
        context.dispatch("triggerError", e);
      });
  },
  createExamination(context, value) {
    return axios
      .post("examination", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("examination.createdMessage", {
            name: r.data.name,
          })
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteExamination(context, val) {
    return axios
      .delete("examination/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", i18n.t("examination.deletedMessage"));
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateExamination(context, value) {
    return axios
      .put("examination/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("examination.updatedMessage", {
            name: r.data.name,
          })
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleExamination(context, id) {
    return axios
      .get("examination/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
