<template>
  <div>
    <v-row align="center" dense justify="end" justify-sm="start" class="my-1">
      <v-col
        v-for="member in shortMembers"
        v-bind:key="member._id"
        class="flex-grow-0"
      >
        <v-chip small>
          {{ member.name }}
        </v-chip>
      </v-col>
      <v-col v-if="item?.members?.length > 3">
        <v-btn
          small
          depressed
          rounded
          @click="$refs.employeeGroupTableEmployeeDialog.openDialog()"
        >
          {{
            $tc(
              "employeeGroup.employeeDialog.andMore",
              item.members.length - 3,
              { count: item.members.length - 3 }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <employee-group-table-employee-dialog
      ref="employeeGroupTableEmployeeDialog"
      :employees="item.members"
    />
  </div>
</template>
<script>
import EmployeeGroupTableEmployeeDialog from "@/views/managedInstruction/employee/employeeGroupTableEmployeeDialog.vue";

export default {
  components: {
    EmployeeGroupTableEmployeeDialog,
  },
  props: ["item"],
  computed: {
    shortMembers() {
      return this.item?.members?.length ? this.item.members.slice(0, 3) : [];
    },
  },
  methods: {},
};
</script>
