<template>
  <div>
    <div v-for="(area, i) in value?.areas ?? []" v-bind:key="i">
      <v-list dense subheader>
        <v-subheader class="mt-4">{{ area.name }}</v-subheader>

        <v-list-item
          v-for="(task, e) in area.tasks"
          v-bind:key="e"
          class="my-2"
        >
          <v-row class="no-gutters justify-space-between">
            <v-col class="col-12 col-sm-8">
              <v-list-item-title>{{ task.name }}</v-list-item-title>
              <!--v-list-item-subtitle>{{ area }}</v-list-item-subtitle-->
              <div>
                <v-textarea
                  class="mb-2 mt-2"
                  data-cy="content"
                  v-model="task.description"
                  dense
                  hide-details
                  outlined
                  label="Kommentar"
                  rows="1"
                  @change="updateInspection(i, e)"
                ></v-textarea>
              </div>
              <div>
                <v-file-input
                  label="Begehungsnachweis"
                  accept="image/*"
                  outlined
                  dense
                  prepend-icon="mdi-camera"
                  :value="defaultValueInputs.get(`${i},${e}`)"
                  @change="handleFile($event, i, e)"
                ></v-file-input>
              </div>
            </v-col>
            <v-col class="col-12 col-sm-auto">
              <div>
                <v-radio-group v-model="task.ok" row class="mt-0 pt-0">
                  <v-radio color="success" :value="true">
                    <template v-slot:label>
                      <div style="color: #4caf50">i.O.</div>
                    </template>
                  </v-radio>
                  <v-radio color="#ff5252" :value="false">
                    <template v-slot:label>
                      <div style="color: #ff5252">n.i.O.</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div>
                <v-btn
                  color="primary"
                  class="mr-4 mb-3 w-100"
                  small
                  outlined
                  :disabled="!!task.ticket && !canUpdateTicket(i, e)"
                  @click="createTicket(task, i, e)"
                >
                  <span v-if="task.ticket == null">
                    {{ $t("inspections.createTicket") }}
                    <v-icon class="ml-1">launch</v-icon>
                  </span>
                  <span v-else-if="canUpdateTicket(i, e)">
                    {{ $t("inspections.updateTicket") }}
                    <v-icon class="ml-1">sync</v-icon>
                  </span>
                  <span v-else>
                    {{ $t("inspections.ticketCreated") }}
                    <v-icon class="ml-1">check</v-icon>
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-divider class="mt-6" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["value"],
  components: {},
  data: () => ({
    defaultValueInputs: new Map(),
    canUpdateTickets: {},
  }),
  created() {
    this.setDefaultInputs();
  },
  methods: {
    setCanUpdateTicket(i, e, value) {
      this.canUpdateTickets[`${i},${e}`] = value;

      this.$forceUpdate();
    },
    canUpdateTicket(i, e) {
      return this.canUpdateTickets[`${i},${e}`];
    },
    updateInspection(areaId, taskId) {
      if (typeof areaId === "number" && typeof taskId === "number") {
        this.setCanUpdateTicket(areaId, taskId, true);
      }
      return this.$store.dispatch("updateInspection", {
        ...this.value,
        id: this.value._id,
      });
    },
    async setDefaultInputs() {
      for (const [areaIndex, area] of this.value?.areas?.entries() ?? []) {
        for (const [taskIndex, task] of area.tasks?.entries() ?? []) {
          if (task.proof) {
            const file = new File([], task.proof.name, {
              type: task.proof.filetype,
            });
            this.defaultValueInputs.set(`${areaIndex},${taskIndex}`, file);
          }
        }
      }
    },
    getDefaultValue(areaId, taskId) {
      const proof = this.value.areas[areaId].tasks[taskId].proof;
      if (!proof) return null;
      return new File([], proof.name, {
        type: proof.filetype,
      });
    },
    async createTicket(task, areaId, taskId) {
      try {
        const item = await this.$store.dispatch(
          "getSettingData",
          "standardOpenBoard"
        );
        if (task.ticket && this.canUpdateTicket(areaId, taskId)) {
          let ticketData = {
            title: task.name,
            description: task.description,
            board: item.value,
            _id: task.ticket._id,
          };
          const r = await this.$store.dispatch("updateTicket", ticketData);
          if (
            !task.ticket.attachments.find(
              (a) => a.location === task.proof.location
            )
          ) {
            await this.$store.dispatch("uploadAttachmentToTicketFromTask", {
              id: this.value.id,
              ticketId: r._id,
              ...task.proof,
            });
          }
        } else {
          let ticketData = {
            recurring: 0,
            title: task.name,
            description: task.description,
            dueDate: null,
            users: [],
            priority: 1,
            board: item.value,
          };
          const r = await this.$store.dispatch("addTicket", ticketData);
          this.value.areas[areaId].tasks[taskId].ticket = r;

          if (task.proof) {
            this.value.areas[areaId].tasks[taskId].ticket =
              await this.$store.dispatch("uploadAttachmentToTicketFromTask", {
                id: this.value.id,
                ticketId: r._id,
                ...task.proof,
              });
          }

          await this.updateInspection();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.setCanUpdateTicket(areaId, taskId, false);
      }
    },
    async handleFile(file, areaId, taskId) {
      if (!file) {
        this.value.areas[areaId].tasks[taskId].proof = null;
      } else {
        try {
          const fileData = await this.createBase64(file);
          this.value.areas[areaId].tasks[taskId].proof = fileData;
        } catch (error) {
          console.log(error);
        }
      }
      const updatedInspection = await this.updateInspection(areaId, taskId);
      this.value.areas[areaId].tasks[taskId].proof =
        updatedInspection.areas[areaId].tasks[taskId].proof;
    },
    async createBase64(fileObject) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({
            filetype: fileObject.name.match(/\.[0-9a-z]+$/i)[0].split(".")[1],
            name: fileObject.name,
            content: e.target.result.split(",")[1],
          });
        };
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(fileObject);
      });
    },
  },
};
</script>
