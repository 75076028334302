import StoreUtil from "../store.util.js";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  portalData: StoreUtil.state(),
  productData: StoreUtil.state(),
};

const subscriptionJsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default subscriptionJsModule;
