<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.dangerousGoods") }}
    </heading>

    <dangerousGoodsStorageLocationTable />
  </div>
</template>

<script>
import dangerousGoodsStorageLocationTable from "@/views/dangerousGoods/dangerousGoodsStorageLocationTable.vue";
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
export default {
  name: "dangerousGoodsStorageLocation",
  components: { dangerousGoodsStorageLocationTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.dangerousGoods;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.dangerousGoods;
      }
      return sidebarTextDE.default.dangerousGoods;
    },
  },
};
</script>
