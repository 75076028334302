import axios from "axios";

const actions = {
  getAllTickets(context) {
    context.commit("SET_ALL_TICKETS");
    return axios
      .get("ticket")
      .then(function (r) {
        context.commit("SET_ALL_TICKETS", r.data);
        return r.data.data;
      })
      .catch(function (e) {
        context.commit("SET_ALL_TICKETS", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleTicket(context, id) {
    return axios
      .get("ticket/" + id + "?populate=board,users,activitys.user")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getCommentsForTicket(context, id) {
    return axios
      .get("ticket/" + id + "?populate=comments.user")
      .then(function (r) {
        return r.data.comments;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getAttachmentsForTicket(context, id) {
    return axios
      .get("ticket/" + id + "?populate=")
      .then(function (r) {
        return r.data.attachments;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getBoard(context) {
    context.commit("SET_BOARD");
    return axios
      .get("board/tickets")
      .then(function (r) {
        context.commit("SET_BOARD", r.data);
        return r.data;
      })
      .catch(function (e) {
        context.commit("SET_BOARD", e);
        context.dispatch("triggerError", e);
      });
  },

  getBoardByUser(context, id) {
    context.commit("SET_BOARD");
    return axios
      .get("board/tickets?user=" + id)
      .then(function (r) {
        context.commit("SET_BOARD", r.data);
        return r.data;
      })
      .catch(function (e) {
        context.commit("SET_BOARD", e);
        context.dispatch("triggerError", e);
      });
  },

  addTicket(context, item) {
    return axios
      .post("ticket", item)
      .then(function (r) {
        context.dispatch("addActivity", { id: r.data._id, content: "1" });
        context.commit("CREATE_SNACKBAR", "Aufgabe wurde erfolgreich erstellt");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  addAttachmentsToTicket(context, item) {
    return axios
      .post("ticket/" + item.id + "/attachments", item)
      .then(function (r) {
        context.dispatch("addActivity", { id: item.id, content: "5" });
        context.commit(
          "CREATE_SNACKBAR",
          "Anhang wurde erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  sendTicketViaMail(context, item) {
    return axios
      .post("ticket/" + item.id + "/email", item)
      .then(function (r) {
        context.dispatch("addActivity", { id: item.id, content: "4" });
        context.commit("CREATE_SNACKBAR", "Email wurde erfolgreich versendet");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  removeAttachmentsfromTicket(context, item) {
    return axios
      .delete("ticket/" + item.id + "/attachments/" + item.location)
      .then(function (r) {
        context.dispatch("addActivity", { id: item.id, content: "7" });
        context.commit("CREATE_SNACKBAR", "Anhang wurde erfolgreich entfernt");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateTicket(context, item) {
    return axios
      .put("ticket/" + item._id, item)
      .then(function (r) {
        context.dispatch("addActivity", { id: r.data._id, content: "2" });
        context.commit(
          "CREATE_SNACKBAR",
          "Aufgabe wurde erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  addComment(context, item) {
    return axios
      .post("ticket/" + item.id + "/comments", item)
      .then(function (r) {
        context.dispatch("addActivity", { id: item.id, content: "8" });
        context.commit(
          "CREATE_SNACKBAR",
          "Kommentar wurde erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteTicket(context, val) {
    return axios
      .delete("ticket/" + val._id)
      .then(function (r) {
        context.dispatch("addActivity", { id: val._id, content: "3" });
        context.commit("CREATE_SNACKBAR", "Aufgabe erfolgreich gelöscht");
        return r;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  addActivity(context, item) {
    /*
    Activity contents:
    1 - Add Ticket
    2 - Edit Ticket
    3 - Delete Ticket
    4 - Send Ticket as Mail
    5 - Add Attachment to Ticket
    6 - Add User to Ticket
    7 - Remove Attachment to Ticket
    8 - Add Comment to Ticket
    */
    //create data
    const data = {
      timestamp: new Date(),
      content: item.content,
      user: JSON.parse(localStorage.getItem("userData"))._id,
    };
    return axios
      .post("ticket/" + item.id + "/activitys", data)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  addUserToTicket(context, item) {
    return axios
      .post("ticket/" + item.id + "/users", item)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Nutzer wurde erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
