<template>
  <div>
    <v-text-field
      v-model="storageLocationName"
      disabled
      dense
      :label="$t('dangerousGoods.dangerousGoodsList.form.storageLocation')"
    >
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: ["value", "config"],
  data() {
    return {
      storageLocationName: "",
    };
  },
  created() {
    this.storageLocationName = this.$route.params.storageLocationName;
    this.value.dangerousGoodStorageLocation =
      this.$route.params.storageLocationId;
  },
};
</script>
