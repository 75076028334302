<template>
  <v-dialog
    v-model="this.isOpen"
    max-width="733"
    @click:outside="isOpen = false"
  >
    <v-card v-if="data">
      <v-card-title>{{ data.identifier }} - {{ data.name }}</v-card-title>
      <v-card-subtitle></v-card-subtitle>

      <v-card-text>
        <div v-if="data.pictograms.length">
          <div class="text-h6 mb-2">
            {{ $t("dangerousGoods.dangerousGoodsList.tableHeader.pictograms") }}
          </div>
          <v-row>
            <v-col
              v-for="pictogram of data.pictograms"
              :key="pictogram"
              cols="6"
              sm="3"
              lg="3"
            >
              <v-card outlined class="pictogram-card">
                <v-card-text>
                  <pictogram :image-path="pictogram" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-if="hAndPSentences.length">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2">
            {{
              $t("dangerousGoods.dangerousGoodsList.tableHeader.hAndPSentences")
            }}
          </div>
          <v-row no-gutters>
            <v-col v-for="sentence of hAndPSentences" :key="sentence" cols="12">
              <div>{{ sentence }}</div>
            </v-col>
          </v-row>
        </div>
        <div v-if="data?.storageClassification">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2"></div>
          <v-row no-gutters>
            <v-col cols="6" class="font-weight-bold">
              {{
                $t(
                  "dangerousGoods.dangerousGoodsList.tableHeader.storageClassification"
                )
              }}</v-col
            >
            <v-col cols="6" class="text-end">
              {{ data.storageClassification }}
            </v-col>
          </v-row>
        </div>
        <div v-if="data?.containerSize">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2"></div>
          <v-row no-gutters>
            <v-col cols="6" class="font-weight-bold">
              {{
                $t(
                  "dangerousGoods.dangerousGoodsList.tableHeader.containerSize"
                )
              }}</v-col
            >
            <v-col cols="6" class="text-end"> {{ data.containerSize }}</v-col>
          </v-row>
        </div>
        <div v-if="data?.yearlyUsage">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2"></div>
          <v-row no-gutters>
            <v-col cols="6" class="font-weight-bold">
              {{
                $t("dangerousGoods.dangerousGoodsList.tableHeader.yearlyUsage")
              }}</v-col
            >
            <v-col cols="6" class="text-end"> {{ data.yearlyUsage }}</v-col>
          </v-row>
        </div>
        <div v-if="data?.usageLocation">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2"></div>
          <v-row no-gutters>
            <v-col cols="6" class="font-weight-bold">
              {{
                $t(
                  "dangerousGoods.dangerousGoodsList.tableHeader.usageLocation"
                )
              }}
            </v-col>
            <v-col cols="6" class="text-end"> {{ data.usageLocation }}</v-col>
          </v-row>
        </div>
        <div v-if="data?.manufacturer">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2"></div>
          <v-row no-gutters>
            <v-col cols="6" class="font-weight-bold">
              {{
                $t("dangerousGoods.dangerousGoodsList.tableHeader.manufacturer")
              }}
            </v-col>
            <v-col cols="6" class="text-end"> {{ data.manufacturer }}</v-col>
          </v-row>
        </div>
        <div v-if="data?.safetyDataSheet?.name">
          <v-divider class="my-4" />
          <div class="text-h6 mb-2"></div>
          <v-row no-gutters>
            <v-col cols="6" class="font-weight-bold">
              {{
                $t(
                  "dangerousGoods.dangerousGoodsList.tableHeader.safetyDataSheet"
                )
              }}
            </v-col>
            <v-col cols="6" class="text-end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    medium
                    class="mr-2"
                    v-on="on"
                    @click.stop="downloadItem(data)"
                    data-cy="copy"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span>
                  {{
                    $t("dangerousGoods.dangerousGoodsList.downloadDataSheet")
                  }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" v-on:click="isOpen = false">{{
          $t("general.back")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Pictogram from "@/components/layout/pictogram.vue";

export default {
  components: { Pictogram },
  data: () => ({
    isOpen: false,
    data: undefined,
  }),
  watch: {
    isOpen(newVal) {
      if (!newVal) {
        this.data = undefined;
        this.overdueAssignments = undefined;
        this.finishedAssignments = undefined;
      }
    },
  },
  computed: {
    ...mapGetters(["tableDataGetter", "hSentences", "pSentences"]),
    hAndPSentences() {
      const allSentences = this.data ? [...this.data.h, ...this.data.p] : [];
      return allSentences.map(
        (key) =>
          this.allHAndPSentences.find((sentence) => sentence.value === key)
            ?.name ?? ""
      );
    },
    allHAndPSentences() {
      return [...this.hSentences, ...this.pSentences];
    },
  },
  methods: {
    openDetails(data) {
      this.data = data;
      this.isOpen = true;
    },
    async downloadItem(item) {
      //open url to download file
      window.open(await this.getDownloadItemLink(item), "_blank");
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
    createFormPath(item) {
      if (item) {
        return (
          "Sicherheitsdatenblaetter/" +
          this.data?.safetyDataSheet.name +
          "_" +
          this.data?.safetyDataSheet.location +
          "." +
          this.data?.safetyDataSheet.filetype
        );
      }
    },
  },
};
</script>

<style scoped>
.pictogram-card {
  height: 100%;
}
</style>
