<template>
  <div>
    <v-card outlined>
      <v-card-title>
        {{ $t("dangerousGoods.dangerousGoodsList.form.pictograms") }}
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="stretch">
          <v-col
            v-for="pictogram of allPictograms"
            :key="pictogram.image"
            cols="6"
            xs="6"
            sm="4"
            md="4"
          >
            <div
              class="pictogram"
              :class="{
                selected: isSelected(pictogram.image),
              }"
              @click="selectPictogram(pictogram.image)"
            >
              <pictogram :image-path="pictogram.image" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pictogram from "@/components/layout/pictogram.vue";

export default {
  components: {
    Pictogram,
  },
  props: ["value", "config"],
  computed: {
    ...mapGetters(["allPictograms"]),
  },
  data() {
    return {
      selectedPictograms: [],
    };
  },
  methods: {
    isSelected(pictogram) {
      return this.value.pictograms.includes(pictogram);
    },
    selectPictogram(pictogram) {
      const index = this.value.pictograms.indexOf(pictogram);
      if (index === -1) {
        this.value.pictograms.push(pictogram);
      } else {
        this.value.pictograms.splice(index, 1);
      }
      this.$emit("input", this.value);
    },
  },
};
</script>

<style scoped>
.pictogram {
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid grey;
  border-radius: 16px;
  padding: 8px;
  height: 100%;

  cursor: pointer;
}
.selected {
  border: 4px solid black;
}
.pictogramImage {
  width: 100%;
}
</style>
