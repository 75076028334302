//import axios from "axios";
import { SubscriptionJS, getBillwerkPublicApiKey } from "../../api.js";
const actions = {
  async getPortalData(context) {
    const portal = new SubscriptionJS.Portal(
      await context.dispatch("getBillwerkToken")
    );
    context.commit("SET_PORTAL_DATA");
    portal.contractDetails(
      function (data) {
        context.commit("SET_PORTAL_DATA", data);
      },
      function (e) {
        context.commit("SET_PORTAL_DATA", e);
        context.dispatch("triggerError", e);
      }
    );
  },
  /*async getOrderPreview(context) {
    const portal = new SubscriptionJS.Portal(
      await context.dispatch("getBillwerkToken")
    );
    context.commit("SET_PORTAL_DATA");
    portal.contractDetails(
      function (data) {
        context.commit("SET_PORTAL_DATA", data);
      },
      function (e) {
        context.commit("SET_PORTAL_DATA", e);
        context.dispatch("triggerError", e);
      }
    );
  },*/
  async updateCustomerData(context, data) {
    const portal = new SubscriptionJS.Portal(
      await context.dispatch("getBillwerkToken")
    );
    return portal.customerChange(
      data,
      function () {
        //on sucess reload data
        context.dispatch("getPortalData");
      },
      function (e) {
        context.dispatch("triggerError", e);
      }
    );
  },
  createOrder(context, data) {
    const signup = new SubscriptionJS.Signup(
      context.dispatch("getBillwerkToken")
    );
    signup.createOrder(
      data.cart,
      data.customer,
      function (r) {
        console.log(r);
        context.dispatch("upgradePaySync", { order: r.OrderId });
        return r;
      },
      function (e) {
        console.log(e);
        context.dispatch("triggerError", e);
        return e;
      }
    );
  },
  async changePayment(context, data) {
    const portal = new SubscriptionJS.Portal(
      await context.dispatch("getBillwerkToken")
    );
    //init payment service
    const paymentService = new SubscriptionJS.Payment(
      {
        publicApiKey: getBillwerkPublicApiKey(),
      },
      function () {
        console.log("ready");
      },
      function (e) {
        context.dispatch("triggerError", e);
      }
    );
    return portal.paymentChange(
      paymentService,
      data,
      function (data) {
        if (data.Url) {
          // Open the PSP URL if provided
          window.location.href = data.Url;
        }
        return data;
      },
      function (e) {
        context.dispatch("triggerError", e);
        return e;
      }
    );
  },
  async upgradePaySync(context, data) {
    const portal = new SubscriptionJS.Portal(
      await context.dispatch("getBillwerkToken")
    );
    console.error(data);
    return portal.upgradePaySync(
      data.order,
      function (data) {
        //if (data.Url) {
        // Open the PSP URL if provided
        //window.location.href = data.Url;
        //}
        //return data;
        console.warn(data);
      },
      function (e) {
        context.dispatch("triggerError", e);
        return e;
      }
    );
  },
  async getAvailiablePaymentMethods(context) {
    const paymentService = new SubscriptionJS.Payment(
      await context.dispatch("getBillwerkToken"),
      function () {},
      function (e) {
        context.dispatch("triggerError", e);
      }
    );
    paymentService.getAvailablePaymentMethodEnum();
  },
};

export default actions;
