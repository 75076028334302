<template>
  <!--translate-->
  <v-dialog v-model="addBoard.open" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline">Spalte / Board hinzufügen</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            class="mt-6"
            :rules="rule"
            label="Boardname"
            data-cy="name"
            v-model="board.name"
            dense
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="addBoard.open = false">{{
          $t("general.cancel")
        }}</v-btn>
        <v-btn
          color="primary"
          outlined
          @click="createBoard(board)"
          data-cy="createBoard"
          >{{ $t("general.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["addBoard"],
  data() {
    return {
      rule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
      valid: false,
      board: {
        name: "",
      },
    };
  },
  methods: {
    createBoard(item) {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("addBoard", item).then(() => {
          //close dialog
          this.addBoard.open = false;
          //reload data
          this.$emit("reloadDataFunc");
        });
      }
    },
  },
};
</script>
