<template>
  <quill-editor
    class="largeTextField"
    :data-cy="config.dataCy"
    v-model="inputVal[config.vModel]"
    :options="editorOptionAvail"
  >
  </quill-editor>
</template>
<script>
export default {
  props: ["config", "value"],
  data() {
    return {
      editorOptionAvail: {
        placeholder: "Inhalt der Aufgabe hier einfügen...",
        modules: {
          toolbar: true,
        },
      },
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style>
.largeTextField > .ql-container {
  min-height: 120px !important;
  font-size: 16px !important;
}
.largeTextField > .ql-toolbar {
  font-size: 16px !important;
}
</style>