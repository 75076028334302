<template>
  <div>
    <v-autocomplete
      v-model="value.users"
      :items="userDataMap"
      :loading="getAllUsersStatus"
      hide-no-data
      item-text="Description"
      item-value="id"
      :label="$t('settings.responsible')"
      data-cy="users"
      multiple
      chips
      dense
      ><template v-slot:selection="data">
        <v-chip small v-bind="data.attrs">
          {{ data.item.Description }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  data: () => ({}),
  mounted() {
    this.$store.dispatch("getUsers");
  },
  computed: {
    ...mapGetters(["getAllUsers", "getAllUsersStatus"]),
    userDataMap() {
      return this.getAllUsers.map((user) => {
        const Description = user.name + " - " + user.short;
        const id = user._id;
        return Object.assign({}, user, {
          Description,
          id,
        });
      });
    },
  },
  methods: {},
};
</script>
