import StoreUtil from "../store.util.js";
const mutations = {
    SET_ALL_MANAGEDINSTRUCTIONS(state, payload) {
    state.managedInstructionList = StoreUtil.updateState(
      state.managedInstructionList,
      payload
    );
  },
};

export default mutations;
