<template>
  <div v-on:mouseleave="minimizeSidebar">
    <v-navigation-drawer v-model="drawer" app bottom permanent expand-on-hover>
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            :id="item.name"
          >
            <template v-slot:activator>
              <v-list-item :id="item.name" class="withSubmenu">
                <v-list-item-content
                  @click="navigateTo(item.route, 'noTab')"
                  active-class="highlighted"
                  :class="
                    '/' + item.route === $route.path ? 'highlighted' : 'notehi'
                  "
                >
                  <v-list-item-title
                    style="text-align: start"
                    class="v-list-item-title-color"
                  >
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click="navigateTo(child.route, 'noTab')"
              active-class="highlighted"
              :class="'/' + child.route === $route.path ? 'highlighted' : ''"
              :id="item.name"
            >
              <!--v-list-item-action v-if="child.icon" :id="item.name">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action-->
              <v-list-item-content>
                <v-list-item-title class="caption v-list-item-title-color">
                  {{ $t(child.text) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            @click="navigateTo(item.route, 'noTab')"
            active-class="highlighted"
            :class="$route.path.includes(item.route) ? 'highlighted' : ''"
            :id="item.name"
          >
            <v-list-item-action :id="item.name">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">
                {{ $t(item.text) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- additional items without list -->
        <v-list-item @click="openKnowledgeBase()">
          <v-list-item-action>
            <v-icon>support</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="v-list-item-title-color">
              {{ $t("sidebar.knowledgeBase") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list dense>
          <template>
            <v-list-item @click="supportDialog = true">
              <v-list-item-action>
                <v-icon color="primary">mdi-help-circle-outline</v-icon>
              </v-list-item-action>
              <v-list-item-title class="v-list-item-title-color">
                {{ $t("sidebar.support") }}
              </v-list-item-title>
            </v-list-item>
            <!--v-list-item @click="openAccount()">
              <v-list-item-action>
                <v-icon>account_circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="v-list-item-title-color">
                  {{ $t("sidebar.account") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item-->
            <v-list-item @click="logout()">
              <v-list-item-action>
                <v-icon color="error" data-cy="logout">mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="v-list-item-title-color">
                  {{ $t("sidebar.logout") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </template>
    </v-navigation-drawer>
    <support-dialog v-model="supportDialog" />
  </div>
</template>

<script>
import { returnToLogin, isAuthenticated } from "../../api.js";
import SupportDialog from "@/components/layout/supportDialog.vue";
export default {
  components: { SupportDialog },
  data: () => ({
    supportDialog: false,
    drawer: null,
    items: [
      {
        text: "sidebar.tickets",
        icon: "mdi-view-dashboard-variant-outline",
        model: false,
        route: "app/tickets/all",
        children: [
          {
            text: "sidebar.ticketsAll",
            icon: "mdi-circle-medium",
            route: "app/tickets/all",
          },
          {
            text: "sidebar.ticketsUser",
            icon: "mdi-circle-medium",
            route: "app/tickets/user",
          },
          {
            text: "sidebar.ticketsRecurring",
            icon: "mdi-circle-medium",
            route: "app/tickets/recurring",
          },
        ],
      },
      {
        text: "sidebar.instructions",
        icon: "mdi-account-multiple-check-outline",
        model: false,
        route: "app/instructions",
      },
      {
        text: "sidebar.managedInstruction",
        icon: "checklist",
        model: false,
        route: "app/managedInstructions",
      },
      {
        text: "sidebar.examination",
        icon: "mdi-magnify",
        model: false,
        route: "app/examination",
      },
      {
        text: "sidebar.riskAssessment",
        icon: "mdi-fire",
        model: false,
        route: "app/riskAssessment",
      },
      {
        text: "sidebar.dangerousGoods",
        icon: "mdi-barrel",
        model: false,
        route: "app/dangerousGoods",
      },
      {
        text: "sidebar.operatingInstructions",
        icon: "mdi-book-outline",
        model: false,
        route: "app/operatingInstructions",
      },
      {
        text: "sidebar.inspections",
        icon: "mdi-walk",
        model: false,
        route: "app/inspections",
      },
      {
        text: "sidebar.incidentReports",
        icon: "personal_injury",
        model: false,
        route: "app/incidentReports",
      },
      {
        text: "sidebar.medkitlogs",
        icon: "local_hospital",
        model: false,
        route: "app/medkitlogs",
      },
      {
        text: "sidebar.files",
        icon: "mdi-folder-outline",
        model: false,
        route: "app/files",
      },
      {
        text: "sidebar.consulting",
        icon: "support_agent",
        model: false,
        route: "app/consulting",
      },
      {
        text: "sidebar.employees",
        icon: "mdi-account",
        model: false,
        route: "app/employee",
      },
      {
        text: "sidebar.settings",
        icon: "settings",
        model: false,
        route: "app/settings/profile",
        children: [
          {
            text: "sidebar.profile",
            icon: "mdi-circle-medium",
            route: "app/settings/profile",
          },
          {
            text: "sidebar.users",
            icon: "mdi-circle-medium",
            route: "app/settings/users",
          },
          {
            text: "sidebar.boards",
            icon: "mdi-circle-medium",
            route: "app/settings/boards",
          },
          {
            text: "sidebar.email",
            icon: "mdi-circle-medium",
            route: "app/settings/email",
          },
          {
            text: "sidebar.multiuser",
            icon: "mdi-circle-medium",
            route: "app/settings/multiuser",
          },
          {
            text: "sidebar.tariff",
            icon: "mdi-circle-medium",
            route: "app/settings/tariff",
          },
        ],
      },
    ],
  }),
  mounted() {
    this.refreshToken();
  },
  methods: {
    returnToLogin,
    navigateTo(destination, mode) {
      if (mode === "newTab") {
        let href = window.location.origin + "/#" + destination;
        window.open(href, "_blank");
      } else {
        this.$router.replace({ path: "/" + destination });
      }
    },
    openAccount() {},
    logout() {
      //redirect to login
      this.returnToLogin();
    },
    openKnowledgeBase() {
      window.open("https://help.safetyplanner.de/help/de-de", "_blank");
    },
    refreshToken() {
      //refresh token every 60 seconds with isAuthenticated function
      setInterval(isAuthenticated, 60 * 1000);
    },
    minimizeSidebar() {
      this.items.forEach((item) => {
        item.model = false;
        item.children?.forEach((child) => (child.model = false));
      });
    },
  },
};
</script>

<style scoped>
.divider {
  border-top: 1px solid #e8e8e8;
}

.v-list-item-title-color {
  color: #717171;
}

.highlighted .v-icon {
  color: #45d6f4;
}

.withSubmenu {
  padding: 0;
}
</style>
