import axios from "axios";

const actions = {
  createIncidentReport(context, value) {
    return axios
      .post("incidentReport", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unfallmeldung " + getIncidentReportName(r) + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteIncidentReport(context, val) {
    return axios
      .delete("incidentReport/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Unfallmeldung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateIncidentReport(context, value) {
    return axios
      .put("incidentReport/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unfallmeldung " + getIncidentReportName(r) + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleIncidentReport(context, id) {
    return axios
      .get("incidentReport/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  createIncidentReportPdf(context, value) {
    return axios
      .get(
        "incidentReport/pdf" +
          new Date(value.start).toISOString() +
          "&end=" +
          new Date(value.end).toISOString()
      )
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  createIncidentReport2Pdf(context, id) {
    return axios
      .get(`incidentReport2/${id}/pdf`)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;

function getIncidentReportName(response) {
  console.log(response)
  if (response.data.incidentReportType === "long") {
    const { firstName, lastName } = response.data.longIncidentReport?.insuredPerson;
    return `${firstName ?? ""}${lastName ? " " + lastName : ""}`;
  } else {
    return response.data.name;
  }
}
