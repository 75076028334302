<template>
  <div class="d-flex">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit</v-icon
        >
      </template>
      <span>Unterweisung bearbeiten</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>Unterweisung löschen</span>
    </v-tooltip>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "Unterweisung löschen",
      description: "",
    },
  }),
  methods: {
    editItem(item) {
      this.$router.push({
        name: "managedInstructionAssignmentForm",
        params: { id: item.id, mode: "edit" },
      });
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      /*this.deleteDialog.description = this.$t(
        "managedInstructionAssignments.deleteTitleLong",
        {
          title: item.name,
        }
      );*/
      this.deleteDialog.description =
        "Soll der Unterweisung " + item.name + " wirklich gelöscht werden?";
    },
    deleteItem(item) {
      this.$store
        .dispatch("deleteManagedInstructionAssignment", item)
        .then(() => {
          this.reloadDataFunc();
          this.deleteDialog.open = false;
        });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
  },
};
</script>
