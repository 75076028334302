<template>
  <div>
    <div v-if="item.status == 'Draft'">{{ $t("inspections.draft") }}</div>
    <div v-else>{{ $t("inspections.finished") }}</div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  methods: {
  },
};
</script>
