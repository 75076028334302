<template>
  <v-layout class="align-center justify-center">
    <div>
      <heading>
        {{ $t("sidebar.examinationAssignment") }}
      </heading>
      <div v-if="examinationAssignment?.status === 'SUCCESS'">
        <v-card outlined>
          <v-card-title>
            {{ $t("examinationAssignment.completed") }}
          </v-card-title>
          <v-card-text>
            {{
              $t("examinationAssignment.completedDescription", {
                name: examinationAssignment?.examination?.name,
              })
            }}
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <v-card outlined>
          <v-card-title>
            {{
              $t("examinationAssignment.cardTitle", {
                name: examinationAssignment?.examination?.name,
              })
            }}
          </v-card-title>
          <v-card-text>
            {{ $t("examinationAssignment.cardDescription") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="completeAssignment()">
              {{ $t("examinationAssignment.complete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  name: "ExaminationAssignmentStatus",
  data: () => ({
    examinationAssignment: null,
  }),
  created() {
    this.loadExaminationAssigment();
  },
  methods: {
    async loadExaminationAssigment() {
      try {
        const response = await axios.get(
          `/examinationAssignment/employee/${this.$route.params.assignmentId}/${this.$route.params.employeeId}`
        );
        this.examinationAssignment = response.data;
      } catch (error) {
        this.$store.dispatch("triggerError", error);
      }
    },
    async completeAssignment() {
      try {
        await axios.put(
          `/examinationAssignment/employee/${this.examinationAssignment._id}/${this.examinationAssignment.employee}/complete`,
          {
            status: "SUCCESS",
          }
        );
        this.$store.dispatch(
          "triggerSnackbar",
          this.$t("examinationAssignment.successMessage", {
            name: this.examinationAssignment.examination.name,
          })
        );
        this.loadExaminationAssigment();
      } catch (error) {
        this.$store.dispatch("triggerError", error);
      }
    },
  },
};
</script>
