<template>
  <!--translate-->
  <div>
    <v-card class="mx-auto my-4" max-width="800" flat outlined>
      <v-container class="mx-4 my-4">
        <v-row>
          <b>{{ $t("settings.boardSettings") }}</b>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            outlined
            @click="openAddBoard"
            color="primary"
            class="mr-10"
          >
            {{ $t("settings.addColumn") }}
          </v-btn>
        </v-row>
      </v-container>
      <v-form class="pa-4 pt-0" ref="form" lazy-validation>
        <p class="mb-3">{{ $t("settings.columnOrder") }}</p>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
              v-model="boardOrder.value"
              :items="boardOrderDataMap"
              :loading="allBoardsPending"
              hide-no-data
              item-text="Description"
              item-value="id"
              :label="$t('settings.boards')"
              data-cy="boardOrder"
              multiple
              chips
              dense
            >
              <template v-slot:selection="data">
                <v-chip small v-bind="data.attrs">
                  {{ data.item.Description }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <p class="mt-6 mb-3">{{ $t("settings.columnUsage") }}</p>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              v-model="standardOpenBoard.value"
              :items="boardOrderDataMap"
              :loading="allBoardsPending"
              hide-no-data
              item-text="Description"
              item-value="id"
              :label="$t('settings.selectedColumn')"
              data-cy="standardOpenBoard"
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              v-model="standardClosedBoard.value"
              :items="boardOrderDataMap"
              :loading="allBoardsPending"
              hide-no-data
              item-text="Description"
              item-value="id"
              :label="$t('settings.columnDeleted')"
              data-cy="standardClosedBoard"
              dense
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary" outlined @click="saveBoardSettings">{{
          $t("general.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <addBoard
      ref="addBoard"
      @reloadDataFunc="initialize"
      :addBoard="addBoard"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import addBoard from "./addBoard.vue";
export default {
  components: { addBoard },
  data: () => ({
    item: {
      value: {
        past: 0,
        future: 0,
      },
    },
    addBoard: {
      open: false,
    },
    boardOrder: { value: [] },
    standardOpenBoard: { value: "" },
    standardClosedBoard: { value: "" },
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["allBoards", "allBoardsPending"]),
    boardOrderDataMap() {
      return this.allBoards.map((board) => {
        const Description = board.name;
        const id = board._id;
        return Object.assign({}, board, {
          Description,
          id,
        });
      });
    },
  },
  methods: {
    openAddBoard() {
      this.addBoard.open = true;
    },
    initialize() {
      //get boards
      this.$store.dispatch("getAllBoards");
      //get settings
      this.$store.dispatch("getSettingData", "boardOrder").then((item) => {
        this.boardOrder = item;
      });
      this.$store
        .dispatch("getSettingData", "standardOpenBoard")
        .then((item) => {
          this.standardOpenBoard = item;
        });
      this.$store
        .dispatch("getSettingData", "standardClosedBoard")
        .then((item) => {
          this.standardClosedBoard = item;
        });
    },
    saveBoardSettings() {
      //update settings
      //boardOrder
      this.$store.dispatch("setSettingData", {
        id: "boardOrder",
        value: this.boardOrder.value,
      });
      //standardOpenBoard
      this.$store.dispatch("setSettingData", {
        id: "standardOpenBoard",
        value: this.standardOpenBoard.value,
      });
      //standardClosedBoard
      this.$store.dispatch("setSettingData", {
        id: "standardClosedBoard",
        value: this.standardClosedBoard.value,
      });
    },
  },
};
</script>
