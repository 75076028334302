<template>
  <div class="ma-4 mt-8 mb-16">
    <recurringTicketsBar/>
    <div><tableComponent class="mx-4" /></div>
  </div>
</template>
<script>
import recurringTicketsBar from "./recurringTicketsBar.vue";
import tableComponent from "./tableComponent.vue"
export default {
  components: { recurringTicketsBar, tableComponent },
  data() {
    return {};
  },
};
</script>
