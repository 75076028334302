import axios from "axios";
const actions = {
  getUsers(context) {
    context.commit("SET_ALL_USERS");
    axios
      .get("user")
      .then(function (response) {
        context.commit("SET_ALL_USERS", response.data.data);
      })
      .catch(function (e) {
        context.commit("SET_ALL_USERS", e);
        context.dispatch("triggerError", e);
      });
  },
  createUser(context, value) {
    return axios
      .post("user", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Nutzer " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        if (e.response.data.message === "User with email already exists") {
          context.dispatch("triggerUniversalAlert", {
            text: "Diese Email kann für das Anlegen eines neuen Nutzers nicht verwendet werden.",
            heading: "Fehler - Email bereits verwendet",
            type: "warning",
          });
        } else {
          context.dispatch("triggerError", e);
        }
      });
  },

  deleteUser(context, val) {
    return axios
      .delete("user/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Nutzer erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateUser(context, value) {
    return axios
      .put("user/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Nutzer " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        if (e.response.data.message === "User with email already exists") {
          context.dispatch("triggerUniversalAlert", {
            text: "Diese Email kann für das Anlegen eines neuen Nutzers nicht verwendet werden.",
            heading: "Fehler - Email bereits verwendet",
            type: "warning",
          });
        } else {
          context.dispatch("triggerError", e);
        }
      });
  },

  updateUserPassword(context, value) {
    return axios
      .put("user/my/password", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Eigenes Passwort erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleUser(context, id) {
    return axios
      .get("user/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
