<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="2"> </v-col>
      <v-col cols="12" sm="12" md="8">
        <v-card class="mx-auto my-5 mb-10" max-width="1000">
          <v-app-bar flat>
            <span class="headline">{{ $t(formCompData.title) }}</span>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <formComponent
                ref="formComponent"
                :form="formComp"
                v-model="formComp.eventForm"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey" outlined @click="closeform">{{
              $t("general.back")
            }}</v-btn>
            <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
              <div v-if="$route.params.mode === 'new'">
                {{ $t("general.save") }}
              </div>
              <div v-if="$route.params.mode === 'edit'">
                {{ $t("general.saveChanges") }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="2"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const getDefaultData = () => ({
  name: "",
  members: [],
  instructionAssignments: [],
  examinationAssignments: [],
});

import formComponent from "../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../components/api/api";
export default {
  components: { formComponent },
  props: ["data"],
  computed: {
    ...mapGetters(["allGroups", "allGroupsPending"]),
    allOtherGroupNames() {
      return this.allGroups
        .filter((group) => group._id !== this.formComp?.eventForm._id)
        .map((group) => group.name);
    },
    formCompData() {
      return {
        title: "employeeGroup.formTitle",
        item: null,
      };
    },
  },
  data: () => ({
    formComp: {
      eventForm: getDefaultData(),
      form: [
        [
          {
            //v-text-field
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 pt-4",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "name",
              label: "Name",
              dataCy: "name",
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
        ],
        [
          {
            //v-select
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-0",
            id: getUniqueId(),
            componentName: "selectEmployees",
            config: {},
          },
        ],
        [
          {
            //multiple v-select
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-2",
            id: getUniqueId(),
            componentName: "selectInstructionAssignments",
            config: {
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
        ],
        [
          {
            //multiple v-select
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-2",
            id: getUniqueId(),
            componentName: "selectExaminationAssignments",
            config: {
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
        ],
      ],
    },
  }),
  mounted() {
    this.switchFormModes(this.$route.params.mode, this.$route.params);
    this.$store.dispatch("getAllEmployeeGroups");
  },
  methods: {
    switchFormModes(mode, params) {
      switch (mode) {
        case "edit":
          this.$store
            .dispatch("getSingleEmployeeGroup", params.id)
            .then((item) => {
              this.formCompData.title = "employeeGroup.editGroup";
              this.formComp.eventForm = item;
            });
          break;
        case "new":
          this.formCompData.title = "employeeGroup.createGroup";
          break;
      }
    },
    closeform() {
      this.resetData("new");
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      if (this.allOtherGroupNames.includes(this.formComp.eventForm.name)) {
        this.$store.dispatch(
          "triggerErrorSnackbar",
          "Der Gruppenname existiert bereits."
        );
        return;
      }
      if (this.$refs.formComponent.validateAllFields()) {
        if (this.$route.params.mode === "new") {
          //create new item
          this.$store
            .dispatch("createEmployeeGroup", this.formComp.eventForm)
            .then((val) => {
              this.afterUpdateCheck(val, createNew, "new");
            });
        } else {
          //edit item
          this.$store
            .dispatch("updateEmployeeGroup", this.formComp.eventForm)
            .then((val) => {
              this.afterUpdateCheck(val, createNew, "edit");
            });
        }
      }
    },
    afterUpdateCheck(val, createNew, mode) {
      if (createNew) {
        //reset contact data to create new one
        this.resetData(mode);
      } else {
        //close form
        this.closeform();
      }
    },
    openDialog(val) {
      this.occupiedDialog.open = true;
      this.occupiedDialog.events = val;
    },
    resetData(mode) {
      //reset whole form data
      this.formComp.eventForm = getDefaultData();
      //reset form component form only after contact create
      if (mode === "new") {
        this.$refs.formComponent.resetForm();
      }
      //trigger reload data function in parent component
      this.reloadData();
      this.$router.back();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
