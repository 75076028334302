<template>
  <div>
    <div class="subtitle-1 mt-5">Datei hinzufügen:</div>
    <input
      ref="fileInput"
      @change="handleImage"
      class="mt-2"
      type="file"
      accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, image/jpeg, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, image/png, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
  </div>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    handleImage(e) {
      const selectedFile = e.target.files[0]; // get the first file
      if (selectedFile) {
        this.createBase64Image(selectedFile);
      }
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.value.filetype = fileObject.name
          .match(/\.[0-9a-z]+$/i)[0]
          .split(".")[1];
        this.value.name = fileObject.name;
        this.value.content = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(fileObject);
    },
    resetFileInput() {
      // Reset the file input using the ref
      this.$refs.fileInput.value = ''; // Clear the file input
    }
  }
};
</script>
