<template>
  <div>
    <v-datetime-picker
      v-model="value.date"
      :label="$t('medkitlogs.date')"
      data-cy="date"
      prependIcon="event"
      :date-picker-props="dateProps"
      :time-picker-props="timeProps"
      dateFormat="dd.MM.yyyy"
      :clearText="$t('medkitlogs.back')"
    />
  </div>
</template>
<script>
import vDatetimePicker from "../../_utils/formComponent/generalFormComponents/v2DateTimePicker.vue";
export default {
  props: ["value"],
  components: { vDatetimePicker },
  data: () => ({
    dateProps: {
      locale: "de-de",
      "first-day-of-week": "1",
    },
    timeProps: {
      format: "24hr",
    },
  }),
  methods: {},
};
</script>
