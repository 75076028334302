<template>
  <v-dialog v-model="data.open" max-width="600px">
    <v-card v-if="data.item != null">
      <v-card-title>
        {{ data.item.title }}
        <div class="flex-grow-1"></div>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="login()" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="10" md="10">
              <v-text-field
                v-model="emails"
                prepend-icon="mail"
                name="email"
                label="Email (bei mehreren durch Komma getrennt)"
                type="email"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="data.open = false" data-cy="back">{{ $t("general.back") }}</v-btn
        >
        <v-btn
          color="primary"
          outlined
          @click="sendEmail(data.item)"
          data-cy="send"
        >
          Email senden</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["data"],
  data: () => ({
    emails: "",
    requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
  }),
  methods: {
    sendEmail(item) {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("sendTicketViaMail", { to: this.emails, id: item._id })
          .then(() => {
            //reset email and close form
            this.emails = "";
            this.data.open = false;
          });
      }
    },
  },
};
</script>
