import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import StoreUtil from "../../../../store/store.util";
import VueI18n from "@/i18n";

export const DEFAULT_ITEMS_PER_PAGE = 100;
export const getDefaultRowsPerPageItems = () => [
  10,
  15,
  25,
  100,
  { text: VueI18n.t("general.all"), value: -1 },
];

const state = {
  dataAll: {
    pagination: {
      page: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      rowsPerPageItems: getDefaultRowsPerPageItems(),
      totalItems: 0,
      search: "",
    },
    allList: StoreUtil.state(),
  },

  dataAllPagination: {
    allList: StoreUtil.state(),
  },
};

const contactsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default contactsModule;
