<template>
  <div>
    <div>tests</div>
    <div>
      <iframe
        src="https://scorm-files.safetyplanner.de/de/42b452f945574298ad31a9e35b29e32a/index_scorm.html"
        style="
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          z-index: 999999;
        "
      >
        Your browser doesn't support iframes
      </iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      //get instruction
      this.$store
        .dispatch("getSingleManagedInstructionAssignmentPublic", {
          managedInstructionAssignmentId:
            this.$route.params.managedInstructionAssignmentId,
          assignmentId: this.$route.params.assignmentId,
          employeeId: this.$route.params.employeeId,
        })
        .then((item) => {
          console.log(item);
        });
    },
  },
};
</script>
