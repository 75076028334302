<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <div class="container theme-showcase" role="main">
          <div class="row">
            <div class="col-md-12">
              <h2>Order</h2>
              <hr />
              <form id="form">
                <div class="form-group">
                  <label>Payment Method</label>
                  <div>
                    <p id="PaymentMethod"></p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="Payment">Payment Data</label>
                  <div
                    id="payment"
                    style="width: 100%; border: 1px solid #eaeaea"
                  ></div>
                </div>
                <button type="button" id="change" class="btn btn-default">
                  Change payment Method
                </button>
              </form>
            </div>
          </div>
        </div></v-col
      >
      <v-btn @click="createOrder()">createOrder</v-btn></v-row
    >
  </div>
</template>

<script>
import { SubscriptionJS } from "../../../../billwerk/subscription.js";
console.warn(SubscriptionJS);
let subscriptionJS = SubscriptionJS;
export default {
  name: "paymentData",
  data: () => ({
    paymentForm: null,
  }),
  mounted() {
    var publicApiKey = "61569f151b5e641a59c94f62"; //replace with your id https://sandbox.billwerk.com/#/settings/self-service

    var style = {
      /*
				{
					backgroundColor: '#ffffff',    
					fontSize: '16px',    
					fontFamily: '"Times New Roman", Times, serif',    
					fontStyle: 'normal',    
					color: '#444444',
					borderLeftColor: 'black',
					borderLeftWidth: '5px'
				}
			*/
      body: {},
      label: {}, // For input labeling
      input: {}, // For input, select elements
      inputRequired: {}, // For required inputs
      inputInvalid: {}, // For invalid inputs
    };

    const config = {
      paymentMethods: ["Debit:FakePSP", "CreditCard:FakePSP"], //restrict server config
      publicApiKey: publicApiKey,
      locale: "de", //set desired locale (i18n)
      providerReturnUrl: "https://sandbox.billwerk.com/portal/finalize.html", //replace with your url
    };
    console.warn(subscriptionJS);
    this.paymentForm = subscriptionJS.createElement(
      "paymentForm",
      document.getElementById("payment"),
      config,
      style,
      function (e) {
        console.error(e);
      }
    );
  },
  methods: {
    createOrder() {
      //create order
      this.$store
        .dispatch("createOrder", {
          cart: { PlanVariantId: JSON.parse(this.$route.params.selected).Id },
          customer: JSON.parse(this.$route.params.portalData).Customer,
        })
        .then((r) => {
          console.error(r);
          /*this.$store
            .dispatch("upgradePayInteractive", {
              order: r,
              paymentForm: this.paymentForm,
            })
            .then((r) => {
              console.warn(r);
            });*/
        });
    },
  },
};
</script>
<style>
iframe {
  width: 99%;
  height: 100%;
}
</style>
