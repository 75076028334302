<template>
  <v-dialog v-model="passwordDialog.open" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline"
        >{{ $t("login.resetViaEmail") }}</v-card-title
      >
      <v-form
        @submit.prevent="sentPasswordResetEmail()"
        ref="form"
        lazy-validation
        class="pa-1"
      >
        <v-text-field
          class="mx-6"
          v-model="logForm.email"
          prepend-icon="mail"
          name="email"
          :label="$t('login.email')"
          type="email"
          :rules="requiredRule"
        ></v-text-field>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="passwordDialog.open = false"
          >{{ $t("general.back") }}</v-btn
        >
        <v-btn
          color="primary"
          @click="sentPasswordResetEmail"
          data-cy="sentPasswordResetEmail"
          >{{ $t("login.sendEmail") }}</v-btn
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>
<script>
export default {
  props: ["passwordDialog"],
  data() {
    return {
      logForm: {
        email: "",
      },
      requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
    };
  },
  methods: {
    sentPasswordResetEmail() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("resetPassword", {
            email: this.logForm.email.trim().toLowerCase(),
          })
          .then(() => {
            //close dialog
            this.passwordDialog.open = false;
            this.logForm.email = "";
          });
      }
    },
    setEmail(email) {
      this.logForm.email = email;
    },
  },
};
</script>
