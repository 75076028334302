<template>
  <div>
    <tableComponent
      class="ma-5 pl-0 mt-6"
      ref="tableComponent"
      v-bind:tableSettings="tableSettings"
      v-bind:tableData="tableData"
      v-bind:headers="headers"
      v-on:newDocument="newDocument"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import tableComponent from "../../components/_utils/dataTableHelpers/tableComponent.vue";
export default {
  components: {
    tableComponent,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "managedInstructionAssignment.addManagedInstructionAssignment",
          color: "secondary",
          action: "newDocument",
          icon: "add",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedDueDate",
          componentName: "formattedDueDate",
        },
        {
          id: getUniqueId(),
          slotName: "item.managedInstructionAssignmentMembers",
          componentName: "managedInstructionAssignmentMembers",
        },
        {
          id: getUniqueId(),
          slotName: "item.managedInstructionAssignmentFormattedCreatedAt",
          componentName: "managedInstructionAssignmentFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.managedInstructionAssignmentAction",
          componentName: "managedInstructionAssignmentAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "managedInstructionAssignment",
      uriPartAppend: "",
      populate: ["instruction", "assignments.employee"],
      sort: "-createdAt",
      editFormPushName: "managedInstructionAssignmentForm",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("managedInstructionAssignment.tableHeader.instruction"),
          value: "instruction.name[0].name",
          align: "left",
        },
        {
          text: this.$t("managedInstructionAssignment.tableHeader.dueTo"),
          value: "formattedDueDate",
        },
        {
          text: this.$t("managedInstructionAssignment.tableHeader.user"),
          value: "managedInstructionAssignmentMembers",
        },
        {
          text: this.$t("managedInstructionAssignment.tableHeader.createdAt"),
          value: "managedInstructionAssignmentFormattedCreatedAt",
        },
        {
          text: "",
          value: "managedInstructionAssignmentAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store
        .dispatch("deleteMultipleOperatingmanagedInstructionAssignment", item)
        .then(() => {
          this.reloadDataFunc();
          this.multipleDeleteDialog.open = false;
          this.multipleDeleteDialog.items = [];
        });
    },
  },
};
</script>
