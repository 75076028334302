<template>
  <div>
    <div v-if="backToWork">{{ $t("incidentReports.yes") }}</div>
    <div v-else>{{ $t("incidentReports.no") }}</div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    backToWork() {
      if (this.item.incidentReportType === "long") {
        return this.item.longIncidentReport?.workTimeAndAbsence
          .didStartWorkAgain  === 'laterAt'
      } else {
        return this.item.backToWork;
      }
    },
  },
};
</script>
