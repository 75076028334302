<template>
  <div>
    <v-stepper max-width="1400px" v-model="surveyId" vertical flat outlined>
      <div>
        <div class="mx-7 my-8">
          <h2>{{ $t("survey.interviewTitle") }}</h2>
          <!--div class="caption">Beschreibungstext hier einfügen</div-->
        </div>
      </div>
      <div v-bind:key="index" v-for="(category, index) in surveyData">
        <v-stepper-step :complete="surveyId > index" :step="index + 1">
          {{ category.title }}
          <small> {{ category.subtitle }}</small>
        </v-stepper-step>

        <v-stepper-content :step="index">
          <v-breadcrumbs :items="category.questions">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :disabled="item != category.questions[questionId]"
              >
                {{ item.title }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <questionCard
            :item="category.questions[questionId]"
            @nextQuestion="
              nextQuestion(index, category.questions[questionId + 1])
            "
          />
          <v-row class="ma-1 mt-4">
            <div class="flex-grow-1"></div>
            <v-btn
              text
              @click="priorQuestion(index, category.questions[questionId - 1])"
            >
              {{ $t("general.back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="nextQuestion(index, category.questions[questionId + 1])"
            >
              {{ $t("general.forward") }}
            </v-btn></v-row
          >
        </v-stepper-content>
      </div>
    </v-stepper>
  </div>
</template>
<script>
import * as surveyData from "../../components/api/content/survey/surveyContent.json";
import questionCard from "./questionCard.vue";
export default {
  components: { questionCard },
  data() {
    return {
      surveyData: null,
      surveyId: 0,
      questionId: 0,
    };
  },
  created() {
    //set data
    this.surveyData = surveyData.surveyCategories;
    this.scroll(0);
  },

  methods: {
    reloadFooter() {
      this.$emit("footerReload");
    },
    priorQuestion(surveyDataIndex, question) {
      //check if end off array
      if (this.surveyData[surveyDataIndex - 1] || question) {
        //not end
        const survey = this.surveyData[surveyDataIndex];
        //check if at the end of questions array
        if (survey.questions.length > this.questionId - 1) {
          //end
          if (this.questionId == 0) {
            this.surveyId--;
          } else {
            this.questionId--;
          }
        }
      }
      this.reloadFooter();
    },
    nextQuestion(surveyDataIndex, question) {
      this.scroll(this.surveyId);
      //check if end off array
      if (this.surveyData[surveyDataIndex + 1] || question) {
        //not end
        const survey = this.surveyData[surveyDataIndex];
        //check if at the end of questions array
        if (survey.questions.length > this.questionId + 1) {
          //not end
          this.questionId++;
        } else {
          //end
          //reset question id
          this.questionId = 0;
          //increase question id to next question
          this.surveyId++;
          this.scroll(this.surveyId);
        }
      } else {
        //end
        this.$router.push("/register/survey");
      }
      this.reloadFooter();
    },
    scroll(id) {
      //scroll
      this.$vuetify.goTo(230 * (id + 1), {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
  },
};
</script>
