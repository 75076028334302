<template>
  <div class="d-flex my-3">
    <div class="text-h4 font-bold">
      <slot></slot>
    </div>
    <div v-if="helpInfo" class="ml-1">
      <help-dialog ref="helpDialog" :data="helpInfo" />
    </div>
  </div>
</template>
<script>
import HelpDialog from "@/components/helpDialog.vue";

export default {
  name: "heading",
  components: { HelpDialog },
  props: ["helpInfo"],
  data: () => ({}),
};
</script>
