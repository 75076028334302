import StoreUtil from "../store.util.js";
const mutations = {
  SET_ALL_TICKETS(state, payload) {
    state.ticketList = StoreUtil.updateState(state.ticketList, payload);
  },
  SET_BOARD(state, payload) {
    state.populatedBoards = StoreUtil.updateState(state.populatedBoards, payload);
  },
};

export default mutations;
