<template>
  <div>
    <v-row align="center" dense justify="end" justify-sm="start" class="my-1">
      <v-col
        v-for="name in shortInstructionAssignedNames"
        v-bind:key="name"
        class="flex-grow-0"
      >
        <v-chip small>
          {{ name }}
        </v-chip>
      </v-col>
      <v-col v-if="item?.assignedNames?.length <= 3">
        <v-btn
          small
          depressed
          rounded
          @click="$refs.instructionTableAssignedNamesDialog.openDialog()"
        >
          {{ $t('instructions.instructionsDialog.assignedNames.add') }}
        </v-btn>
      </v-col>
      <v-col v-else>
        <v-btn
          small
          depressed
          rounded
          @click="$refs.instructionTableAssignedNamesDialog.openDialog()"
        >
          {{
            $tc(
              "instructions.instructionsDialog.assignedNames.andMore",
              item?.assignedNames?.length - 3,
              { count: item?.assignedNames?.length - 3 }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <instruction-table-assigned-names-dialog
      ref="instructionTableAssignedNamesDialog"
      :assigned-names="item?.assignedNames"
      @saveInstruction="onSaveInstruction"
    />
  </div>
</template>
<script>
import InstructionTableAssignedNamesDialog from "@/views/instructions/instructionTableAssignedNamesDialog.vue";

export default {
  components: {
    InstructionTableAssignedNamesDialog,
  },
  props: ["item"],
  computed: {
    shortInstructionAssignedNames() {
      return this.item?.assignedNames?.length
        ? this.item?.assignedNames?.slice(0, 3)
        : [];
    },
  },
  methods: {
    onSaveInstruction(assignedNames) {
      const instruction = {
        ...this.item,
        assignedNames,
      };
      // this.item = instruction;
      this.$store
        .dispatch("updateInstruction", instruction)
        .then(() => this.$emit("reloadData"))
        .catch(() => console.error("ERROR"));
    },
  },
};
</script>
