<template>
  <!--translate-->
  <v-dialog v-model="importDialog.open" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline">{{ $t(importDialog.title) }}</v-card-title>
      <v-card-text
        >Mitarbeiter können mithilfe einer Excel-Datei importiert werden, eine
        Beispieldatei ist
        <a
          href="https://s3.cloud2.c-dev.io/sp-templating/downloads/sp-mitarbeiter.xlsx"
          target="_blank"
          >hier</a
        >
        verfügbar.</v-card-text
      >
      <div class="ml-6 mb-4">
        <div class="subtitle-1 mt-5">{{ $t("examination.addFile") }}</div>
        <input
          @change="handleImage"
          class="mt-2"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="importDialog.open = false">{{
          $t("general.cancel")
        }}</v-btn>
        <v-btn
          color="error"
          text
          @click="$emit('triggerUpload', file)"
          data-cy="importEvent"
          >importieren</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["importDialog"],
  data() {
    return {
      file: {
        filetype: null,
        name: null,
        content: null,
      },
    };
  },
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file.filetype = fileObject.name
          .match(/\.[0-9a-z]+$/i)[0]
          .split(".")[1];
        this.file.name = fileObject.name;
        this.file.content = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>
