import axios from "axios";
import config from "./store/config/index.js";
import { SubscriptionJS } from "./billwerk/subscription.js";
import { billwerkStaticData } from "./billwerk/billwerk-static-data.js";
//frontend api for general functions

export function getBackendServer() {
  return config.state.backendServer;
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem("token");
}

export function removeDataFromLocalStorage() {
  //remove vuex from localstorage
  localStorage.removeItem("vuex");
  //remove token from localstorage
  localStorage.removeItem("token");
  //remove userDate from localstorage
  localStorage.removeItem("userData");
  localStorage.removeItem("userInfo");
}

export function isAuthenticated() {
  //check if token is not present
  if (getTokenFromLocalStorage() == null || getTokenFromLocalStorage() == "") {
    return new Promise((resolve) => {
      resolve(false);
    });
  }
  const headers = {
    headers: {
      Authorization: getTokenFromLocalStorage(),
    },
  };
  return axios
    .get("isAuthenticated", headers)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

export function formatCurrency(number) {
  let roundedNumber = parseFloat(Math.round(number * 100) / 100);
  return roundedNumber.toFixed(2).toString().replace(".", ",");
}

export function getBillwerkApiUrl() {
  return SubscriptionJS.apiUrl;
}

export function getBillwerkPortalUrl() {
  return SubscriptionJS.selfServiceUrl;
}

export function getUniqueId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}

export function objectToLowerCase(obj) {
  //transforms keys ob object to lower case, usefull for billwerk
  return Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});
}

export function getBillwerkPublicApiKey() {
  return billwerkStaticData.publicApiKey;
}

export function returnToLogin() {
  //remove token from localstorage
  removeDataFromLocalStorage();
  //create login redirect
  //remove params from url
  this.$router.replace(
    "/login/login/1/" +
      encodeURIComponent(window.location.origin + window.location.pathname) + "/1"
  );
}

export { default as dateUtil } from "./components/api/date.util";
export { SubscriptionJS } from "./billwerk/subscription.js";
