<template>
  <div>
    <v-datetime-picker
      v-model="value.incidentDate"
      :label="$t('incidentReports.incidentDate')"
      prependIcon="event"
      :date-picker-props="dateProps"
      :time-picker-props="timeProps"
      dateFormat="dd.MM.yyyy"
      clearText="zurück"
      :dense="false"
      :hide-details="false"
    />
  </div>
</template>

<script>
import vDatetimePicker from "../../../_utils/formComponent/generalFormComponents/v2DateTimePicker.vue";

export default {
  components: { vDatetimePicker },
  props: ["value"],
  data() {
    return {
      dateProps: {
        locale: "de-de",
        "first-day-of-week": "1",
      },
      timeProps: {
        format: "24hr",
      },
    };
  },
};
</script>
