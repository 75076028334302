<template>
  <!--translate-->
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit
        </v-icon>
      </template>
      <span>Mitarbeiter bearbeiten</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>{{ $t("employee.deleteEmployee") }}</span>
    </v-tooltip>
    <deleteDialog
      v-on:triggerDelete="deleteItem"
      v-bind:item="item"
      v-bind:deleteDialog="deleteDialog"
    />
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "employee.deleteTitle",
      description: "",
    },
  }),
  methods: {
    editItem(item) {
      this.$router.push({
        name: "examinationEmployeeForm",
        params: { id: item._id, mode: "edit" },
      });
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      /*this.deleteDialog.description = this.$t(
        "employees.deleteTitleLong",
        {
          title: item.name,
        }
      );*/
      this.deleteDialog.description =
        "Soll der Mitarbeiter " + item.name + " wirklich gelöscht werden?";
    },
    deleteItem(item) {
      this.$store.dispatch("deleteEmployee", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
  },
};
</script>
