<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" csm="6" md="6">
        <v-text-field
          v-model="value.code"
          label="Code"
          dense
          outlined
          :rules="requiredRule"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["value", "config"],
  data: () => ({
    requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
