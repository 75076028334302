<template>
  <div>
    <v-menu
      v-model="datePickerMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      nudge-width="200"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          label="Seit wann bei dieser Tätigkeit?"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="value.employedSince"
        locale="de"
        type="month"
        :max="maxDate"
        @input="closeDatePicker"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      datePickerMenu: false,
      maxDate: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    formattedDate() {
      return this.value.employedSince
        ? new Date(this.value.employedSince).toLocaleDateString("de-DE", {
            year: "numeric",
            month: "long",
          })
        : "";
    },
  },
  methods: {
    closeDatePicker() {
      this.datePickerMenu = false;
    },
  },
};
</script>
