import axios from "axios";

const actions = {
  createJob(context, value) {
    return axios
      .post("job", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Wiederholung " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  createJobAndTriggerExecution(context, value) {
    return axios
      .post("job", value)
      .then(function (r) {
        //execute job
        context.dispatch("executeJob", { _id: r.data._id }).then(() => {
          //force reload window
          location.reload();
        });
        context.commit(
          "CREATE_SNACKBAR",
          "Wiederholung " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  executeJob(context, value) {
    return axios
      .post("job/" + value._id + "/execute")
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Wiederholung erfolgreich ausgeführt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteJob(context, val) {
    return axios
      .delete("job/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Wiederholung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateJob(context, value) {
    return axios
      .put("job/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Wiederholung " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleJob(context, id) {
    return axios
      .get("job/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
