<template>
  <div>
    <v-radio-group v-model="value.gender">
      <template v-slot:label>
        {{ $t("incidentReports.incidentReportFormLong.gender") }}
      </template>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.female')"
        value="female"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.male')"
        value="male"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {},
};
</script>
