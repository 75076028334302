<template>
  <div>
    <tableComponent
      class="pa-5 mt-6"
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      @newDocument="newDocument"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../../components/api/api";
import tableComponent from "../../../components/_utils/dataTableHelpers/tableComponent.vue";

export default {
  components: {
    tableComponent,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedRole",
          componentName: "formattedRole",
        },
        {
          id: getUniqueId(),
          slotName: "item.medkitlogFormattedCreatedAt",
          componentName: "medkitlogFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.userAction",
          componentName: "usersAction"
        }
      ],
      itemKey: "_id",
      uriPartPrepend: "user",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "usersForm",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("settings.usersTableHeader.name"),
          value: "name",
          align: "left",
        },
        { text: this.$t("settings.usersTableHeader.email"), value: "email" },
        {
          text: this.$t("settings.usersTableHeader.role"),
          value: "formattedRole",
        },
        {
          text: this.$t("settings.usersTableHeader.createdAt"),
          value: "medkitlogFormattedCreatedAt",
        },
        {
          text: "",
          value: "userAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    //header button
    this.tableSettings.headerButtons.push({
      title: "settings.createUser",
      color: "secondary",
      action: "newDocument",
      icon: "add",
      iconDark: true,
      dark: true,
      outlined: true,
    });
    //actions slot
    this.tableData.tableComponentSlots.push({
      id: getUniqueId(),
      slotName: "item.usersAction",
      componentName: "usersAction",
    });
    //action header
    this.tableData.headers.push({
      text: "",
      value: "usersAction",
      align: "right",
      sortable: false,
    });
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store.dispatch("deleteMultipleUsers", item).then(() => {
        this.reloadDataFunc();
        this.multipleDeleteDialog.open = false;
        this.multipleDeleteDialog.items = [];
      });
    },
  },
};
</script>
