import axios from "axios";
import i18n from "../../../i18n";

const actions = {
  createDangerousGoodsStorageLocation(context, value) {
    return axios
      .post("dangerousGoodStorageLocation", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("dangerousGoods.storageLocation.createdMessage", {
            name: r.data.name,
          })
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteDangerousGoodsStorageLocation(context, val) {
    return axios
      .delete("dangerousGoodStorageLocation/" + val.id)
      .then(function (res) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("dangerousGoods.storageLocation.deletedMessage")
        );
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateDangerousGoodsStorageLocation(context, value) {
    return axios
      .put("dangerousGoodStorageLocation/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("dangerousGoods.storageLocation.updatedMessage", {
            name: r.data.name,
          })
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleDangerousGoodsStorageLocation(context, id) {
    return axios
      .get("dangerousGoodStorageLocation/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
