<template>
  <div>
    <v-row class="my-3">
      <v-icon class="mr-2" disabled>timeline</v-icon>
      <div class="subtitle-1">{{ $t("tickets.view.activities") }}</div></v-row
    >
    <div
      class="ml-5"
      v-bind:key="index"
      v-for="(comment, index) in item.activitys"
    >
      <div class="mb-1">
        <p>
          <b>{{ comment.user.name }}</b> :
          {{ formatContent(comment.content) }} -
          <i>{{ formatDate(comment.timestamp) + "h" }}</i>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { dateUtil } from "../../../../components/api/api";
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return dateUtil(new Date(date), true, "FEHLER");
    },
    formatContent(content) {
      /*
    Activity contents:
    1 - Add Ticket
    2 - Edit Ticket
    3 - Delete Ticket
    4 - Send Ticket as Mail
    5 - Add Attachment to Ticket
    6 - Add User to Ticket
    7 - Remove Attachment to Ticket
    8 - Add Comment to Ticket
    */
      let retVal = "";
      switch (content) {
        case "1":
          retVal = this.$t("tickets.view.addedTicket");
          break;
        case "2":
          retVal = this.$t("tickets.view.editedTicket");
          break;
        case "3":
          retVal = this.$t("tickets.view.deleteTicket");
          break;
        case "4":
          retVal = this.$t("tickets.view.mailTicket");
          break;
        case "5":
          retVal = this.$t("tickets.view.addAttachmentToTicket");
          break;
        case "6":
          retVal = this.$t("tickets.view.addUserToTicket");
          break;
        case "7":
          retVal = this.$t("tickets.view.deleteAttachmentFromTicket");
          break;
        case "8":
          retVal = this.$t("tickets.view.addedCommentToTicket");
          break;
      }
      return retVal;
    },
  },
};
</script>