<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.managedInstruction") }}
    </heading>
    <div class="d-flex justify-space-between mb-5">
      <v-tabs v-model="tab">
        <v-tab
          v-for="tab of tabs"
          v-bind:key="tab.id"
          v-bind:to="tab.route"
          v-bind:exact="!!tab.exact"
        >
          {{ headers(tab.id) }}
        </v-tab>
      </v-tabs>
      <!-- <v-btn color="primary" flat class="ma-2">Zuweisen</v-btn> -->
    </div>
    <v-card outlined>
      <router-view></router-view>
    </v-card>

    <v-tab-item
      v-for="tab of tabs"
      v-bind:key="tab.id"
      v-bind:value="tab.route"
      v-bind:transition="false"
      v-bind:reverse-transition="false"
    >
    </v-tab-item>
  </div>
</template>

<script>
import * as sidebarTextDE from "@/components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "@/components/api/content/sidebarText-en.json";

export default {
  name: "NewManagedInstruction",
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.managedInstruction;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.managedInstruction;
      }
      return sidebarTextDE.default.managedInstruction;
    },
  },
  data: () => ({
    tabs: [
      { id: 1, route: "/app/managedInstructions", exact: true },
      { id: 2, route: "/app/managedInstructions/employee" },
    ],
    data: null,
    tab: undefined,
  }),
  methods: {
    headers(i) {
      switch (i) {
        case 1:
          return this.$t(
            "managedInstructions.tabs.managedInstructionAssignments"
          );
        case 2:
          return this.$t('managedInstructions.tabs.assign')
      }
    },
  },
};
</script>

<style scoped></style>
