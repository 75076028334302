const classifications = require("../../../assets/dangerousGoodsStorageClassification.json");
const hSentences = require("../../../assets/dangerousGoodsHSentences.json");
const pSentences = require("../../../assets/dangerousGoodsPSentences.json");

const allPictograms = [
  {
    image: "/dangerousGoods/pictogram_corrosive_kat_1.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.corrosiveKat1",
  },
  {
    image: "/dangerousGoods/pictogram_environmental_risk.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.environmentalRisk",
  },
  {
    image: "/dangerousGoods/pictogram_explosive.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.explosive",
  },
  {
    image: "/dangerousGoods/pictogram_flammable.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.flammable",
  },
  {
    image: "/dangerousGoods/pictogram_gas.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.gas",
  },
  {
    image: "/dangerousGoods/pictogram_health_risk.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.healthRisk",
  },
  {
    image: "/dangerousGoods/pictogram_oxidizing.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.oxidizing",
  },
  {
    image: "/dangerousGoods/pictogram_poisonous_kat_1_3.png",
    text: "dangerousGoods.dangerousGoodsList.pictograms.poisonousKat1To3",
  },
  {
    image: "/dangerousGoods/pictogram_poisonous_kat_4.png",
    title: "Achtung",
    text: "dangerousGoods.dangerousGoodsList.pictograms.poisonousKat4",
  },
];

const getters = {
  allPictograms: () => allPictograms,
  allStorageClassifications: () => classifications,
  hSentences: () => hSentences,
  pSentences: () => pSentences,
};

export default getters;
