<template>
  <div>
    <tableComponent
      class="pa-5 pl-0 mt-6"
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../../components/api/api";
import tableComponent from "../../../components/_utils/dataTableHelpers/tableComponent.vue";

export default {
  components: {
    tableComponent,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: false,
      headerButtons: [],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedJobType",
          componentName: "formattedJobType",
        },
        {
          id: getUniqueId(),
          slotName: "item.formattedNextExecution",
          componentName: "formattedNextExecution",
        },
        {
          id: getUniqueId(),
          slotName: "item.riskAssessmentFormattedCreatedAt",
          componentName: "riskAssessmentFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.jobAction",
          componentName: "jobAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "job",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "",
    },
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("recurringTicketsTableHeader.name"), value: "name", align: "left" },
        { text: this.$t("recurringTicketsTableHeader.type"), value: "formattedJobType" },
        { text: this.$t("recurringTicketsTableHeader.nextExecution"), value: "formattedNextExecution" },
        { text: this.$t("recurringTicketsTableHeader.createdAt"), value: "riskAssessmentFormattedCreatedAt" },
        {
          text: "",
          value: "jobAction",
          align: "right",
          sortable: false,
        },
      ]
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
  },
};
</script>
