const getters = {
    version: state => state.version,
    backendServer: state => state.backendServer,
    baseRedirectServer: state => state.baseRedirectServer,
    error: state => state.error,
    snackbar: state => state.snackbar,
    universalAlert: state => state.universalAlert
  };
  
  export default getters;
  