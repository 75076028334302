<template>
  <div>
    <singleDateForm
      v-model="value.nextExecution"
      :label="$t('instructions.nextInstructionDate')"
      data-cy="nextExecution"
      prependIcon="event"
      :rules="requieredRule"
    />
  </div>
</template>
<script>
import singleDateForm from "../../_utils/general/singleDateForm.vue";
export default {
  props: ["value"],
  components: { singleDateForm },
  data: () => ({
    requieredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
  }),
  methods: {},
};
</script>
