<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-tabs v-model="currentTab">
      <v-tab v-for="item in items" :key="item.text">
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="(item, index) in items" :key="item.text">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div>
              {{ title }}
            </div>
          </v-card-title>
          <v-banner>{{ item.bannerMessage }}</v-banner>
          <v-card-text v-if="index === currentTab">
            <tableComponent
              ref="tableComponent"
              :key="reloadTrigger"
              :headers="headers"
              :tableSettings="tableSettings"
              :tableData="tableData"
              @remindEveryone="remindEveryone"
              @dataChanged="data = $event"
            />
          </v-card-text>

          <v-spacer />

          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" v-on:click="isOpen = false">{{
              $t("general.back")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getUniqueId } from "../../components/api/api";

export default {
  props: {
    instructionId: {
      type: () => String | undefined,
      required: true,
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.reloadTrigger++;
      } else {
        this.$emit("dismiss");
      }
    },
  },
  computed: {
    existsEmployeeWithNotCompletedAssignment() {
      if (!this.data) {
        return false;
      }

      return this.data.some((item) => !this.isCompleted(item));
    },
    noDataText() {
      return this.currentTab === 0
        ? this.$t(
            "managedInstructions.assignedDialog.assignedAssignmentsTab.noDataText"
          )
        : this.$t(
            "managedInstructions.assignedDialog.openAssignmentsTab.noDataText"
          );
    },
    headers() {
      const headerButtons = [
        {
          text: this.$t(
            "managedInstructions.assignedDialog.headerButtons.name"
          ),
          value: "name",
          align: "left",
        },
        {
          text: this.$t(
            "managedInstructions.assignedDialog.headerButtons.mail"
          ),
          value: "mail",
          align: "left",
        },
      ];
      if (this.currentTab !== 0) {
        headerButtons.push({
          text: this.$t(
            "managedInstructions.assignedDialog.headerButtons.action"
          ),
          value: "action",
          align: "left",
        });
      }
      if (this.items[this.currentTab]?.showOverdue) {
        headerButtons.unshift({
          text: this.$t(
            "managedInstructions.assignedDialog.headerButtons.dueDate"
          ),
          value: "dueDate",
          align: "left",
        });
      }
      return headerButtons;
    },
    tableSettings() {
      const headerButtons = [];
      if (this.currentTab !== 0) {
        headerButtons.push({
          title: "managedInstructions.assignedDialog.remindEveryone",
          color: "error",
          action: "remindEveryone",
          icon: "mdi-send-clock",
          iconDark: true,
          dark: true,
          text: true,
          disabled: !this.existsEmployeeWithNotCompletedAssignment,
        });
      }
      return {
        title: "",
        showSelect: false,
        showHeaderToolbar: true,
        multipleSelectButtons: [],
        headerButtons,
      };
    },
    uriPartPrepend() {
      return this.currentTab !== 0
        ? `managedInstruction/${this.instructionId}/openassignments`
        : `/managedInstruction/${this.instructionId}/assigned`;
    },
    uriPartAppend() {
      return this.currentTab !== 0
        ? `&overdue=${this.items[this.currentTab]?.showOverdue ? "1" : "0"}`
        : "";
    },
    tableData() {
      const tableComponentSlots = [
        {
          id: getUniqueId(),
          slotName: "item.dueDate",
          componentName: "managedInstructionAssignmentAssignedDialogDueDate",
        },
        {
          id: getUniqueId(),
          slotName: "item.name",
          componentName: "managedInstructionAssignmentAssignedDialogName",
        },
        {
          id: getUniqueId(),
          slotName: "item.mail",
          componentName: "managedInstructionAssignmentAssignedDialogEmail",
        },
        {
          id: getUniqueId(),
          slotName: "item.action",
          componentName: "managedInstructionAssignmentAssignedDialogAction",
        },
      ];

      if (this.currentTab !== 0) {
        tableComponentSlots.push({
          id: getUniqueId(),
          slotName: "item.managedInstructionAssignmentAction",
          componentName: "managedInstructionAssignmentAction",
        });
      }
      return {
        tableComponentSlots,
        itemKey: "_id",
        uriPartPrepend: this.uriPartPrepend,
        uriPartAppend: this.uriPartAppend,
        populate: [],
        sort: "-createdAt",
      };
    },
    title() {
      if (this.currentTab === 0) {
        return this.$t(
          "managedInstructions.assignedDialog.assignedAssignmentsTab.title"
        );
      } else {
        return this.$t(
          "managedInstructions.assignedDialog.openAssignmentsTab.title"
        );
      }
    },
    items() {
      return [
        {
          text: this.$t(
            "managedInstructions.assignedDialog.assignedAssignmentsTab.title"
          ),
          showOverdue: false,
          bannerMessage: this.$t(
            "managedInstructions.assignedDialog.assignedAssignmentsTab.bannerMessage"
          ),
        },
        {
          text: this.$t(
            "managedInstructions.assignedDialog.openAssignmentsTab.title"
          ),
          showOverdue: false,
          bannerMessage: this.$t(
            "managedInstructions.assignedDialog.openAssignmentsTab.bannerMessage"
          ),
        },
      ];
    },
  },
  data: () => ({
    isOpen: false,
    data: undefined,
    remindEmployeeProgress: undefined,
    currentTab: 0,
    reloadTrigger: 0,
  }),
  methods: {
    isCompleted(item) {
      return item.assignments?.completedAt ?? false;
    },
    openDialog(tab) {
      this.isOpen = true;
      this.currentTab = tab ?? 0;
    },
    async sendReminder({ instructionId, employee }) {
      await axios.post(
        `/managedInstructionAssignment/${instructionId}/${employee.id}/reminder`
      );
      this.isSent = true;
    },
    async remindEveryone() {
      if (this.remindEmployeeProgress !== undefined) {
        return;
      }

      this.remindEmployeeProgress = 0;

      const toRemind = this.data.filter(
        (employee) => !this.isCompleted(employee)
      );

      const totalCount = toRemind.length;
      let completedCount = 0;

      let start = Date.now();

      const results = await Promise.allSettled(
        toRemind.map((employeeOverDue) =>
          this.sendReminder({
            instructionId: employeeOverDue._id,
            employee: {
              id: employeeOverDue.assignments.employee._id,
              name: employeeOverDue.assignments.employee.name,
            },
          }).finally(() => {
            completedCount++;
            this.remindEmployeeProgress = Math.ceil(
              (completedCount / totalCount) * 100
            );
          })
        )
      );
      if (
        results.some((result) => result.status === "rejected") &&
        !results.some((result) => result.status === "fulfilled")
      ) {
        await this.$store.dispatch(
          "triggerSnackbar",
          this.$t("managedInstructions.assignedDialog.notificationFailed")
        );
      } else if (results.some((result) => result.status === "rejected")) {
        await this.$store.dispatch(
          "triggerSnackbar",
          this.$t("managedInstructions.assignedDialog.notificationPartlyFailed")
        );
      } else {
        this.$store.dispatch(
          "triggerSnackbar",
          this.$t("managedInstructions.assignedDialog.notificationToAllSent")
        );
      }

      //make loading animation last at least 750ms to make it look less "choppy"

      setTimeout(() => {
        this.remindEmployeeProgress = undefined;
      }, Math.max(0, start - Date.now() + 750));
    },
  },
};
</script>
