<template>
  <v-dialog v-model="data.open" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(data.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="ticketFormRecurring"
            v-model="ticketFormRecurring.formData.payload"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{
          $t("general.back")
        }}</v-btn>
        <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
          <div v-if="data.method === 'edit'">
            {{ $t("general.save") }}
          </div></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function getDefaultData() {
  return {
    recurring: 0,
    title: "",
    description: "",
    dueDate: null,
    users: [],
    priority: 1,
    board: null,
  };
}
import formComponent from "../../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../../components/api/api";
export default {
  components: { formComponent },
  props: ["data"],
  data: () => ({
    ticketFormRecurring: {
      formData: getDefaultData(),
      form: [
        //v-row
        [
          {
            cols: 12,
            sm: 4,
            md: 4,
            class: "pb-0 mt-4",
            id: getUniqueId(),
            componentName: "board",
            config: {},
          },
        ],
        [
          {
            //v-text-field
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "title",
              label: "Name / Titel",
              dataCy: "title",
              rules: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
            },
          },
          {
            //v-text-field
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "vQuillEditor",
            config: {
              vModel: "description",
              label: "Beschreibung",
              dataCy: "description",
            },
          },
        ],
        [
          {
            cols: 12,
            sm: 4,
            md: 4,
            class: "pb-0 mt-5",
            id: getUniqueId(),
            componentName: "dueDate",
            config: {},
          },
          {
            //v-select
            cols: 12,
            sm: 4,
            md: 4,
            class: "pb-0 mt-5",
            id: getUniqueId(),
            componentName: "vSelect",
            config: {
              vModel: "priority",
              items: [
                { name: "gering", value: 1 },
                { name: "mittel", value: 2 },
                { name: "hoch", value: 3 },
              ],
              label: "Priorität",
              dataCy: "priority",
              rules: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
            },
          },
        ],
        [
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "user",
            config: {},
          },
        ],
      ],
    },
  }),
  methods: {
    presetData(data) {
      //set board
      if (data.board) {
        this.ticketFormRecurring.formData.board = data.board;
      }
    },
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //validate form
      if (this.$refs.formComponent.validateAllFields()) {
          //edit item
          this.$store
            .dispatch("updateJob", this.ticketFormRecurring.formData)
            .then(() => {
              if (createNew) {
                //reset ticket data to create new one
                this.resetData();
              } else {
                //close form
                this.closeform();
              }
            });
      }
    },
    resetData() {
      //reset whole form data
      this.ticketFormRecurring.formData = getDefaultData();
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
    triggerEdit(item) {
      //set edit item from Page trigger
      this.ticketFormRecurring.formData = item;
    },
    triggerCopy(item) {
      //set copy item from ticketOverview Page trigger
      //delete item references for copy
      delete item.id;
      delete item._id;
      delete item.formattedDocumentId;
      item.numberRange = null;
      item.documentId = null;
      this.ticketFormRecurring.formData = item;
    },
  },
};
</script>
