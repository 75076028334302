<template>
  <div>
    <tableComponent
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      @openForm="openForm"
    />
    <instructionForm
      ref="instructionForm"
      :data="instructionForm"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import instructionForm from "./instructionForm.vue";
export default {
  components: {
    instructionForm,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "instructions.addInstruction",
          color: "secondary",
          action: "openForm",
          icon: "cloud_upload",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedDocumentType",
          componentName: "formattedDocumentType",
        },
        {
          id: getUniqueId(),
          slotName: "item.operationInstructionCreatedAt",
          componentName: "operationInstructionCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.instructionAction",
          componentName: "instructionAction",
        },
        {
          id: getUniqueId(),
          slotName: "item.instructionAssignedNames",
          componentName: "instructionAssignedNames",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "instruction",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "instructionForm",
    },
    instructionForm: {
      open: false,
      title: "instructions.formTitle",
      item: null,
      method: "new",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("instructions.tableHeader.description"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("instructions.tableHeader.type"),
          value: "formattedDocumentType",
        },
        {
          text: this.$t("instructions.tableHeader.createdAt"),
          value: "operationInstructionCreatedAt",
        },
        {
          text: this.$t("instructions.tableHeader.assignedNames"),
          value: "instructionAssignedNames",
        },
        {
          text: "",
          value: "instructionAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    openForm() {
      this.instructionForm.open = true;
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store
        .dispatch("deleteMultipleOperatingInstruction", item)
        .then(() => {
          this.reloadDataFunc();
          this.multipleDeleteDialog.open = false;
          this.multipleDeleteDialog.items = [];
        });
    },
  },
};
</script>
