import axios from "axios";

const actions = {
  createOperatingInstruction(context, value) {
    return axios
      .post("operatingInstruction", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Betriebsanweisung " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteOperatingInstruction(context, val) {
    return axios
      .delete("operatingInstruction/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Betriebsanweisung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateOperatingInstruction(context, value) {
    return axios
      .put("operatingInstruction/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Betriebsanweisung " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleOperatingInstruction(context, id) {
    return axios
      .get("operatingInstruction/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getPdfOperatingInstruction(context, id) {
    return axios
      .get("operatingInstruction/" + id + "/pdf")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
