import axios from "axios";

const actions = {
  getSettingData(context, name) {
    return axios
      .get("settings/" + name)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  setSettingData(context, item) {
    return axios
      .put("settings/" + item.id, item)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Einstellung aktualisiert");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
