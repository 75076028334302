<template>
  <div>
    {{
      $t("managedInstructions.assignedDialog.tableSlotComponents.dueDate", {
        days: formatSince(new Date(item?.dueDate)),
      })
    }}
  </div>
</template>
<script>
import { sinceDate } from "../../../api/date.util";

export default {
  props: ["item"],
  methods: {
    formatSince: sinceDate,
  },
};
</script>
