<template>
  <div>
    <v-simple-table class="ma-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Rolle</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in multiusers" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.role }}</td>
            <td>
              <v-btn
                color="primary"
                outlined
                small
                @click.stop="removeMultiuser(item.id)"
              >
                Zugang entfernen
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  components: {},
  data: () => ({
    multiusers: [],
  }),
  mounted() {
    JSON.parse(localStorage.getItem("userData")).client.multiusers.forEach(
      (element) => {
        this.$store.dispatch("getSingleUser", element).then((item) => {
          this.multiusers.push(item);
        });
      }
    );
  },
  methods: {
    removeMultiuser(id) {
      this.$store.dispatch("multiuserRevoke", { multiuser: id }).then((r) => {
        this.$store.dispatch("getUserInfo");
        //reload page
        location.reload();
      });
    },
  },
};
</script>
