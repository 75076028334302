<template>
  <v-layout class="align-center justify-center">
    <v-card width="700px">
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title>{{ $t("general.registerNewUser") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout align-center justify-center>
          <register ref="register" />
        </v-layout>
      </v-card-text>
    </v-card>
  </v-layout>
</template>
<script>
import register from "../../views/login/register.vue";
export default {
  components: { register },
  data() {
    return {};
  },
  methods: {},
};
</script>
