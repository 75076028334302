<template>
  <div>
    <div class="mb-4">
      <div class="subtitle-2">{{ $t("tickets.view.board") }}</div>
      <div class="subtitle-1">{{ item.board.name }}</div>
    </div>
    <div class="mb-4">
      <div class="subtitle-2">{{ $t("tickets.view.priority") }}</div>
      <div class="subtitle-1">
        <div v-if="item.priority === 1">
          <v-icon small color="green" class="mb-1">
            indeterminate_check_box
          </v-icon>
          gering
        </div>
        <div v-if="item.priority === 2">
          <v-icon small color="warning" class="mb-1">
            fiber_manual_record
          </v-icon>
          {{ $t("tickets.view.medium") }}
        </div>
        <div v-if="item.priority === 3">
          <v-icon small color="error" class="mb-1">
            fiber_manual_record
          </v-icon>
          {{ $t("tickets.view.high") }}
        </div>
        <div v-if="item.priority === 4">
          <v-icon small color="error" class="mb-1">
            fiber_manual_record
          </v-icon>
          {{ $t("tickets.view.veryHigh") }}
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="subtitle-2">{{ $t("tickets.view.user") }}</div>
      <div
        class="subtitle-1"
        v-bind:key="index"
        v-for="(user, index) in item.users"
      >
        {{ user.name }}
      </div>
    </div>
    <div
      v-if="item.dueDate && !item.board.final"
      class="mb-4"
      :style="isWeekPrior ? 'color: #b71c1c' : ''"
    >
      <div class="subtitle-2">{{ $t("tickets.view.dueTo") }}</div>
      <div class="subtitle-1">
        <v-icon small class="mb-1" :color="isWeekPrior ? 'error' : ''"
          >alarm</v-icon
        >
        {{ formatDate(item.dueDate) }}
      </div>
    </div>
    <div class="mb-4">
      <div class="subtitle-2">{{ $t("tickets.view.createdAt") }}</div>
      <div class="subtitle-1">
        {{ formatDate(item.createdAt) }}
      </div>
    </div>
    <div class="mb-10">
      <div class="subtitle-2">{{ $t("tickets.view.lastChanged") }}</div>
      <div class="subtitle-1">
        {{ formatDate(item.updatedAt) }}
      </div>
    </div>
    <div>
      <div class="subtitle-2">{{ $t("tickets.view.activities") }}</div>
      <!--div class="subtitle-1">{{ item.activitys }}</div-->
    </div>
  </div>
</template>
<script>
import { dateUtil } from "../../../../components/api/api";
export default {
  props: ["item"],
  data() {
    return {};
  },
  computed: {
    isWeekPrior() {
      if (this.item.dueDate) {
        //return true;
        //check if date is one week prior
        return new Date(this.item.dueDate) < new Date(Date.now() + 604800000);
      } else {
        return false;
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return dateUtil(new Date(date), false, "FEHLER");
    },
  },
};
</script>
