<template>
  <div>
    <v-autocomplete
      v-model="value.members"
      :items="employeeOrderDataMap"
      :loading="allEmployeesPending"
      hide-no-data
      item-text="Description"
      item-value="id"
      :label="$t('employeeGroup.tableHeader.employee')"
      data-cy="employeeOrder"
      multiple
      chips
      dense
    >
      <template v-slot:selection="data">
        <v-chip small v-bind="data.attrs">
          {{ data.item.Description }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  components: {},
  data: () => ({}),
  computed: {
    ...mapGetters(["allEmployees", "allEmployeesPending"]),
    employeeOrderDataMap() {
      return this.allEmployees.map((employee) => {
        const Description = employee.name;
        const id = employee._id;
        return Object.assign({}, employee, {
          Description,
          id,
        });
      });
    },
  },
  created() {
    //get getAllEmployees
    this.$store.dispatch("getAllEmployees");
  },
  methods: {},
};
</script>
