import axios from "axios";

const actions = {
  getAllEmployees(context) {
    context.commit("SET_ALL_EMPLOYEES");
    return axios
      .get("employee?sort=name")
      .then(function (r) {
        context.commit("SET_ALL_EMPLOYEES", r.data.data);
        return r.data.data;
      })
      .catch(function (e) {
        context.commit("SET_ALL_EMPLOYEES", e);
        context.dispatch("triggerError", e);
      });
  },

  createEmployee(context, value) {
    return axios
      .post("employee", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Mitarbeiter " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteEmployee(context, val) {
    return axios
      .delete("employee/" + val._id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Mitarbeiter erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateEmployee(context, value) {
    return axios
      .put("employee/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Mitarbeiter " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleEmployee(context, id) {
    return axios
      .get("employee/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  createEmployeeImport(context, value) {
    return axios
      .post("employee/batch", value)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Mitarbeiter erfolgreich importiert");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
