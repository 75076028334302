<template>
  <div>
    <v-card class="mx-auto my-5 mb-16" max-width="1000">
      <v-app-bar flat>
        <span class="headline">
          {{ formTitle }} -
          <span> {{ stepHeadline }}</span>
        </span>
      </v-app-bar>
      <v-card-text>
        <span class="headline">{{ formHeadline }}</span>
        <v-container>
          <div v-if="step === 1" class="step-1">
            <formComponent
              ref="companyFormComponent"
              :form="forms[0]"
              v-model="forms[0].data"
            />
          </div>
          <div v-if="step === 2" class="step-2">
            <formComponent
              ref="companyFormComponent"
              :form="forms[1]"
              v-model="forms[1].data"
            />
          </div>
          <div v-if="step === 3" class="step-3">
            <formComponent
              ref="companyFormComponent"
              :form="forms[2]"
              v-model="forms[2].data"
            />
          </div>
          <div v-if="step === 4" class="step-4">
            <formComponent
              ref="companyFormComponent"
              :form="forms[3]"
              v-model="forms[3].data"
            />
          </div>
          <div v-if="step === 5" class="step-5">
            <formComponent
              ref="companyFormComponent"
              :form="forms[4]"
              v-model="forms[4].data"
            />
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="back()">
          <span v-if="step > 1">
            {{ $t("incidentReports.incidentReportFormLong.actions.back") }}
          </span>
          <span v-else>
            {{ $t("incidentReports.incidentReportFormLong.actions.cancel") }}
          </span>
        </v-btn>
        <v-btn color="primary" @click="next()">
          <span v-if="step < totalSteps">
            {{ $t("incidentReports.incidentReportFormLong.actions.next") }}
          </span>
          <span v-else>
            {{ $t("incidentReports.incidentReportFormLong.actions.save") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { getUniqueId } from "../../components/api/api";
import formComponent from "../../components/_utils/formComponent/formComponent.vue";

export default {
  components: { formComponent },
  props: ["data"],
  computed: {
    stepHeadline() {
      return this.$t("incidentReports.incidentReportFormLong.step", {
        step: this.step,
        maxSteps: this.totalSteps,
      });
    },
    formHeadline() {
      if (this.step === 1) return "Unternehmensdaten";
      if (this.step === 2) return "Versicherte Person";
      if (this.step === 3) return "Angaben zum Unfall";
      if (this.step === 4) return "Ärztliche Versorung";
      if (this.step === 5) return "Arbeitszeit, Tätigkeit und Arbeitsausfall";

      return "";
    },
  },
  data: () => ({
    formTitle: "Unfallmeldung bearbeiten",
    step: 1,
    totalSteps: 5,
    forms: [
      {
        data: {
          name: undefined,
          street: undefined,
          houseNumber: undefined,
          zip: undefined,
          city: undefined,
          companyNumber: undefined,
          recipientName: undefined,
          recipientStreet: undefined,
          recipientHouseNumber: undefined,
          recipientZip: undefined,
          recipientCity: undefined,
        },
        form: [
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "name",
                label: "Unternehmensname",
              },
            },
          ],
          [
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "street",
                label: "Straße",
              },
            },
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "houseNumber",
                label: "Hausnummer",
              },
            },
          ],
          [
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "zip",
                label: "PLZ",
              },
            },
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "city",
                label: "Ort",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              class: "mb-5 mt-5",
              config: {
                vModel: "companyNumber",
                label: "Unternehmensnummer des Unfallversicherungsträgers",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "recipientName",
                label: "Empfänger Name (BG)",
              },
            },
          ],
          [
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "recipientStreet",
                label: "Empfänger Straße (BG)",
              },
            },
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "recipientHouseNumber",
                label: "Empfänger Hausnummer (BG)",
              },
            },
          ],
          [
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "recipientZip",
                label: "Empfänger PLZ (BG)",
              },
            },
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "recipientCity",
                label: "Empfänger Ort (BG)",
              },
            },
          ],
        ],
      },
      {
        data: {
          firstName: undefined,
          lastName: undefined,
          birthday: undefined,
          street: undefined,
          houseNumber: undefined,
          zip: undefined,
          city: undefined,
          gender: undefined,
          nationality: undefined,
          temporaryWorker: false,
          trainee: false,
          roleInCompany: undefined,
          weeksOfEntitlementToContinuedPaymentOfWages: 0,
          healthInsurance: undefined,
        },
        form: [
          [
            {
              cols: 12,
              sm: 6,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "firstName",
                label: "Vorname",
              },
            },
            {
              cols: 12,
              sm: 6,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "lastName",
                label: "Nachname",
              },
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "incidentReportFormLongBirthday",
            },
          ],
          [
            {
              cols: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "street",
                label: "Straße",
              },
              autocomplete: "postal-code",
            },
            {
              cols: 4,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "houseNumber",
                label: "Hausnummer",
              },
            },
          ],
          [
            {
              cols: 4,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "zip",
                label: "PLZ",
              },
            },
            {
              cols: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "city",
                label: "Ort",
              },
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "incidentReportFormLongGender",
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "nationality",
                label: "Staatsangehörigkeit",
              },
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "incidentReportFormLongTemporaryWorker",
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "incidentReportFormLongTrainee",
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "incidentReportFormLongRoleInCompany",
            },
          ],
          [
            {
              cols: 12,
              sm: 8,
              md: 6,
              lg: 4,
              id: getUniqueId(),
              componentName: "incidentReportFormLongContinuedPaymentWeeks",
            },
          ],
          [
            {
              cols: 12,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "healthInsurance",
                label: "Krankenkasse des Versicherten (Name, PLZ, Ort)",
              },
            },
          ],
        ],
      },
      {
        data: {
          incidentDate: null,
          street: undefined,
          houseNumber: undefined,
          zip: undefined,
          city: undefined,
          deadly: false,
          incidentDescription: undefined,
          descriptionProvidedBy: undefined,
          injuredBodyParts: undefined,
          typeOfInjury: undefined,
          firstContactAfterIncident: undefined,
          eyewitness: false,
        },
        form: [
          [
            {
              cols: 12,
              sm: 8,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongDateTime",
            },
          ],
          [
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "street",
                label: "Straße",
              },
            },
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "houseNumber",
                label: "Hausnummer",
              },
            },
          ],
          [
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "zip",
                label: "PLZ",
              },
            },
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "city",
                label: "Ort",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 8,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportDeadly",
            },
          ],
          [
            {
              cols: 12,
              sm: 8,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextarea",
              config: {
                vModel: "incidentDescription",
                label: "Unfallhergang",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 8,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongDescriptionProvidedBy",
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "injuredBodyParts",
                label: "Verletzte Körperteile",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "typeOfInjury",
                label: "Art der Verletzung",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "firstContactAfterIncident",
                label:
                  "Wer hat von dem Unfall zuerst Kenntnis genommen? (Name, Anschrift des Zeugen)",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 8,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongEyewitness",
            },
          ],
        ],
      },
      {
        data: {
          name: undefined,
          street: undefined,
          houseNumber: undefined,
          zip: undefined,
          city: undefined,
        },
        form: [
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "name",
                label: "Name des erstbehandelnden Arztes/Krankenhauses",
              },
            },
          ],
          [
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "street",
                label: "Straße",
              },
            },
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "houseNumber",
                label: "Hausnummer",
              },
            },
          ],
          [
            {
              cols: 4,
              sm: 4,
              md: 2,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "zip",
                label: "PLZ",
              },
            },
            {
              cols: 8,
              sm: 8,
              md: 6,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "city",
                label: "Ort",
              },
            },
          ],
        ],
      },
      {
        data: {
          startWork: null,
          endWork: null,
          employedAs: undefined,
          employedSince: null,
          partInCompanyPermanentlyEmployed: undefined,
          didStopWork: undefined,
          stoppedLaterDate: null,
          stoppedLaterTime: null,
          didStartWorkAgain: undefined,
          startedAgainDate: null,
        },
        form: [
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongStartAndEndWork",
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "employedAs",
                label: "Zum Unfallzeitpunkt beschäftigt/tätig als",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongEmployedSince",
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "partInCompanyPermanentlyEmployed",
                label:
                  "In welchem Teil des Unternehmens ist der Versicherte ständig tätig?",
              },
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongDidStopWork",
            },
          ],
          [
            {
              cols: 12,
              sm: 12,
              md: 8,
              id: getUniqueId(),
              componentName: "incidentReportFormLongDidStartWorkAgain",
            },
          ],
        ],
      },
    ],
  }),
  created() {
    this.switchFormModes(this.$route.params.mode, this.$route.params);
  },
  methods: {
    async switchFormModes(mode, params) {
      switch (mode) {
        case "edit":
          this.$store
            .dispatch("getSingleIncidentReport", params.id)
            .then((item) => {
              this.formTitle = this.$t("incidentReports.editIncidentReports");
              if (item.longIncidentReport) {
                this.setFormData(item.longIncidentReport);
              }
            });
          break;
        case "copy": {
          this.$store
            .dispatch("getSingleIncidentReport", params.id)
            .then((item) => {
              this.formTitle = this.$t("incidentReports.copyIncidentReports");
              if (item.longIncidentReport) {
                this.setFormData(item.longIncidentReport);
              }
            });
          break;
        }
        case "new":
          this.formTitle = this.$t("incidentReports.createIncidentReports");
          break;
      }
    },
    setFormData(longIncidentReport) {
      this.forms[0].data = {
        ...this.forms[0].data,
        ...(longIncidentReport.companyData ?? {}),
      };
      this.forms[1].data ={
        ...this.forms[1].data,
        ...(longIncidentReport.insuredPerson ?? {}),
      }
      this.forms[2].data ={
        ...this.forms[2].data,
        ...(longIncidentReport.accidentDetails ?? {}),
      }
      this.forms[3].data ={
        ...this.forms[3].data,
        ...(longIncidentReport.medicalCare ?? {}),
      }
      this.forms[4].data ={
        ...this.forms[4].data,
        ...(longIncidentReport.workTimeAndAbsence ?? {}),
      }
    },
    back() {
      if (this.step === 1) {
        this.$router.push({
          name: "incidentReport",
        });
        return;
      }
      this.step -= 1;
    },
    next() {
      if (this.step === this.totalSteps) {
        this.submit();
        return;
      }
      this.step += 1;
    },
    submit() {
      const longIncidentReport = {
        companyData: this.forms[0].data,
        insuredPerson: this.forms[1].data,
        accidentDetails: this.forms[2].data,
        medicalCare: this.forms[3].data,
        workTimeAndAbsence: this.forms[4].data,
      };
      const data = {
        longIncidentReport,
        incidentReportType: "long",
        id: this.$route.params.id,
      };

      if (
        this.$route.params.mode === "new" ||
        this.$route.params.mode === "copy"
      ) {
        //create new item
        this.$store.dispatch("createIncidentReport", data).then(() => {
          this.afterUpdateCheck();
        });
      } else {
        //edit item
        this.$store.dispatch("updateIncidentReport", data).then(() => {
          this.afterUpdateCheck();
        });
      }
    },
    afterUpdateCheck() {
      this.$router.back();
    },
  },
};
</script>
