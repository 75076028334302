<template>
  <div>
    <v-footer fixed padless>
      <v-card width="100%" tile>
        <v-card-text>
          <div>
            <v-progress-linear
              class="my-4"
              color="secondary"
              height="12"
              :value="value"
              striped
            ></v-progress-linear>
            <div class="pt-0 text-center caption">
              {{ $t("survey.editProgress") }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
import * as surveyData from "../../components/api/content/survey/surveyContent.json";
export default {
  components: {},
  data() {
    return {
      gesQuestions: 0,
      value: 0,
    };
  },
  mounted() {
    let questionCount = 0;
    surveyData.default.surveyCategories.forEach((r) => {
      questionCount = questionCount + r.questions.length;
    });
    this.gesQuestions = questionCount;
    //trigger reload
    this.reload();
  },
  methods: {
    reload() {
      this.$store.dispatch("getAllTicketsFromStorage").then((r) => {
        this.value = (r.values.length / this.gesQuestions) * 100;
      });
    },
  },
};
</script>
