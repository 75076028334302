<template>
  <div>
    <v-form
      @submit.prevent="register()"
      ref="form"
      lazy-validation
      class="pl-2"
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="regForm.name"
            prepend-icon="person"
            :label="$t('login.name')"
            type="text"
            :rules="requiredRule"
          ></v-text-field
        ></v-col>
        <v-col>
          <v-text-field
            v-model="regForm.email"
            prepend-icon="mail"
            :label="$t('login.email')"
            type="email"
            :rules="emailRules"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="clientData.name"
            prepend-icon="business"
            :label="$t('login.companyName')"
            type="text"
            :rules="requiredRule"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" sm="8">
          <v-text-field
            v-model="clientData.companyData.address.street"
            :label="$t('login.street')"
            type="text"
            :rules="requiredRule"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="2" sm="4">
          <v-text-field
            v-model="clientData.companyData.address.streetNumber"
            :label="$t('login.number')"
            type="number"
            :rules="requiredRule"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="clientData.companyData.address.postalCode"
            :label="$t('login.zip')"
            type="number"
            :rules="plzRules"
          ></v-text-field
        ></v-col>
        <v-col>
          <v-text-field
            v-model="clientData.companyData.address.city"
            :label="$t('login.city')"
            type="text"
            :rules="requiredRule"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="regForm.password"
            id="password"
            prepend-icon="lock"
            :label="$t('login.password')"
            :rules="passRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field
        ></v-col>
        <v-col>
          <v-text-field
            v-model="regForm.password1"
            id="password1"
            prepend-icon="lock"
            :label="$t('login.enterPassword')"
            :rules="passRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <p v-if="regForm.registerPasswWarning" class="error--text">
            {{ $t("login.passwordsNotIdentical") }}
          </p></v-col
        >
      </v-row>
      <v-checkbox :rules="requiredRule" v-model="checkbox" dense>
        <template v-slot:label>
          <div>
            {{ $t("login.clickHere") }}
            <a href="https://www.safetyplanner.de/datenschutz.html" target="_blank">
              {{ $t("login.privacy") }}
            </a>
            {{ $t("login.and") }}
            <a href="https://www.safetyplanner.de/agb.html" target="_blank">
              {{ $t("login.agreement") }}
            </a>
            {{ $t("login.accept") }}
          </div>
        </template>
      </v-checkbox>
      <v-checkbox :rules="requiredRule" v-model="legalNotice" dense>
        <template v-slot:label>
          {{ $t("login.legalNotice") }}
        </template>
      </v-checkbox>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          outlined
          type="submit"
          >{{ $t("login.registerNow") }}</v-btn
        >
      </v-card-actions>
    </v-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      checkbox: false,
      legalNotice: false,
      showPassword: false,
      plzRules: [
        (v) => !!v || "Eine Postleitzahl wird benötigt",
        (v) =>
          (v && v.length <= 5) ||
          "Die Postleitzahl darf nicht länger als 5 Ziffern sein",
      ],
      emailRules: [
        (v) => !!v || "Eine Email wird benötigt",
        (v) => /.+@.+/.test(v) || "Diese Email ist ungültig",
      ],
      passRules: [
        (v) => !!v || "Ein Passwort wird benötigt",
        (v) =>
          (!!v && v.length >= 8) ||
          "Das Passwort benötigt eine Mindestlänge von 8 Zeichen.",
      ],
      requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
      regForm: {
        name: "",
        email: "",
        password: "",
        password1: "",
        registerPasswWarning: false,
      },
      clientData: {
        name: "",
        license: "sp-free",
        companyData: {
          numberOfEmployees: 10,
          address: {
            postalCode: null,
            streetNumber: null,
            street: "",
            city: "",
            country: "DE",
          },
        },
      },
    };
  },
  methods: {
    register() {
      if(this.loading) return
      this.loading = true;

      if (this.$refs.form.validate()) {
        //check if both passwords are the same
        this.regForm.registerPasswWarning = false;

        if (this.regForm.password !== this.regForm.password1) {
          this.regForm.registerPasswWarning = true;
          this.loading = false;
        } else {
          //get tickets from interview
          this.$store.dispatch("getAllTicketsFromStorage").then((r) => {
            //both passwords are the same
            //trim data
            this.regForm.email = this.regForm.email.trim().toLowerCase();
            this.regForm.password = this.regForm.password.trim();
            this.regForm.password1 = this.regForm.password1.trim();
            this.$store
              .dispatch("register", {
                user: this.regForm,
                client: this.clientData,
                tickets: r.tickets,
                results: r.values,
              })
              .then((r) => {
                if (r) {
                  this.afterRegisterLogin(this.regForm);
                }
              });
          });
        }
      }
      this.loading = false;
    },
    afterRegisterLogin(data) {
      //redirect to login
      this.$store
        .dispatch("login", {
          email: data.email.trim().toLowerCase(),
          password: data.password.trim(),
        })
        .then(() => {
          this.loading = false;
          //save results from interview in settings
          this.$store.dispatch("getAllTicketsFromStorage").then((r) => {
            this.$store.dispatch("setSettingData", {
              id: "interviewResults",
              value: r,
            });
          });
          //redirect
          this.$router.push("/app/tickets/all");
        });
    },
  },
};
</script>
