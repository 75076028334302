import StoreUtil from "../store.util";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  examinations: StoreUtil.state(),
};

const examinationModule = {
  state,
  mutations,
  actions,
  getters,
};

export default examinationModule;
