<template>
  <div>
    <sidebar />
    <v-app-bar clipped-left app color="primary" dense dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title class="text-body-1">SafetyPlanner</v-toolbar-title>

      <v-spacer></v-spacer>
      <!--Only display when the user is a multiuser-->
      <div
        class="text-center"
        v-if="userType != null && userType == 'MultiUser'"
      >
        <v-select
          :items="multiuserSelect"
          v-model="selectedMultiuser"
          item-text="name"
          item-value="id"
          label="Multiuser (FASI) Organisation"
          class="mr-4 mt-7"
          dense
          outlined
          @change="switchMultiuser"
        ></v-select>
      </div>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined v-bind="attrs" v-on="on">
              {{ $i18n.locale }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in langs"
              :key="index"
              @click="setLanguage(item.value)"
            >
              <v-list-item-title>{{ item.display }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>
<script>
import sidebar from "@/components/layout/sidebar.vue";

export default {
  components: { sidebar },
  data() {
    return {
      langs: [
        { value: "de", display: "Deutsch" },
        { value: "en", display: "English" },
      ],
      userType: null,
      multiuserSelect: [],
      selectedMultiuser: { id: null, name: "" },
    };
  },
  mounted() {
    //get user data
    this.$store.dispatch("getUserInfo").then(() => {
      //if no locale is set, preset de
      if (localStorage.getItem("locale") == null) {
        localStorage.setItem("locale", "de");
      }
      this.$i18n.locale = localStorage.getItem("locale");
      //set user type
      this.userType = JSON.parse(localStorage.getItem("userData"))?.role;
      //set current multiuser
      this.selectedMultiuser.id = JSON.parse(
        localStorage.getItem("userData")
      )?.client._id;
      this.selectedMultiuser.name = JSON.parse(
        localStorage.getItem("userData")
      )?.client.name;
      if (this.userType == "MultiUser") {
        this.getMultiuserList();
      }
    });
  },
  methods: {
    setLanguage(item) {
      this.$i18n.locale = item;
      localStorage.setItem("locale", item);
      this.$store.commit("SWITCH_TABLE_LANGUAGE");
    },
    getMultiuserList() {
      this.$store.dispatch("getMultiuserClients").then((r) => {
        r.data.forEach((element) => {
          this.multiuserSelect.push(element);
        });
      });
    },
    switchMultiuser(item) {
      this.$store
        .dispatch("switchMultiuserClients", { client: item })
        .then(() => {
          //clear cache and localstorage
          localStorage.removeItem("userData");
          localStorage.removeItem("vuex");
          //reload page
          location.reload();
          //this.selectedMultiuser = item;
        });
    },
  },
};
</script>
