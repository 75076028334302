/***
 * function to ensure common date formatting
 * @param {Date} date - Date that should be formatted
 * @param {boolean} hours - Boolean to declare if time should be displayed after date
 * @param {boolean} string - Value which will be returned if date is not valid
 * @returns {string} - Formatted Date with given specification
 ***/
export default function formatDate(date, hours, replacement) {
  let formatDateNumber = (dateNumber) =>
    dateNumber < 10 ? "0" + dateNumber : dateNumber;

  try {
    let d =
      formatDateNumber(date.getDate()) +
      "." +
      formatDateNumber(date.getMonth() + 1) +
      "." +
      date.getFullYear();
    let h =
      formatDateNumber(date.getHours()) +
      ":" +
      formatDateNumber(date.getMinutes());

    return hours ? d + " " + h : d;
  } catch (e) {
    return replacement != null ? replacement : "-";
  }
}

/**
 * Calculates the number of whole days between a given date and the current date.
 * Note: This calculation is based on local time and rounds to the nearest whole day.
 *
 * @param {Date} date - The date to compare with the current date.
 * @return {number} The number of whole days between the given date and now. Returns NaN for invalid inputs.
 */
export function sinceDate(date) {
  // Validate input is a Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.error("Invalid date provided");
    return NaN; // or throw new Error('Invalid date provided');
  }

  let currentDate = new Date();
  let diff = currentDate.getTime() - date.getTime();

  // Convert difference from milliseconds to days and round to nearest whole number
  let dayDiff = Math.round(diff / (1000 * 60 * 60 * 24));

  return dayDiff;
}
