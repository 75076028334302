<template>
  <div>
    <v-toolbar class="mb-6" flat outlined rounded>
      <v-toolbar-title><b>{{ title }}</b></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="openBoardSettings">
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tickets.navbar.settings") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="openRecurringTickets">
            <v-icon>mdi-calendar-sync</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tickets.navbar.recurringTickets") }}</span>
      </v-tooltip>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  computed: {
    title() {
      const val = this.$route.params.mode
      if (val === "all") {
        return this.$t("tickets.navbar.tickets");
      } else if (val === "user") {
        return this.$t("tickets.navbar.myTickets");
      }

      return ""
    },
  },
  methods: {
    openBoardSettings() {
      this.$router.push({name: "boardSettings"});
    },
    openRecurringTickets() {
      this.$router.push("/app/tickets/recurring");
    },
  },
};
</script>
