<template>
  <div>
    <v-app-bar clipped-left app color="primary" dense dark>

      <v-toolbar-title class="text-body-1">SafetyPlanner</v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
