import router from "@/router";

const actions = {
  triggerError(context, error) {
    console.log(error);
    //trigger snackbar if login data is not correct
    if (error == "Error: Request failed with status code 401") {
      context.commit(
        "CREATE_SNACKBAR",
        "Deine Zugangsdaten sind leider falsch."
      );
    } else if (error == "Error: Request failed with status code 402") {
      //trigger error when license limits are reached
      context.dispatch("triggerUniversalAlert", {
        text: "Diese Aktion kann nicht durchgeführt werden, da Ihre Lizenzlimits erreicht sind. Um diese Beschränkung aufzuheben, kontaktieren Sie bitte den Support.",
        heading: "Lizenzlimits erreicht",
        type: "warning",
      });
    } else if (error == "Error: Request failed with status code 409") {
      //trigger error when user already exists
      context.dispatch("triggerUniversalAlert", {
        text: "Diese Aktion kann nicht durchgeführt werden, da bereits ein Nutzer mit dieser E-Mail existiert. Um diese Beschränkung aufzuheben, kontaktieren Sie bitte den Support.",
        heading: "Email bereits vergeben",
        type: "warning",
      });
    } else if (error == "Error: Request failed with status code 403") {
      //trigger error when user already exists
      router.push("/app/tickets/all").catch();
      context.dispatch("triggerUniversalAlert", {
        text: "Diese Aktion kann nicht durchgeführt werden, da Sie nicht über die benötigten Berechtigungen verfügen.",
        heading: "Aktion nicht möglich",
        type: "warning",
      });
    } else {
      //otherwise cath all other errors
      context.commit(
        "CREATE_ERROR",
        "Es ist ein Fehler aufgetreten, bitte versuche es später erneut."
      );
    }
  },
  triggerSnackbar(context, text) {
    context.commit("CREATE_SNACKBAR", text);
  },
  triggerErrorSnackbar(context, text) {
    context.commit("CREATE_ERROR", text);
  },
  triggerUniversalAlert(context, value) {
    context.commit("CREATE_UNIVERSAL_ALERT", value);
  },
  closeUniversalAlert(context) {
    context.commit("CLOSE_UNIVERSAL_ALERT");
  },
};

export default actions;
