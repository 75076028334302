<template>
  <div>
    <!-- Start Work -->
    <div class="d-flex flex-row">
      <v-menu
        v-model="startWorkMenu"
        :close-on-content-click="false"
        nudge-width="200"
        offset-y
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="value.startWork"
            label="Beginn der Arbeitszeit des Versicherten"
            prepend-icon="mdi-clock"
            readonly
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          ></v-text-field>
        </template>
        <div class="d-flex flex-column">
          <div>
            <v-time-picker
              v-model="value.startWork"
              locale="de"
              format="24hr"
            ></v-time-picker>
          </div>
          <div class="align-self-end mb-2 mr-2">
            <v-btn color="primary" @click="closeStartWorkPicker">Ok</v-btn>
          </div>
        </div>
      </v-menu>

      <!-- End Work -->
      <v-menu
        v-model="endWorkMenu"
        :close-on-content-click="false"
        nudge-width="200"
        offset-y
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="value.endWork"
            label="Ende der Arbeitszeit des Versicherten"
            prepend-icon="mdi-clock"
            readonly
            v-bind="attrs"
            v-on="on"
            class="ml-3"
          ></v-text-field>
        </template>
        <div class="d-flex flex-column">
          <div>
            <v-time-picker
              v-model="value.endWork"
              locale="de"
              format="24hr"
            ></v-time-picker>
          </div>
          <div class="align-self-end mb-2 mr-2">
            <v-btn color="primary" @click="closeEndWorkPicker">Ok</v-btn>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      startWorkMenu: false,
      endWorkMenu: false,
    };
  },
  methods: {
    closeStartWorkPicker() {
      this.startWorkMenu = false;
    },
    closeEndWorkPicker() {
      this.endWorkMenu = false;
    },
  },
};
</script>
