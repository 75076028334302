<template>
  <div v-if="item.safetyDataSheet?.name">
    <v-tooltip v-if="item.safetyDataSheet?.name" bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="downloadItem(item)"
          data-cy="copy"
        >
          picture_as_pdf
        </v-icon>
      </template>
      <span>{{
        $t("dangerousGoods.dangerousGoodsList.downloadDataSheet")
      }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: ["item"],
  methods: {
    createFormPath(item) {
      if (item) {
        return (
          "Sicherheitsdatenblaetter/" +
          item.safetyDataSheet.name +
          "_" +
          item.safetyDataSheet.location +
          "." +
          item.safetyDataSheet.filetype
        );
      }
    },
    async downloadItem(item) {
      //open url to download file
      window.open(await this.getDownloadItemLink(item), "_blank");
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
  },
};
</script>
