import axios from "axios";
const actions = {
  getBillwerkToken(context) {
    return axios
      .get("license/token")
      .then(function (r) {
        return r.data.token;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
