<template>
  <div>
    <div class="d-flex justify-center mt-4">
      <v-btn color="primary" outlined @click="dialog = true">{{
        $t("inspections.selectFromPreset")
      }}</v-btn>
    </div>
    <!-- dialog: -->
    <div>
      <v-dialog v-model="dialog" max-width="800" persistent>
        <v-card>
          <v-card-title class="headline">{{
            $t("inspections.selectFromPresetAndAdd")
          }}</v-card-title>
          <v-card-text>
            <v-card flat class="mx-auto">
              <v-list shaped>
                <v-list-item-group v-model="selectedItems" multiple>
                  <template v-for="(item, i) in presetItems.areas">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item"
                      active-class="primary--text text--accent-4"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="secondary accent-4"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
            <hr />
            <div class="d-flex justify-center mt-4">
              <v-btn
                color="primary"
                text
                @click="appendAreas"
                data-cy="addAreas"
                disabled
                >{{ $t("inspections.addAfter") }}</v-btn
              >
            </div>
            <div class="d-flex justify-center mt-4">- oder -</div>
            <div class="d-flex justify-center mt-4">
              <v-btn
                color="primary"
                text
                @click="appendAreas"
                data-cy="addAreas"
                :disabled="selectedItems.length > 1 && selectedItems.length < 1"
                >{{ $t("inspections.addAtTheEndOfTheList") }}</v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click="dialog = false">{{
              $t("general.back")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import * as inspectionsContent from "../../api/content/inspectionsContent-de.json";
export default {
  props: ["value"],
  components: {},
  data: () => ({
    dialog: false,
    presetItems: inspectionsContent.default,
    selectedItems: [],
  }),
  methods: {
    appendAreas() {
      //set selection
      this.selectedItems.forEach((element) => {
        this.value.areas.push(element);
      });
      //clean up and close dialog
      this.selectedItems = [];
      this.dialog = false;
    },
  },
};
</script>
