<template>
  <div>
    <v-row class="my-3">
      <v-icon class="mr-2" color="primary">question_answer</v-icon>
      <div class="subtitle-1">{{ $t("tickets.view.comments") }}</div>
    </v-row>
    <div class="ml-5">
      <div class="mb-1">
        <v-icon class="mr-2 mb-1" color="primary">person</v-icon>
        {{ username }}
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-textarea
          class="mb-3"
          data-cy="content"
          v-model="comment.content"
          dense
          hide-details
          outlined
          :rules="requiredRule"
          rows="1"
          auto-grow
        ></v-textarea>
        <v-row class="mb-3 mt-1">
          <div class="flex-grow-1"></div>
          <v-btn
            class="mr-3"
            color="primary"
            data-cy="send"
            @click="postMessage(item)"
          >
            senden
          </v-btn>
        </v-row>
      </v-form>
    </div>
    <div class="ml-5" v-bind:key="index" v-for="(comment, index) in comments">
      <div class="mb-1">
        <v-icon class="mr-2 mb-1" color="primary">person</v-icon>
        {{ comment.user.name }} -
        <i>{{ formatDate(comment.timestamp) + "h" }}</i>
      </div>
      <v-textarea
        class="mb-4"
        v-model="comment.content"
        dense
        hide-details
        outlined
        disabled
        auto-grow
        background-color="grey lighten-3"
        v-bind:rows="comment.content.split('\n').length"
      ></v-textarea>
    </div>
  </div>
</template>
<script>
import { dateUtil } from "../../../../components/api/api";

export default {
  props: ["item"],
  data() {
    return {
      valid: false,
      requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
      comment: {
        content: "",
        user: null,
        timestamp: null,
      },
      comments: [],
      username: "",
    };
  },
  methods: {
    loadMessanges() {
      //trigger use ingfo
      this.$store.dispatch("getUserInfo").then((r) => {
        //set username
        this.username = r.name;
      });
      //set comments
      this.$store.dispatch("getCommentsForTicket", this.item._id).then((r) => {
        this.comments = r;
      });
      //clear form
      this.clearComment();
    },
    postMessage(item) {
      //validate form
      if (this.$refs.form.validate()) {
        //set data
        let postData = this.comment;
        while (postData.content.endsWith("\n")) {
          postData.content = postData.content.substring(
            0,
            postData.content.length - 1
          );
        }
        postData.user = JSON.parse(localStorage.getItem("userData"))._id;
        postData.timestamp = new Date();
        postData.id = item._id;
        this.$store.dispatch("addComment", postData).then(() => {
          //trigger clear comment
          this.clearComment();
          //trigger data reload
          this.loadMessanges();
        });
      }
    },
    clearComment() {
      //reset validation
      this.$refs.form.resetValidation();
      this.comment = {
        content: "",
        user: null,
        timestamp: null,
      };
    },
    formatDate(date) {
      if (!date) return null;
      return dateUtil(new Date(date), true, "FEHLER");
    },
  },
};
</script>
