<template>
  <v-dialog v-model="data.open" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(data.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="inspectionForm"
            v-model="item"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{
          $t("general.back")
        }}</v-btn>
        <v-btn color="primary" @click="saveForm()" data-cy="saveEvent">
          {{ $t("inspections.closeInspection") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function getDefaultData() {
  return {
    name: "",
    filetype: "",
    content: "",
    nextExecution: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ),
  };
}
import formComponent from "../../_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../api/api";
export default {
  components: { formComponent },
  props: ["data", "item"],
  data: () => ({
    inspectionForm: {
      formData: getDefaultData(),
      form: [
        [
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "py-0 mt-0",
            id: getUniqueId(),
            componentName: "inspectionFinishForm",
            config: {},
          },
        ],
      ],
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    async saveForm() {
      const updatedInspection = await this.$store.dispatch("updateInspection", {
        ...this.item,
        status: "Closed",
      });
      this.$store.commit(
        "CREATE_SNACKBAR",
        "Begehung " + updatedInspection.title + " erfolgreich verändert"
      );
      this.closeform();
    },
    resetData() {
      this.data.open = false;
      //reset form component form only after contact create
      this.$refs.formComponent.resetForm();
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
