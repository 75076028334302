<template>
  <div>
    <v-radio-group v-model="value.descriptionProvidedBy">
      <template v-slot:label>
        {{ $t("incidentReports.incidentReportFormLong.descriptionProvidedBy.label") }}
      </template>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.descriptionProvidedBy.insured')"
        value="insured"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.descriptionProvidedBy.other')"
        value="other"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {},
};
</script>
