import { DEFAULT_ITEMS_PER_PAGE, getDefaultRowsPerPageItems } from ".";
import StoreUtil from "../../../../store/store.util";

const mutations = {
  SET_TABLE_DATA(state, payload) {
    state.dataAll.allList = StoreUtil.updateState(
      state.dataAll.allList,
      payload
    );
  },
  SET_TOTAL_ITEMS(state, payload) {
    state.dataAll.pagination.totalItems = payload;
  },
  SET_PAGINATION_TABLE(state, payload) {
    state.dataAll.pagination = payload;
  },
  RESET_PAGINATION_PAGES_ALL(state) {
    state.dataAll.pagination.page = 1;
    state.dataAll.pagination.itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
  },
  SWITCH_TABLE_LANGUAGE(state) {
    state.dataAll.pagination.rowsPerPageItems = getDefaultRowsPerPageItems();
  },
};

export default mutations;
