import axios from "axios";

const actions = {
  createRiskAssessment(context, value) {
    return axios
      .post("riskAssessment", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Gefährdungsbeurteilung " + r.data.title + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteRiskAssessment(context, val) {
    return axios
      .delete("riskAssessment/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Gefährdungsbeurteilung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateRiskAssessment(context, value) {
    return axios
      .put("riskAssessment/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Gefährdungsbeurteilung " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleRiskAssessment(context, id) {
    return axios
      .get("riskAssessment/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getPdfRiskAssessment(context, id) {
    return axios
      .get("riskAssessment/" + id + "/pdf")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
