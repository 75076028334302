<template>
  <div>
    <v-text-field
      dense
      v-model="value.unableToWorkDays"
      :label="$t('incidentReports.absenceInDays')"
      data-cy="unableToWorkDays"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {},
};
</script>
