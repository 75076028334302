import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import { version } from "../../../package.json";

const state = {
  version: version,
  backendServer: "https://api.safetyplanner.de/api/v1/",
  error: {
    visible: false,
    message: null,
  },
  snackbar: {
    visible: false,
    message: null,
  },
  universalAlert: {
    visible: false,
    text: null,
    heading: null,
    type: null,
  },
};

const configModule = {
  state,
  mutations,
  actions,
  getters,
};

export default configModule;
