<template>
  <div>
    <v-autocomplete
      v-model="value.h"
      :items="hSentences"
      hide-no-data
      item-text="name"
      item-value="value"
      :label="$t('dangerousGoods.dangerousGoodsList.form.hSentences')"
      data-cy="hSentences"
      dense
      chips
      multiple
    >
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  components: {},
  data: () => ({ standardOpenManagedInstruction: { value: "" } }),
  computed: {
    ...mapGetters(["hSentences"]),
  },
  methods: {},
};
</script>
