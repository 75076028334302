import axios from "axios";

const actions = {
  getS3StructureUrl(context) {
    return axios
      .get("document/url")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  
  getPresignedDownloadUrl(context, path) {
    return axios
      .get("document/downloadurl?path=" + path)
      .then(function (response) {
        return response.data.url;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
