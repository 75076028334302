<template>
    <div>
        <v-switch :label="$t('incidentReports.roadIncident')" data-cy="commutingIncident" v-model="value.commutingIncident"></v-switch>
    </div>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({}),
    methods: {},
};
</script>
