import axios from "axios";

const actions = {
  getAllEmployeeGroups(context) {
    context.commit("SET_ALL_GROUPS");
    return axios
      .get("employeeGroup")
      .then(function (r) {
        context.commit("SET_ALL_GROUPS", r.data.data);
        return r.data.data;
      })
      .catch(function (e) {
        context.commit("SET_ALL_GROUPS", e);
        context.dispatch("triggerError", e);
      });
  },

  createEmployeeGroup(context, value) {
    return axios
      .post("employeeGroup", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Gruppe " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteEmployeeGroup(context, val) {
    return axios
      .delete("employeeGroup/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Gruppe erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateEmployeeGroup(context, value) {
    return axios
      .put("employeeGroup/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Gruppe " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleEmployeeGroup(context, id) {
    return axios
      .get("employeeGroup/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
