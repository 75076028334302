<template>
  <div>
    <singleDateForm
      v-model="value.inspectionDate"
      :label="$t('inspections.tableHeader.inspectionDate')"
      data-cy="inspectionDate"
      prependIcon="event"
      :rules="requieredRule"
    />
  </div>
</template>
<script>
import singleDateForm from "../../_utils/general/singleDateForm.vue";
export default {
  props: ["value"],
  components: { singleDateForm },
  data() {
    const $t = this.$t.bind(this);
    return {
      requieredRule: [(v) => !!v || $t("general.fieldNeedsToBeFilled")],
    };
  },
  methods: {},
};
</script>
