<template>
  <div>
    <v-row align="center" dense justify="end" justify-sm="start" class="my-1">
      <v-col
        v-for="{ examination, interval } in shortExaminations"
        v-bind:key="examination._id"
        class="flex-grow-0"
      >
        <v-chip small>
          {{ examination.name }} (<v-icon small>mdi-rotate-left</v-icon
          >{{ interval }})
        </v-chip>
      </v-col>
      <v-col v-if="item?.examinationAssignments?.length > 3">
        <v-btn
          small
          depressed
          rounded
          @click="
            $refs.employeeGroupTableExaminationAssignmentsDialog.openDialog()
          "
        >
          {{
            $tc(
              "employeeGroup.examinationsDialog.assignedExaminations.andMore",
              item.examinationAssignments.length - 3,
              { count: item.examinationAssignments.length - 3 }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <employee-group-table-examination-assignments-dialog
      ref="employeeGroupTableExaminationAssignmentsDialog"
      :examination-assignments="item?.examinationAssignments"
    />
  </div>
</template>
<script>
import EmployeeGroupTableExaminationAssignmentsDialog from "@/views/employee/employeeGroupTableExaminationAssignmentsDialog.vue";

export default {
  components: {
    EmployeeGroupTableExaminationAssignmentsDialog,
  },
  props: ["item"],
  computed: {
    shortExaminations() {
      return this.item?.examinationAssignments?.length
        ? this.item.examinationAssignments.slice(0, 3)
        : [];
    },
  },
  methods: {},
};
</script>
