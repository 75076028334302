<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="2"></v-col>
      <v-col cols="12" sm="12" md="8">
        <v-card class="mx-auto my-5 mb-10" max-width="1000">
          <v-app-bar flat>
            <span class="headline">{{ formCompData.title }}</span>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <formComponent
                ref="formComponent"
                :form="formComp"
                v-model="formComp.eventForm"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey" outlined @click="closeform">{{
              $t("general.back")
            }}</v-btn>
            <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
              <div
                v-if="
                  $route.params.mode === 'new' || $route.params.mode === 'copy'
                "
              >
                {{ $t("general.save") }}
              </div>
              <div v-if="$route.params.mode === 'edit'">
                {{ $t("general.saveChanges") }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="2"></v-col>
    </v-row>
  </div>
</template>

<script>
function getDefaultData() {
  return {
    title: "",
    responsible: "",
    location: "",
    risks: [],
  };
}

import formComponent from "../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../components/api/api";

export default {
  components: { formComponent },
  props: ["data"],
  data() {
    return {
      formCompData: {
        title: "riskAssessment.addRiskAssessment",
        item: null,
      },
      formComp: {
        form: [],
        eventForm: getDefaultData(),
      },
    };
  },
  created() {
    let errorFieldNeedsToBeFilled = this.$t("general.fieldNeedsToBeFilled");
    this.formComp.form = [
      [
        {
          //v-text-field
          cols: 12,
          sm: 8,
          md: 8,
          class: "pb-0 pt-4",
          id: getUniqueId(),
          componentName: "vTextField",
          config: {
            vModel: "title",
            label: this.$t("riskAssessment.title"),
            dataCy: "title",
            rules: [(v) => !!v || errorFieldNeedsToBeFilled],
          },
        },
      ],
      [
        {
          //v-text-field
          cols: 12,
          sm: 6,
          md: 6,
          class: "pb-0",
          id: getUniqueId(),
          componentName: "vTextField",
          config: {
            vModel: "location",
            label: this.$t("general.location"),
            dataCy: "location",
            rules: [(v) => !!v || errorFieldNeedsToBeFilled],
          },
        },
        {
          //v-text-field
          cols: 12,
          sm: 6,
          md: 6,
          class: "pb-0",
          id: getUniqueId(),
          componentName: "vSingleUserSelect",
          config: {},
        },
      ],
      [
        {
          cols: 12,
          sm: 12,
          md: 12,
          class: "pb-0",
          id: getUniqueId(),
          componentName: "riskAssessmentAreasPreset",
          config: {},
        },
      ],
      [
        {
          cols: 12,
          sm: 12,
          md: 12,
          class: "pb-0",
          id: getUniqueId(),
          componentName: "riskAssessmentRisks",
          config: {
            rules: [(v) => !!v || errorFieldNeedsToBeFilled],
          },
        },
      ],
    ];
  },
  mounted() {
    this.switchFormModes(this.$route.params.mode, this.$route.params);
  },
  methods: {
    switchFormModes(mode, params) {
      switch (mode) {
        case "edit":
          this.$store
            .dispatch("getSingleRiskAssessment", params.id)
            .then((item) => {
              this.formCompData.title = this.$t(
                "riskAssessment.editRiskAssessment"
              );
              this.formComp.eventForm = item;
            });
          break;
        case "copy": {
          this.$store
            .dispatch("getSingleRiskAssessment", params.id)
            .then((item) => {
              this.formCompData.title = this.$t(
                "riskAssessment.copyRiskAssessment"
              );
              this.formComp.eventForm = item;
              //delete individual data
              delete this.formComp.eventForm._id;
              delete this.formComp.eventForm.id;
              delete this.formComp.eventForm.createdAt;
              delete this.formComp.eventForm.createdBy;
              delete this.formComp.eventForm.updatedAt;
            });
          break;
        }
        case "new":
          this.formCompData.title = this.$t("riskAssessment.addRiskAssessment");
          break;
      }
    },
    closeform() {
      this.resetData("new");
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      console.log(
        this.$refs.formComponent.validateAllFields(),
        !this.formComp.eventForm.risks.some(
          (risk) => !risk.action || !risk.measurement
        )
      );
      if (
        this.$refs.formComponent.validateAllFields() &&
        !this.formComp.eventForm.risks.some(
          (risk) => !risk.action || !risk.measurement
        )
      ) {
        if (
          this.$route.params.mode === "new" ||
          this.$route.params.mode === "copy"
        ) {
          //create new item
          this.$store
            .dispatch("createRiskAssessment", this.formComp.eventForm)
            .then((val) => {
              this.afterUpdateCheck(val, createNew, "new");
            });
        } else {
          //edit item
          this.$store
            .dispatch("updateRiskAssessment", this.formComp.eventForm)
            .then((val) => {
              this.afterUpdateCheck(val, createNew, "edit");
            });
        }
      }
    },
    afterUpdateCheck(val, createNew, mode) {
      if (createNew) {
        //reset contact data to create new one
        this.resetData(mode);
      } else {
        //close form
        this.closeform();
      }
    },
    openDialog(val) {
      this.occupiedDialog.open = true;
      this.occupiedDialog.events = val;
    },
    resetData(mode) {
      //reset whole form data
      this.formComp.eventForm = getDefaultData();
      //reset form component form only after contact create
      if (mode === "new") {
        this.$refs.formComponent.resetForm();
      }
      //trigger reload data function in parent component
      this.reloadData();
      this.$router.back();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
