<template>
  <div>
    <heading>{{ $t("sidebar.settings") }}</heading>
    <v-tabs v-model="$route.path">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>

      <v-tab-item
        v-for="tab of tabs"
        :key="tab.id"
        :value="tab.route"
        :transition="false"
        :reverse-transition="false"
      >
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Profil",
          route: "/app/settings/profile",
        },
        {
          id: 2,
          name: this.$t("settings.user"),
          route: "/app/settings/users",
        },
        {
          id: 3,
          name: this.$t("settings.boards"),
          route: "/app/settings/boards",
        },
        {
          id: 4,
          name: this.$t("settings.email"),
          route: "/app/settings/email",
        },
        {
          id: 5,
          name: this.$t("settings.multiuser"),
          route: "/app/settings/multiuser",
        },
        {
          id: 6,
          name: this.$t("settings.tariff"),
          route: "/app/settings/tariff",
        },
      ],
    };
  },
};
</script>
