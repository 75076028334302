<template>
  <div>
    <v-row
      align="center"
      v-for="(assignment, index) in value.instructionAssignments"
      :key="index"
    >
      <v-col cols="10" cols-md="11">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="8">
                <v-select
                  v-model="assignment.instruction"
                  :items="managedInstructionOrderDataMap"
                  :loading="allManagedInstructionsPending"
                  :label="$t('instructions.instruction')"
                  :rules="config.rules"
                  dense
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="assignment.interval"
                  :items="months"
                  :rules="config.rules"
                  :label="$t('employeeGroup.selectInterval')"
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" cols-md="1">
        <v-btn
          icon
          color="error"
          @click="removeInstructionAssignment(index)"
          data-cy="remove"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-btn text dense @click="addInstructionAssignment" class="mt-4">
      {{ $t("employeeGroup.addInstructionAssignment") }}
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value", "config"],
  components: {},
  data: () => ({
    instructionAssigmentsCount: 2,
    standardOpenManagedInstruction: { value: "" },
  }),
  computed: {
    ...mapGetters(["allManagedInstructions", "allManagedInstructionsPending"]),
    managedInstructionOrderDataMap() {
      return this.allManagedInstructions.map((managedInstruction) => {
        const Description = managedInstruction.name[0].name;
        const id = managedInstruction._id;
        return Object.assign({}, managedInstruction, {
          text: Description,
          value: id,
        });
      });
    },
    months() {
      return [
        { value: 1, text: this.$tc("employeeGroup.month", 1, { count: 1 }) },
        { value: 2, text: this.$tc("employeeGroup.month", 2, { count: 2 }) },
        { value: 3, text: this.$tc("employeeGroup.month", 3, { count: 3 }) },
        { value: 4, text: this.$tc("employeeGroup.month", 4, { count: 4 }) },
        { value: 5, text: this.$tc("employeeGroup.month", 5, { count: 5 }) },
        { value: 6, text: this.$tc("employeeGroup.month", 6, { count: 6 }) },
        { value: 7, text: this.$tc("employeeGroup.month", 7, { count: 7 }) },
        { value: 8, text: this.$tc("employeeGroup.month", 8, { count: 8 }) },
        { value: 9, text: this.$tc("employeeGroup.month", 9, { count: 9 }) },
        { value: 10, text: this.$tc("employeeGroup.month", 10, { count: 10 }) },
        { value: 11, text: this.$tc("employeeGroup.month", 11, { count: 11 }) },
        { value: 12, text: this.$tc("employeeGroup.month", 12, { count: 12 }) },
        { value: 18, text: this.$tc("employeeGroup.month", 18, { count: 18 }) },
        { value: 24, text: this.$tc("employeeGroup.month", 24, { count: 24 }) },
      ];
    },
  },
  created() {
    //get getAllManagedInstructions
    this.$store.dispatch("getAllManagedInstructions");
  },
  methods: {
    addInstructionAssignment() {
      this.value.instructionAssignments.push({
        instruction: null,
        interval: 11,
      });
    },
    removeInstructionAssignment(index) {
      this.value.instructionAssignments.splice(index, 1);
    },
  },
};
</script>
