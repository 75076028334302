<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.instructions") }}
    </heading>
    <instructionTable />
  </div>
</template>

<script>
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
import instructionTable from "./instructionTable.vue";

export default {
  components: { instructionTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.instructions;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.instructions;
      }
      return sidebarTextDE.default.instructions;
    },
  },
  data: () => ({
    drawer: false,
  }),
};
</script>
