<template>
  <!--translate-->
  <div class="text-center">
    <v-dialog v-bind:value="value" v-on:input="(e) => $emit('input', e)" width="500">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{ $t("supportDialog.title") }}
        </v-card-title>

        <v-card-text class="pt-5 d-flex flex-row justify-space-between">
          <v-card outlined class="flex-grow-1 mr-2">
            <v-card-text>
              <p class="mb-0 pb-0">E-Mail:</p>
              <a href="mailto:jonas@safetyplanner.de">
                jonas@safetyplanner.de
              </a>
            </v-card-text>
          </v-card>
          <v-card outlined class="flex-grow-1 ml-2">
            <v-card-text>
              <p class="mb-0 pb-0">Mobil:</p>
              <a href="tel:+4917670724828">
                +49 176 70724828
              </a>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', false)">
            {{ $t("supportDialog.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "supportDialog",
  props: ["value"],
};
</script>
