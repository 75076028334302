<template>
  <div>
    <tableComponent
      class="pa-5 pl-0"
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      @newDocument="newDocument"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";

export default {
  data: () => ({
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupMembers",
          componentName: "employeeGroupMembers",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupAssignedInstructions",
          componentName: "employeeGroupAssignedInstructions",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupAssignedExaminations",
          componentName: "employeeGroupAssignedExaminations",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupFormattedCreatedAt",
          componentName: "employeeGroupFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupAction",
          componentName: "employeeGroupAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "employeeGroup",
      uriPartAppend: "",
      populate: ["members", "examinationAssignments.examination", "instructionAssignments.instruction"],
      sort: "-createdAt",
      editFormPushName: "employeeGroupForm",
    },
  }),
  computed: {
    tableSettings() {
      return {
        title: "",
        showSelect: false,
        showHeaderToolbar: true,
        headerButtons: [
          {
            title: "employeeGroup.addEmployeeGroup",
            color: "secondary",
            action: "newDocument",
            icon: "add",
            iconDark: true,
            dark: true,
            outlined: true,
          },
        ],
        multipleSelectButtons: [],
      };
    },
    headers() {
      return [
        {
          text: this.$t("employeeGroup.tableHeader.name"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("employeeGroup.tableHeader.employee"),
          value: "employeeGroupMembers",
        },
        {
          text: this.$t("employeeGroup.tableHeader.assignedInstructions"),
          value: "employeeGroupAssignedInstructions",
        },
        {
          text: this.$t("employeeGroup.tableHeader.assignedExaminations"),
          value: "employeeGroupAssignedExaminations",
        },
        {
          text: this.$t("employeeGroup.tableHeader.createdAt"),
          value: "employeeGroupFormattedCreatedAt",
        },
        {
          text: "",
          value: "employeeGroupAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store
        .dispatch("deleteMultipleOperatingemployeeGroup", item)
        .then(() => {
          this.reloadDataFunc();
          this.multipleDeleteDialog.open = false;
          this.multipleDeleteDialog.items = [];
        });
    },
  },
};
</script>
