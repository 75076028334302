<template>
  <v-dialog v-model="data.open" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(data.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="instructionForm"
            v-model="item"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{ $t("general.back") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formComponent from "../../_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../api/api";
export default {
  components: { formComponent },
  props: ["data", "item"],
  data: () => ({
    instructionForm: {
      form: [
        [
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "py-0 mt-0",
            id: getUniqueId(),
            componentName: "instructionProofList",
            config: {},
          },
        ],
      ],
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    resetData() {
      //reset form component form only after contact create
      this.$refs.formComponent.resetForm();
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
