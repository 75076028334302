<template>
  <div>
    <v-data-table
      :headers="headers"
      :options.sync="tableDataGetter.pagination"
      :server-items-length="tableDataGetter.pagination.totalItems"
      :loading="tableDataStatusPending"
      :items="tableDataGetter.allList.data"
      :footer-props="{
        itemsPerPageOptions: tableDataGetter.pagination.rowsPerPageItems,
        itemsPerPageText: $t('general.rowsPerPage'),
      }"
      :item-key="tableSettings.itemKey"
      @click:row="$emit('clickedRow', $event)"
      @update:options="getData()"
      v-model="selectedItems"
      :show-select="tableSettings.showSelect"
      :class="{ 'pointer-cursor': hasClickedRowListener }"
    >
      <template v-slot:top v-if="tableSettings.showHeaderToolbar">
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="tableSettings.title != ''">{{
            tableSettings.title
          }}</v-toolbar-title>
          <v-divider
            v-if="tableSettings.title != ''"
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            style="max-width: 370px"
            v-model="tableDataGetter.pagination.search"
            :label="$t('general.search')"
            id="search-all"
            single-line
            hide-details
            dense
            clearable
            @click:clear="handleClickClear()"
            @keyup.enter.native="getData()"
          ></v-text-field>
          <v-btn text icon color="secondary" @click="getData()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <div class="flex-grow-1"></div>
          <div
            v-for="item in tableSettings.headerButtons"
            v-bind:key="item.title"
          >
            <v-btn
              :text="item.text"
              :color="item.color"
              :dark="item.dark"
              :outlined="item.outlined"
              :disabled="item.disabled"
              class="mb-2 mr-4"
              @click="$emit(item.action, $event)"
              >{{ $t(item.title) }}
              <v-icon right :dark="item.iconDark" v-if="item.icon"
                >{{ item.icon }}
              </v-icon></v-btn
            >
          </div>
        </v-toolbar>
        <!-- render only select buttons when at least one element is selected-->
        <v-toolbar flat dense color="white" v-if="selectedItems.length > 0">
          <div
            v-for="item in tableSettings.multipleSelectButtons"
            v-bind:key="item.title"
          >
            <v-btn
              :color="item.color"
              :dark="item.dark"
              :outlined="item.outlined"
              class="mr-4"
              small
              @click="$emit(item.action, selectedItems)"
              >{{ item.title }}</v-btn
            >
          </div>
          <div class="flex-grow-1"></div>
        </v-toolbar>
      </template>
      <!-- render slot props and components from prop data -->
      <template
        v-for="slot in tableData.tableComponentSlots"
        v-slot:[slot.slotName]="{ item }"
      >
        <component
          :is="slot.componentName"
          :key="slot.id"
          :item="item"
          :config="slot.config"
          @reloadData="getData()"
        />
      </template>
      <template v-slot:no-data> {{ $t("general.noData") }} </template>
    </v-data-table>
  </div>
</template>
<script>
import * as components from "../../../../src/components/tableSlotComponents/index.js";
import { mapGetters } from "vuex";
export default {
  components: {
    ...components,
  },
  props: ["tableSettings", "tableData", "headers"],
  data: () => ({
    selectedItems: [],
  }),
  computed: {
    hasClickedRowListener() {
      return this.$listeners && this.$listeners.clickedRow;
    },
    pagination: {
      get: function () {
        return this.$store.getters.tableDataGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_TABLE", value);
        //this.getData();
      },
    },
    ...mapGetters(["tableDataGetter", "tableDataStatusPending"]),
  },
  watch: {
    "tableDataGetter.allList.data": function (newData) {
      this.$emit("dataChanged", newData);
    },
  },
  mounted() {},
  methods: {
    clearSelectItems() {
      this.selectedItems = [];
    },
    getData() {
      this.$store.dispatch("getTableData", this.tableData);
    },
    handleClickClear() {
      this.tableDataGetter.pagination.search = "";
    },
  },
};
</script>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background: white !important;
}
.pointer-cursor tr {
  cursor: pointer;
}
</style>
<!--
Data models:
-->
