<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="2"> </v-col>
      <v-col cols="12" sm="12" md="8">
        <v-card class="mx-auto my-5 mb-10" max-width="1000">
          <v-app-bar flat>
            <span class="headline">{{ $t(formCompData.title) }}</span>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <formComponent
                ref="formComponent"
                :form="formComp"
                v-model="formComp.eventForm"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey" outlined @click="closeform">{{
              $t("general.back")
            }}</v-btn>
            <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
              <div v-if="$route.params.mode === 'new'">
                {{ $t("general.save") }}
              </div>
              <div v-if="$route.params.mode === 'edit'">
                {{ $t("general.saveChanges") }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="2"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

function getDefaultData() {
  return {
    title: "",
    responsible: "",
    location: "",
    risks: [],
  };
}
import formComponent from "../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../components/api/api";
export default {
  components: { formComponent },
  props: ["data"],
  computed: {
    ...mapGetters(["allEmployees", "allEmployeesPending"]),
    allOtherEmployeeEmails() {
      return this.allEmployees
        .filter((employee) => employee._id !== this.formComp.eventForm._id)
        .map((employee) => employee.email);
    },
    formComp() {
      return {
        eventForm: getDefaultData(),
        form: [
          [
            {
              //v-text-field
              cols: 12,
              sm: 6,
              md: 6,
              class: "pb-0 pt-4",
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "name",
                label: "Name",
                dataCy: "name",
                rules: [(v) => !!v || "Dieses Feld wird benötigt"],
              },
            },
            {
              //v-text-field
              cols: 12,
              sm: 6,
              md: 6,
              class: "pb-0 pt-4",
              id: getUniqueId(),
              componentName: "vTextField",
              config: {
                vModel: "email",
                label: "Email",
                dataCy: "email",
                rules: [
                  (v) => !!v || "Dieses Feld wird benötigt",
                  (v) => /.+@.+\..+/.test(v) || "E-mail ungültig",
                ],
              },
            },
          ],
          [
            {
              //v-select
              cols: 12,
              sm: 12,
              md: 12,
              class: "pb-0 mt-5",
              id: getUniqueId(),
              componentName: "vSelect",
              config: {
                vModel: "employeeLanguage",
                items: [
                  { name: "Deutsch", value: "GER" },
                  { name: "Englisch", value: "ENG" },
                ],
                label: "Sprache",
                dataCy: "employeeLanguage",
                rules: [(v) => !!v || "Dieses Feld wird benötigt"],
              },
            },
          ],
        ],
      };
    },
  },
  data: () => ({
    formCompData: {
      title: "employee.addEmployee",
      item: null,
    },
  }),
  mounted() {
    this.switchFormModes(this.$route.params.mode, this.$route.params);
    this.$store.dispatch("getAllEmployees");
  },
  methods: {
    switchFormModes(mode, params) {
      switch (mode) {
        case "edit":
          this.$store.dispatch("getSingleEmployee", params.id).then((item) => {
            this.formCompData.title = "Mitarbeiter bearbeiten";
            this.formComp.eventForm = item;
          });
          break;
        case "new":
          this.formCompData.title = "Mitarbeiter anlegen";
          break;
      }
    },
    closeform() {
      this.resetData("new");
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      if (this.allOtherEmployeeEmails.includes(this.formComp.eventForm.email)) {
        this.$store.dispatch(
          "triggerErrorSnackbar",
          "Ein Mitarbeiter mit dieser E-Mail Adresse existiert bereits"
        );
        return;
      }
      if (this.$refs.formComponent.validateAllFields()) {
        if (this.$route.params.mode === "new") {
          //create new item
          this.$store
            .dispatch("createEmployee", this.formComp.eventForm)
            .then((val) => {
              this.afterUpdateCheck(val, createNew, "new");
            });
        } else {
          //edit item
          this.$store
            .dispatch("updateEmployee", this.formComp.eventForm)
            .then((val) => {
              this.afterUpdateCheck(val, createNew, "edit");
            });
        }
      }
    },
    afterUpdateCheck(val, createNew, mode) {
      if (createNew) {
        //reset contact data to create new one
        this.resetData(mode);
      } else {
        //close form
        this.closeform();
      }
    },
    openDialog(val) {
      this.occupiedDialog.open = true;
      this.occupiedDialog.events = val;
    },
    resetData(mode) {
      //reset whole form data
      this.formComp.eventForm = getDefaultData();
      //reset form component form only after contact create
      if (mode === "new") {
        this.$refs.formComponent.resetForm();
      }
      //trigger reload data function in parent component
      this.reloadData();
      this.$router.back();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
