<template>
  <v-dialog v-model="data.open" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ data.title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="customerDataForm"
            v-model="customerDataForm.formData"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{
          $t("kontakte.form.buttons.abort")
        }}</v-btn>
        <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
          <div v-if="data.method === 'new'">
            {{ $t("kontakte.form.buttons.create") }}
          </div>
          <div v-if="data.method === 'edit'">
            {{ $t("kontakte.form.buttons.edit") }}
          </div></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function getDefaultData() {
  return {
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    Tag: "",
    CompanyName: "",
    VatId: "",
    PhoneNumber: "",
    Address: {
      AddressLine1: "",
      Street: "",
      HouseNumber: "",
      PostalCode: "",
      City: "",
      Country: "DE",
    },
  };
}
import formComponent from "../../../../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../../../../api.js";
export default {
  name: "customerDataForm",
  components: { formComponent },
  props: ["data"],
  data: () => ({
    activeTab: 0,
    customerDataForm: {
      formData: getDefaultData(),
      form: [
        //v-row
        [
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 6,
            class: "pb-0 mt-6",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "FirstName",
              label: "Vorname",
              dataCy: "FirstName",
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 6,
            class: "pb-0 mt-6",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "LastName",
              label: "Nachname",
              dataCy: "LastName",
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
        ],
        [
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 6,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "CompanyName",
              label: "Firma",
              dataCy: "CompanyName",
              rules: [],
            },
          },
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 6,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "EmailAddress",
              label: "Rechnungs E-Mail",
              dataCy: "EmailAddress",
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
        ],
        [
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 8,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "streetForm",
            config: {},
          },
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 4,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "houseNumberForm",
            config: {},
          },
        ],
        [
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 4,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "postalCodeForm",
            config: {},
          },
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 8,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "cityForm",
          },
        ],
        [
          //v-cols
          {
             
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "countryForm",
            config: {},
          },
        ],
        [
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 6,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "PhoneNumber",
              label: "Telefonnummer",
              dataCy: "PhoneNumber",
              rules: [],
            },
          },
          {
            //v-text-field
            cols: 12,
            sm: 6,
            md: 6,
            class: "pb-0",
            id: getUniqueId(),
            componentName: "vTextField",
            config: {
              vModel: "VatId",
              label: "USt-IdNr.",
              dataCy: "VatId",
              rules: [],
            },
          },
        ],
      ],
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      if (this.$refs.formComponent.validateAllFields()) {
        if (this.data.method === "edit") {
          //create new item
          this.$store
            .dispatch("updateCustomerData", this.customerDataForm.formData)
            .then(() => {
              if (createNew) {
                //reset contact data to create new one
                this.resetData();
              } else {
                //close form
                this.closeform();
              }
            });
        }
      }
    },
    resetData() {
      //reset whole form data
      this.customerDataForm.formData = getDefaultData();
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.formComponent.resetForm();
      }
    },
    triggerEdit(item) {
      //set edit item from contactOverview Page trigger
      this.customerDataForm.formData = item;
    },
  },
};
</script>
