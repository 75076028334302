import StoreUtil from "../store.util.js";
const mutations = {
    SET_ALL_BOARDS(state, payload) {
    state.boardList = StoreUtil.updateState(
      state.boardList,
      payload
    );
  },
};

export default mutations;
