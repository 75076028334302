<template>
  <div>
    <tableComponent
      class="pa-5 pl-0"
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../../components/api/api";

export default {
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupAssignedInstructions",
          componentName: "employeeGroupAssignedInstructions",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupActionInstruction",
          componentName: "employeeGroupActionInstruction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "employeeGroup",
      uriPartAppend: "",
      populate: ["members", "instructionAssignments.instruction"],
      sort: "-createdAt",
      editFormPushName: "managedInstructionEmployeeGroupForm",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("employeeGroup.tableHeader.name"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("managedInstructions.assignedInstructionsTable.tableHeader.assignedInstructions"),
          value: "employeeGroupAssignedInstructions",
        },
        {
          text: "",
          value: "employeeGroupActionInstruction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
};
</script>
