<template>
  <div>
    <div v-if="!isConfirm">
      <v-tooltip left>
        <template v-slot:activator="{ attr, on }">
          <v-btn
            v-on="on"
            v-bind="attr"
            text
            icon
            color="primary"
            v-bind:disabled="isSent"
            v-on:click="checkConfirmation()"
          >
            <v-icon v-if="isSent">mdi-send-check</v-icon>
            <v-icon v-else>mdi-send-clock</v-icon>
          </v-btn>
        </template>
        {{
          $t(
            "managedInstructionAssignment.overdueSendReminder.sendEmailNotification"
          )
        }}
      </v-tooltip>
      <v-btn text icon color="error" style="visibility: hidden">
        <v-icon>mdi-send-clock</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-tooltip left>
        <template v-slot:activator="{ attr, on }">
          <v-btn
            v-on="on"
            v-bind="attr"
            text
            icon
            color="error"
            v-on:click="cancelReminder"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        {{ $t("general.cancel") }}
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ attr, on }">
          <v-btn
            v-on="on"
            v-bind="attr"
            text
            icon
            color="success"
            v-on:click="sendReminder()"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        {{ $t("managedInstructionAssignment.overdueSendReminder.confirm") }}
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["employee", "instructionId"],
  data: () => ({
    isConfirm: false,
    revokeConfirmSchedulerTaskId: undefined,
    isSent: false,
  }),
  methods: {
    checkConfirmation() {
      this.isConfirm = true;
      this.revokeConfirmSchedulerTaskId = setInterval(
        () => (this.isConfirm = false),
        15 * 1000
      );
    },
    cancelReminder() {
      this.isConfirm = false;
      clearTimeout(this.revokeConfirmSchedulerTaskId);
    },
    sendReminder() {
      clearTimeout(this.revokeConfirmSchedulerTaskId);

      if (!this.isConfirm) {
        return;
      }

      axios
        .post(
          `/managedInstructionAssignment/${this.instructionId}/${this.employee._id}/reminder`
        )
        .then(() => {
          this.$store.dispatch(
            "triggerSnackbar",
            `Die Erinnerung wurde an ${this.employee.name} verschickt.`
          );
          this.isSent = true;
        })
        .catch(() =>
          this.$store.dispatch(
            "triggerSnackbar",
            "Die Erinnerung konnte nicht verschickt werden."
          )
        )
        .finally(() => (this.isConfirm = false));
    },
  },
};
</script>
