<template>
  <div>
    <v-row class="ma-2 mb-10">
      <v-col md="6" sm="12">
        <customerData />
      </v-col>

      <v-col md="6" sm="12">
        <contractData />
      </v-col>
      <v-col md="6" sm="12">
        <invoices />
      </v-col>
      <v-col cols="12"> </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import customerData from "./tariffCards/customerData.vue";
import invoices from "./tariffCards/invoices.vue";
import contractData from "./tariffCards/contractData.vue";
export default {
  components: { customerData, invoices, contractData },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["portalData"]),
  },
  mounted() {
    this.$store.dispatch("getBillwerkToken");
    this.$store.dispatch("getPortalData");
    //this.$store.dispatch("payCart");
  },
};
</script>
