<template>
  <div>
    <v-text-field
      v-model="value.name"
      disabled
      dense
      :label="$t('managedInstructions.assignInstructionsForm.groupName')"
    >
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: ["value"],
};
</script>
