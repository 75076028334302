<template>
  <div>
    <v-list subheader v-if="value.proofs.length > 0">
      <v-list-item-group>
        <v-list-item v-for="(item, i) in value.proofs" :key="i" class="pl-0">
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-file-image</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="py-2">
            <v-list-item-title
              >{{ $t("instructions.proofFrom") }}
              {{ formatDate(item.uploadedAt) }}</v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-row>
              <v-btn icon @click.stop="downloadItem(item)">
                <v-icon color="grey darken-1">mdi-download</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else class="text-center">
      <b>{{ $t("instructions.proofText") }}</b>
    </div>
  </div>
</template>
<script>
export default {
  props: ["value"],
  components: {},
  data: () => ({}),
  methods: {
    createFormPath(item) {
      if (item) {
        return (
          "Unterweisungensnachweise/Nachweis_vom_" +
          this.formatDate(item.uploadedAt) +
          "_" +
          item.location +
          ".pdf"
        );
      }
    },
    async downloadItem(item) {
      //open url to download file
      window.open(await this.getDownloadItemLink(item), "_blank");
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
    formatDate(date) {
      const today = new Date(date);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return dd + "." + mm + "." + yyyy;
    },
  },
};
</script>
