<template>
  <div>
    <v-radio-group v-model="value.roleInCompany">
      <template v-slot:label>
        {{ $t("incidentReports.incidentReportFormLong.roleInCompany.label") }}
      </template>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.roleInCompany.entrepreneur')"
        value="entrepreneur"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.roleInCompany.relatedToEntrepreneur')"
        value="relatedToEntrepreneur"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.roleInCompany.spouseOfEntrepreneur')"
        value="spouseOfEntrepreneur"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.roleInCompany.shareholderOrCEO')"
        value="shareholderOrCEO"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.roleInCompany.nothing')"
        value="noSpecialRole"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {},
};
</script>
