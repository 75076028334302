<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openPrintPdf(item)"
          data-cy="open"
          >picture_as_pdf</v-icon
        >
      </template>
      <span>{{ $t("riskAssessment.openRiskAssessment") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="copyItem(item)"
          data-cy="copy"
          >content_copy</v-icon
        >
      </template>
      <span>{{ $t("riskAssessment.copyRiskAssessment") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit</v-icon
        >
      </template>
      <span>{{ $t("riskAssessment.editRiskAssessment") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>{{ $t("riskAssessment.deleteRiskAssessment") }}</span>
    </v-tooltip>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
    <!-- Loading Dialog -->
    <v-dialog v-model="loadPdfDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("riskAssessment.createDokument") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "Gefährdungsbeurteilung löschen",
      description: "",
    },
    loadPdfDialog: false,
  }),
  methods: {
    editItem(item) {
      this.$router.push({
        name: "riskAssessmentForm",
        params: { id: item.id, mode: "edit" },
      });
    },
    copyItem(item) {
      this.$router.push({
        name: "riskAssessmentForm",
        params: { id: item.id, mode: "copy" },
      });
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description =
        "Soll der Gefährdungsbeurteilung " + item.title + " wirklich gelöscht werden?";
    },
    deleteItem(item) {
      this.$store.dispatch("deleteRiskAssessment", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
    openPrintPdf(item) {
      let vm = this;
      this.loadPdfDialog = true;
      this.$store.dispatch("getPdfRiskAssessment", item._id).then(function (r) {
        //close loading
        vm.loadPdfDialog = false;
        const base64 = "data:application/pdf;base64," + r.base64;
        //open pdf
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<head><title>" +
            item.title +
            "</title></head><iframe width='100%' height='100%' src='" +
            encodeURI(base64) +
            "'></iframe>"
        );
      });
    },
  },
};
</script>
