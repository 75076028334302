<template>
  <div class="pa-1 ml-6">
    <v-form @submit.prevent="resetPassword()" ref="form" lazy-validation>
      <div class="body-1 mb-4">{{ $t("login.passwordReset") }}</div>
      <v-text-field
        v-model="logForm.password"
        id="password"
        prepend-icon="lock"
        :label="$t('login.password')"
        :rules="passRules"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      <v-text-field
        v-model="logForm.password1"
        id="password1"
        prepend-icon="lock"
        name="password1"
        :label="$t('login.enterPassword')"
        :rules="passRules"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      <p v-if="logForm.registerPasswWarning" class="error--text">
        {{ $t("login.passwordsNotIdentical") }}
      </p>
    </v-form>
    <v-btn class="mt-6" color="primary" block @click="resetPassword()"
      >{{ $t("login.passwordReset") }}</v-btn
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
      passRules: [
        (v) => !!v || "Ein Passwort wird benötigt",
        (v) =>
          (!!v && v.length >= 8) ||
          "Das Passwort benötigt eine Mindestlänge von 8 Zeichen.",
      ],
      logForm: {
        password: "",
        password1: "",
        registerPasswWarning: false,
      },
      requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
    };
  },
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        //check if both passwords are the same
        this.logForm.registerPasswWarning = false;
        if (this.logForm.password !== this.logForm.password1) {
          this.logForm.registerPasswWarning = true;
        } else {
          this.logForm.password = this.logForm.password.trim();
          this.$store
            .dispatch("setNewPassword", {
              password: this.logForm.password,
              id: this.$route.params.id,
            })
            .then(() => {
              //redirect to login
              this.$router.push("/login/login/1/1/1");
            });
        }
      }
    },
    openRegister() {
      this.$router.push("/login/register");
    },
  },
};
</script>
