<template>
  <v-app>
    <router-view></router-view>
    <defaultFooter />
  </v-app>
</template>

<script>
import defaultFooter from "@/components/layout/footer.vue";

export default {
  components: { defaultFooter },
};
</script>
<style>
/* let's remove those ugly borders from iframes */
iframe {
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 10%);
}

/* try to adapt quill stile to vuetify */

.quill-editor .ql-toolbar {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;

  padding-left: 0 !important;
  padding-right: 0 !important;
}

.quill-editor span.ql-picker-label {
  padding-left: 0 !important;
}

.quill-editor .ql-container,
.quill-editor .ql-editor,
.quill-editor .ql-editor::before {
  border: none !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  left: 0 !important;
}
</style>
