<template>
  <div>
    <div class="subtitle-1 mt-5">{{ $t("instructions.addFile") }}</div>
    <input
      @change="handleImage"
      class="mt-2"
      type="file"
      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, video/mp4, video/x-matroska, video/quicktime"
    />
  </div>
</template>
<script>
export default {
  props: ["value"],
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.value.filetype = fileObject.name
          .match(/\.[0-9a-z]+$/i)[0]
          .split(".")[1];
        this.value.name = fileObject.name;
        this.value.content = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>
