import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import filesModule from "./files/index.js";
import authModule from "./auth/index.js";
import configModule from "./config/index.js";
import boardModule from "./board/index.js";
import ticketModule from "./ticket/index.js";
import inspectionModule from "./inspection/index.js";
import userModule from "./user/index.js";
import riskAssessmentModule from "./riskAssessment/index.js";
import settingsModule from "./settings/index.js";
import surveyModule from "./survey/index.js";
import jobModule from "./job/index.js";
import operatingInstructionModule from "./operatingInstruction/index.js";
import instructionModule from "./instruction/index.js";
import managedInstructionAssignmentModule from "./managedInstructionAssignment/index.js";
import employeeModule from "./employee/index.js";
import employeeGroupModule from "./employeeGroup/index.js";
import tableStore from "../components/_utils/dataTableHelpers/tableStore/index";
import incidentReportModule from "./incidentReport/index.js";
import medkitlogModule from "./medkitlog/index.js";
import subscriptionJsModule from "./subscriptionJs/index.js";
import tariffModule from "./tariff/index.js";
import managedInstructionModule from "./managedInstruction/index.js";
import multiuserModule from "./multiuser/index.js";
import dangerousGoodsStorageLocationModule from "./dangerousGoods/storageLocations/index.js";
import dangerousGoodsListModule from "./dangerousGoods/dangerousGoodsList/index.js";
import examinationModule from "./examinations/index.js";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    filesModule,
    authModule,
    configModule,
    boardModule,
    ticketModule,
    inspectionModule,
    userModule,
    riskAssessmentModule,
    settingsModule,
    surveyModule,
    jobModule,
    operatingInstructionModule,
    managedInstructionAssignmentModule,
    employeeModule,
    employeeGroupModule,
    instructionModule,
    tableStore,
    incidentReportModule,
    medkitlogModule,
    subscriptionJsModule,
    tariffModule,
    managedInstructionModule,
    multiuserModule,
    dangerousGoodsStorageLocationModule,
    dangerousGoodsListModule,
    examinationModule,
  },
});

export default store;
