import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
};

const jobModule = {
  state,
  mutations,
  actions,
  getters,
};

export default jobModule;
