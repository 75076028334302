<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card outlined flat>
          <v-card-title class="headline">Safetyplanner</v-card-title>
          <v-card-text
            ><b>Variante wählen</b>

            <v-card
              flat
              outlined
              dense
              class="my-3"
              v-for="variant in JSON.parse(this.$route.params.portalData)
                .Products.PlanVariants"
              v-bind:key="variant.Id"
            >
              <v-card-title class="headline">{{
                variant.InternalName
              }}</v-card-title>
              <v-card-text>{{ variant.Description }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  data-cy="selectElement"
                  @click="continueTab(variant)"
                  >Buchen</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card> </v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  name: "planSelect",
  data: () => ({}),
  mounted() {
    console.log(JSON.parse(this.$route.params.portalData));
    console.warn(
      JSON.parse(this.$route.params.portalData).Products.PlanVariants
    );
  },
  methods: {
    continueTab(variant) {
      console.warn(variant);
      //increase Tab
      this.$router.push({
        name: "changePlan",
        params: {
          portalData: this.$route.params.portalData,
          step: Number(this.$route.params.step) + 1,
          selected: JSON.stringify(variant),
        },
      });
    },
  },
};
</script>
