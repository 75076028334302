<template>
  <v-radio-group v-model="inputVal" row>
    <div v-bind:key="index" v-for="(item, index) in config.buttons" class="mx-auto">
      <v-radio
        class="mr-10"
        :label="item.label"
        :value="item.value"
        @change="$emit('postValue', item.value)"
      ></v-radio>
    </div>
  </v-radio-group>
</template>
<script>
export default {
  props: ["config", "value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
