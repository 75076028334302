<template>
  <div>
    <v-radio-group v-model="value.optional">
      <v-radio :value="true">
        <template v-slot:label>
          <div>{{ $t("examination.proposal") }}</div>
        </template>
      </v-radio>
      <v-radio :value="false" >
        <template v-slot:label>
          <div>{{ $t("examination.obligatory") }}</div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: ["value"],
};
</script>
