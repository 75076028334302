<template>
  <div>
    <v-row align="center" dense justify="end" justify-sm="start" class="my-1">
      <v-col
        v-for="member in shortMembers"
        v-bind:key="member._id"
        class="flex-grow-0"
      >
        <v-chip small>
          {{ member.employee.name }}
          <div class="ml-1">
            <v-icon v-if="member.status === 'SUCCESS'" medium color="success"
              >mdi-check</v-icon
            >
            <v-icon v-else medium color="grey">clear</v-icon>
          </div>
          <div v-if="member.completedAt != null">
            - {{ formatDate(member.completedAt) }}
          </div>
        </v-chip>
      </v-col>
      <v-col v-if="item?.assignments?.length > 3">
        <v-btn
          small
          depressed
          rounded
          @click="$refs.employeeGroupTableEmployeeDialog.openDialog()"
        >
          {{
            $tc(
              "managedInstructions.assignedDialog.andMore",
              item.assignments.length - 3,
              { count: item.assignments.length - 3 }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <managed-instruction-assignment-table-employee-dialog
      ref="employeeGroupTableEmployeeDialog"
      :employees="item.assignments"
    />
  </div>
</template>
<script>
import { dateUtil } from "../../../components/api/api";
import ManagedInstructionAssignmentTableEmployeeDialog from "@/views/managedInstruction/managedInstructionAssignmentTableEmployeeDialog.vue";

export default {
  components: {
    ManagedInstructionAssignmentTableEmployeeDialog,
  },
  props: ["item"],
  computed: {
    shortMembers() {
      return this.item?.assignments?.slice(0, 3) || [];
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return dateUtil(new Date(date), false, "FEHLER");
    },
  },
};
</script>
