<template>
  <!--translate-->
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon
        medium
        class="mr-2"
        v-on="on"
        @click.stop="$emit('triggerChangePassword', item)"
        data-cy="changePassword"
        >mdi-key</v-icon
      >
    </template>
    <span>{{ $t("settings.changePassword") }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["item"],
};
</script>
