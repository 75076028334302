<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon
        medium
        class="mr-2"
        v-on="on"
        v-on:click.stop="open = true"
        data-cy="userPermissions"
        >mdi-lock
      </v-icon>
      <v-dialog
        v-model="open"
        max-width="500"
        v-bind:persistent="!permissionsLocked"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("settings.editPermissions")
          }}</v-card-title>
          <v-card-text
            ><b>{{ item.name }} ({{ item.email }})</b></v-card-text
          >
          <v-card-text>
            <p v-if="permissionsLocked" class="red--text">
              <v-icon color="red" x-small class="mr-1 mb-1">mdi-lock</v-icon>
              {{ $t("settings.permissionsLocked") }}
            </p>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-for="permission in permissionsLeft"
                  v-bind:key="permission"
                  v-bind:label="$t(`settings.permissions.${permission}`)"
                  v-model="item.permissions"
                  v-bind:value="permission"
                  v-bind:disabled="permissionsLocked || loading"
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-for="permission in permissionsRight"
                  v-bind:key="permission"
                  v-bind:label="$t(`settings.permissions.${permission}`)"
                  v-model="item.permissions"
                  v-bind:value="permission"
                  v-bind:disabled="permissionsLocked || loading"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text v-on:click="open = false"
              >{{ $t("general.cancel") }}
            </v-btn>
            <v-btn
              v-if="!permissionsLocked"
              v-bind:loading="loading"
              color="error"
              text
              v-on:click="save()"
              data-cy="deleteEvent"
              >{{ $t("general.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <span>{{ $t("settings.editPermissions") }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    open: false,
    loading: false,
    permissionsLeft: [
      "inspection",
      "instruction",
      "riskassessment",
      "usermanagement",
      "incidentreport",
      "multiusermanagement",
    ],
    permissionsRight: [
      "operatinginstruction",
      "medkitlog",
      "managedinstruction",
      "documentmanagement",
      "dangerousgoodstorage",
      "examination",
    ],
    localPermissions: [],
  }),
  mounted() {
    this.localPermissions = this.item.permissions;
  },
  computed: {
    permissionsLocked() {
      const thisUser = JSON.parse(localStorage.getItem("userData"));
      return (
        thisUser._id === this.item._id ||
        !thisUser.permissions.includes("usermanagement")
      );
    },
  },
  methods: {
    save() {
      if (this.permissionsLocked) return;

      this.loading = true;
      this.$store.dispatch("updateUser", this.item).then(() => {
        this.loading = false;
        this.open = false;
      });
    },
  },
};
</script>
