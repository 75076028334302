<template>
  <v-dialog v-model="isOpen" max-width="800">
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon color="secondary" v-on="{ ...on, ...onTooltip }">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
        <span>Hilfe</span>
      </v-tooltip>
    </template>
    <v-card>
      <div>
        <v-card flat>
          <v-card-title
            >{{ data.title }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="secondary"
              v-bind:href="data.supportUrl"
              target="_blank"
            >
              <v-icon>support</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="mb-4 text-subtitle-1" v-if="data.subtitle">
              {{ data.subtitle }}
            </div>
            <div v-html="data.description"></div>
          </v-card-text>

          <div v-if="data.downloadButtons?.length !== 0">
            <v-divider class="mx-4" />
            <v-card-text
              v-for="(item, i) in data.downloadButtons"
              v-bind:key="i"
            >
              <a target="_blank" v-bind:href="item.downloadUrl">
                <v-icon color="primary" left small>mdi-file</v-icon>
                {{ item.name }}
              </a>
            </v-card-text>
            <v-divider class="mx-4" v-if="data.youtubeUrl"></v-divider>
          </div>

          <v-card-text v-if="data.youtubeUrl">
            <video-embed :src="data.youtubeUrl"></video-embed>
          </v-card-text>
        </v-card>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="isOpen = false"> Schließen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["data"],
  components: {},
  data: () => ({
    isOpen: false,
  }),
};
</script>
<style>
.embed-responsive-item {
  width: 100%;
}
</style>
