<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ title }}
    </heading>
    <v-breadcrumbs :items="breadcrumbs" class="breadcrumbs"></v-breadcrumbs>

    <dangerousGoodsListTable />
  </div>
</template>

<script>
import dangerousGoodsListTable from "@/views/dangerousGoods/dangerousGoodsListTable.vue";
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
import i18n from "../../i18n";
export default {
  name: "dangerousGoods",
  components: { dangerousGoodsListTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.dangerousGoods;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.dangerousGoods;
      }
      return sidebarTextDE.default.dangerousGoods;
    },
    breadcrumbs() {
      return [
        {
          text: i18n.t(
            "dangerousGoods.dangerousGoodsList.breadcrumbs.storageLocations"
          ),
          disabled: false,
          href: `/app/dangerousGoods`,
        },
        {
          text: this.title,
          disabled: true,
        },
      ];
    },
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    this.title = this.$route.params.storageLocationName;
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 12px 12px 18px 0;
}
</style>
