<template>
  <div>
    <alertComponent />
    <!-- display only on non survey sites -->
    <v-footer
      dense
      fixed
      padless
      dark
      v-if="$route.fullPath != '/survey/register' && !$route.fullPath.includes('login')"
      color="primary"
    >
      <v-col class="text-right" cols="12">
        <span class="text--white text-caption" style="opacity: 0.5"
          >&copy; {{ new Date().getFullYear() }} — {{ version }} SafetyPlanner
          GmbH
        </span>
      </v-col>
    </v-footer>
  </div>
</template>
<script>
import alertComponent from "./alertComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: { alertComponent },
  computed: {
    ...mapGetters(["version"]),
  },
};
</script>
