<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-4"
          v-on="on"
          @click.stop="openInspectionProof()"
          data-cy="copy"
          :color="item.status == 'Draft' ? 'error' : 'success'"
          >check</v-icon
        >
      </template>
      <span>{{ $t("inspections.closeInspection") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openPrintPdf(item)"
          data-cy="open"
          >picture_as_pdf</v-icon
        >
      </template>
      <span>{{ $t("inspections.openInspection") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="copyItem(item)"
          data-cy="copy"
          >content_copy</v-icon
        >
      </template>
      <span>{{ $t("inspections.copyInspection") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit</v-icon
        >
      </template>
      <span>{{ $t("inspections.editInspection") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>{{ $t("inspections.deleteInspection") }}</span>
    </v-tooltip>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
    <!-- Loading Dialog -->
    <v-dialog v-model="loadPdfDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("inspections.renderInspection") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <inspectionFinish
      ref="inspectionFinish"
      :data="inspectionFinishData"
      :item="item"
      @reloadData="reloadDataFunc"
    />
    <inspectionProofList
      ref="inspectionProofList"
      :data="inspectionProofListData"
      :item="item"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
import inspectionFinish from "./inspectionFinish.vue";
import inspectionProofList from "./inspectionProofList.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
    inspectionFinish,
    inspectionProofList,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "inspections.deleteInspection",
      description: "",
    },
    inspectionFinishData: {
      open: false,
      title: "inspections.closeInspection",
      item: null,
      method: "new",
    },
    inspectionProofListData: {
      open: false,
      title: "inspections.instructionProof",
      item: null,
      method: "new",
    },
    loadPdfDialog: false,
  }),
  computed: {
    computedItemProofLength() {
      return String(this.item.proofs.length);
    },
  },
  methods: {
    editItem(item) {
      this.$router.push({
        name: "inspectionForm",
        params: { id: item.id, mode: "edit" },
      });
    },
    copyItem(item) {
      this.$router.push({
        name: "inspectionForm",
        params: { id: item.id, mode: "copy" },
      });
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description = this.$t("inspections.deleteTitleLong", {
        title: item.title,
      });
    },
    deleteItem(item) {
      this.$store.dispatch("deleteInspection", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
    openPrintPdf(item) {
      let vm = this;
      this.loadPdfDialog = true;
      this.$store.dispatch("getPdfInspection", item._id).then(function (r) {
        //close loading
        vm.loadPdfDialog = false;
        const base64 = "data:application/pdf;base64," + r.base64;
        //open pdf
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<head><title>" +
            item.title +
            "</title></head><iframe width='100%' height='100%' src='" +
            encodeURI(base64) +
            "'></iframe>"
        );
      });
    },
    createFormPath(item) {
      if (item) {
        return (
          "Unterweisungen/" +
          item.name +
          "_" +
          item.location +
          "." +
          item.filetype
        );
      }
    },
    async downloadItem(item) {
      //open url to download file
      window.open(await this.getDownloadItemLink(item), "_blank");
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
    openInspectionProof() {
      this.inspectionFinishData.open = true;
    },
  },
};
</script>
