<template>
  <div v-if="item">
    <v-card outlined flat min-height="400px">
      <v-card-title>{{ item.title }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="5" sm="3">
            <div class="mb-4 text-subtitle-1">{{ item.subtitle }}</div>
            <div v-html="item.description"></div
          ></v-col>
          <v-col cols="12" md="1" sm="1" class="pa-0 pb-3">
            <v-divider class="mx-4" vertical></v-divider
          ></v-col>
          <v-col cols="12" md="6" sm="8" class="pa-0 pb-10">
            <component
              :is="item.component"
              @nextQuestion="$emit('nextQuestion')"
              :key="item.component"
              :item="item"
              ref="questionComponent"
          /></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as components from "./questionComponents/index";
//import localforage from "localforage";
export default {
  components,
  props: ["item"],
  data() {
    return {};
  },
  methods: {},
};
</script>
