<template>
  <!--translate-->
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit</v-icon
        >
      </template>
      <span>{{ $t("examination.assignedExaminationsTable.addTooltip") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    editItem(item) {
      this.$router.push({
        name: "examinationEmployeeGroupForm",
        params: { id: item.id, mode: "edit" },
      });
    },
  },
};
</script>
