import axios from "axios";
const actions = {
  getTableData(context, tableData) {
    axios
      .get(urlBuilder(context.state.dataAll.pagination, tableData, context))
      .then(function (r) {
        context.commit("SET_TABLE_DATA", r.data.data);
        context.commit("SET_TOTAL_ITEMS", parseInt(r.data.count));
      })
      .catch(function (e) {
        context.commit("SET_TABLE_DATA", e);
        context.dispatch("triggerError", e);
      });
  },
};

function urlBuilder(pagination, tableData, context) {
  let queryParams = {};
  const { page, itemsPerPage, search } = pagination;
  //init url
  let buildUrl = tableData.uriPartPrepend;
  //add pagination
  if (itemsPerPage > 0) {
    queryParams.page = page;
    queryParams.perPage = itemsPerPage;
  }
  //add search when visible
  if (search != "" && search != null) {
    queryParams.search = search;
    context.commit("RESET_PAGINATION_PAGES_ALL");
  }
  //check if fields should be populated then populate
  if (tableData.populate.length > 0) {
    queryParams.populate = tableData.populate.join();
  }
  //check if table should be sorted then sort
  //table sort overrides tableData.sort propety
  if (pagination.sortBy.length > 0) {
    //check if sortDesc is set
    queryParams.sort =
      (pagination.sortDesc[0] ? "-" : "") + pagination.sortBy[0];
  } else {
    //no table sort selected try sort parameter
    if (tableData.sort) {
      queryParams.sort = tableData.sort;
    }
  }
  //append parameters
  buildUrl =
    buildUrl +
    "?" +
    new URLSearchParams(queryParams).toString() +
    tableData.uriPartAppend;
  return buildUrl;
}
export default actions;
