<template>
  <v-dialog v-model="show" max-width="733">
    <v-card v-if="data">
      <v-card-title>{{ data.name }}</v-card-title>
      <v-card-subtitle
        >{{ data.email }} - {{ data.employeeLanguage }}</v-card-subtitle
      >

      <v-card-text>
        <div class="employee-info-row">
          <v-card flat>
            <v-card-title>{{
              $t("employeeDetailsDialog.createdAt")
            }}</v-card-title>
            <v-card-subtitle class="text-body-1">{{
              formatDate(new Date(data.createdAt))
            }}</v-card-subtitle>
          </v-card>
          <v-card flat>
            <v-card-title>{{
              $t("employeeDetailsDialog.changed")
            }}</v-card-title>
            <v-card-subtitle class="text-body-1">{{
              formatDate(new Date(data.createdAt))
            }}</v-card-subtitle>
          </v-card>
          <v-card flat>
            <v-card-title>{{
              $t("employeeDetailsDialog.overdueInstructions")
            }}</v-card-title>
            <v-card-subtitle
              v-bind:class="{ 'red--text': overdueAssignments?.length > 0 }"
              class="text-body-1 font-weight-bold"
            >
              {{ overdueAssignments?.length }}
            </v-card-subtitle>
          </v-card>
        </div>
        <v-tabs v-model="tab">
          <v-tab>Zugewiesene</v-tab>
          <v-tab>Verlauf</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              :headers="headers"
              :items="instructionAssignments"
              :options.sync="tableDataGetter.pagination"
              :footer-props="{
                itemsPerPageOptions:
                  tableDataGetter.pagination.rowsPerPageItems,
                itemsPerPageText: $t('general.rowsPerPage'),
              }"
            >
              <template v-slot:item.instruction="{ item }">
                {{ item.instruction.name[0].name }}
              </template>
              <template v-slot:item.groups="{ item }">
                <v-chip v-for="group in item.groups" :key="group" class="mr-1">
                  {{ group }}
                </v-chip>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <div
              v-if="!overdueAssignments?.length && !finishedAssignments?.length"
            >
              <h3 class="text--primary mb-2">
                {{ $t("employeeDetailsDialog.noInstructions") }}
              </h3>
            </div>
            <div v-if="overdueAssignments?.length > 0">
              <!-- <v-divider class="my-4" /> -->
              <h3 class="text--primary my-2">
                {{ $t("employeeDetailsDialog.overdueInstructions") }}
              </h3>
              <v-card outlined>
                <v-expansion-panels flat>
                  <v-expansion-panel
                    v-for="assignment in overdueAssignments"
                    v-bind:key="assignment['_id']"
                  >
                    <v-expansion-panel-header
                      >{{ assignment.instruction.name[0].name }}
                      {{ formatDate(new Date(assignment.dueDate)) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider class="mb-4" />

                      <span class="text-lg-caption font-weight-bold">{{
                        $t("employeeDetailsDialog.createdAt")
                      }}</span>
                      <p>
                        {{ formatDate(new Date(assignment.createdAt)) }}
                      </p>

                      <span class="text-lg-caption font-weight-bold">{{
                        $t("employeeDetailsDialog.dueTo")
                      }}</span>
                      <p>
                        {{ formatDate(new Date(assignment.dueDate)) }}
                      </p>

                      <div class="d-flex justify-end">
                        <managed-instruction-assignment-overdue-send-reminder
                          :employee="data"
                          :instruction-id="assignment._id"
                        />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </div>
            <div v-if="finishedAssignments?.length > 0">
              <v-divider class="my-4" />
              <h3 class="text--primary mb-2">
                {{ $t("employeeDetailsDialog.completedInstructions") }}
              </h3>
              <v-card outlined>
                <v-expansion-panels flat>
                  <v-expansion-panel
                    v-for="assignment in finishedAssignments"
                    v-bind:key="assignment['_id']"
                  >
                    <v-expansion-panel-header
                      >{{ assignment.instruction.name[0].name }}
                      {{ formatDate(new Date(assignment.dueDate)) }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-divider class="mb-4" />

                      <span class="text-lg-caption font-weight-bold">{{
                        $t("employeeDetailsDialog.createdAt")
                      }}</span>
                      <p>{{ formatDate(new Date(assignment.createdAt)) }}</p>

                      <span class="text-lg-caption font-weight-bold">{{
                        $t("employeeDetailsDialog.dueTo")
                      }}</span>
                      <p>{{ formatDate(new Date(assignment.dueDate)) }}</p>

                      <span class="text-lg-caption font-weight-bold">{{
                        $t("employeeDetailsDialog.completedAt")
                      }}</span>
                      <p>{{ formatDate(new Date(assignment.completedAt)) }}</p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" v-on:click="show = false">{{
          $t("general.back")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { dateUtil } from "@/components/api/api";
import ManagedInstructionAssignmentOverdueSendReminder from "../../../views/managedInstruction/managedInstructionAssignmentOverdueSendReminder.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ManagedInstructionAssignmentOverdueSendReminder,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters(["tableDataGetter"]),
    headers() {
      return [
        {
          text: "Unterweisung",
          value: "instruction.name[0].name",
          align: "left",
        },
        {
          text: "Gruppen",
          value: "groups",
        },
      ];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    data: undefined,
    overdueAssignments: undefined,
    finishedAssignments: undefined,
    instructionAssignments: undefined,
    tab: 0,
  }),
  watch: {
    show(newVal) {
      if (!newVal) {
        this.data = undefined;
        this.overdueAssignments = undefined;
        this.finishedAssignments = undefined;
      } else {
        this.fetchData();
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date || isNaN(date)) return null;
      return dateUtil(new Date(date), true, "ERROR");
    },
    fetchData() {
      this._fetchOverdue();
      this._fetchFinishedAssignments();
      this._fetchGroups();
    },
    _fetchOverdue() {
      axios
        .get(`/employee/${this.data["_id"]}/overdueAssignments`)
        .then((rawData) => (this.overdueAssignments = rawData.data.data));
    },
    _fetchFinishedAssignments() {
      axios
        .get(`/employee/${this.data["_id"]}/finishedAssignments`)
        .then((rawData) => (this.finishedAssignments = rawData.data.data));
    },
    _fetchGroups() {
      axios
        .get(
          `/employeeGroup?members=${this.data._id}&select=-members&populate=instructionAssignments.instruction`
        )
        .then((rawData) => {
          this.instructionAssignments =
            this.extractUniqueInstructionAssignments(rawData.data.data);
        });
    },
    extractUniqueInstructionAssignments(data) {
      const uniqueInstructions = {};

      data.forEach((group) => {
        const groupName = group.name;

        group.instructionAssignments.forEach((assignment) => {
          const instructionId = assignment.instruction._id;

          if (!uniqueInstructions[instructionId]) {
            uniqueInstructions[instructionId] = {
              ...assignment,
              groups: [groupName], // Initialize groups with the current group name
            };
          } else {
            uniqueInstructions[instructionId].groups.push(groupName);
          }
        });
      });

      return Object.values(uniqueInstructions);
    },
  },
};
</script>

<style scoped>
.employee-info-row {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  flex-wrap: wrap;

  gap: 15px;

  flex-grow: 1;

  justify-content: space-between;
}

.employee-info-row * {
  padding-left: 0;
  padding-right: 0;
}
</style>
