<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.operatingInstructions") }}
    </heading>
    <operatingInstructionTable />
  </div>
</template>

<script>
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
import operatingInstructionTable from "@/views/operatingInstruction/operatingInstructionTable.vue";
export default {
  components: { operatingInstructionTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.operatingInstruction;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.operatingInstruction;
      }
      return sidebarTextDE.default.operatingInstruction;
    },
  },
};
</script>
