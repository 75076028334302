<template>
  <div>
    <v-row align="center" dense justify="end" justify-sm="start" class="my-1">
      <v-col
        v-for="{ instruction, interval } in shortInstructions"
        v-bind:key="instruction._id"
        class="flex-grow-0"
      >
        <v-chip small>
          {{ instruction.name[0].name }} (<v-icon small>mdi-rotate-left</v-icon
          >{{ interval }})
        </v-chip>
      </v-col>
      <v-col v-if="item?.instructionAssignments?.length > 3">
        <v-btn
          small
          depressed
          rounded
          @click="
            $refs.employeeGroupTableInstructionAssignmentsDialog.openDialog()
          "
        >
          {{
            $tc(
              "employeeGroup.instructionsDialog.assignedInstructions.andMore",
              item.instructionAssignments.length - 3,
              { count: item.instructionAssignments.length - 3 }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <employee-group-table-instruction-assignments-dialog
      ref="employeeGroupTableInstructionAssignmentsDialog"
      :instruction-assignments="item.instructionAssignments"
    />
  </div>
</template>
<script>
import EmployeeGroupTableInstructionAssignmentsDialog from "@/views/employee/employeeGroupTableInstructionAssignmentsDialog.vue";

export default {
  components: {
    EmployeeGroupTableInstructionAssignmentsDialog,
  },
  props: ["item"],
  computed: {
    shortInstructions() {
      return this.item?.instructionAssignments?.length
        ? this.item.instructionAssignments.slice(0, 3)
        : [];
    },
  },
  methods: {},
};
</script>
