<template>
  <v-dialog v-model="data.open" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(data.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="instructionForm"
            v-model="instructionForm.formData"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{ $t("general.back") }}</v-btn>
        <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
          {{ $t("instructions.upload") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function getDefaultData() {
  return {
    name: "",
    filetype: "",
    content: "",
    nextExecution: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ),
  };
}
import formComponent from "../../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../../components/api/api";
export default {
  components: { formComponent },
  props: ["data", "item"],
  data: () => ({
    instructionForm: {
      formData: getDefaultData(),
      form: [
        [
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "py-0 mt-0",
            id: getUniqueId(),
            componentName: "instructionProofUpload",
            config: {},
          },
        ],
      ],
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate form
      if (this.$refs.formComponent.validateAllFields()) {
        //set id
        this.instructionForm.formData.id = this.item._id;
        //create new item
        this.$store
          .dispatch("createInstructionProof", this.instructionForm.formData)
          .then(() => {
            if (createNew) {
              //reset contact data to create new one
              this.resetData();
            } else {
              //close form
              this.closeform();
            }
          });
      }
    },
    resetData() {
      //reset whole form data
      this.instructionForm.formData = getDefaultData();
      //reset form component form only after contact create
      this.$refs.formComponent.resetForm();
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
