<template>
  <div>
    <v-text-field
      v-model="value.weeksOfEntitlementToContinuedPaymentOfWages"
      :label="
        $t(
          'incidentReports.incidentReportFormLong.weeksOfEntitlementToContinuedPaymentOfWages'
        )
      "
      maxlength="2"
      type="number"
      @input="validateInput"
    >
      <template v-slot:append-outer> Wochen </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {
    validateInput(value) {
      let num = parseInt(value, 10);

      if (num < 0) {
        this.value.weeksOfEntitlementToContinuedPaymentOfWages = "0";
        return;
      }

      if (num > 99) {
        this.value.weeksOfEntitlementToContinuedPaymentOfWages = "99";
      } else if (value.length > 2) {
        this.value.weeksOfEntitlementToContinuedPaymentOfWages = value.slice(0, 2);
      }
    },
  },
};
</script>
