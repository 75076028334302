<template>
  <div>
    <v-file-input
      label="Sicherheitsdatenblatt"
      accept="application/pdf"
      class="mt-2"
      outlined
      :value="defaultValueInput"
      @change="handleFile"
    ></v-file-input>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({
    defaultValueInput: null,
  }),
  watch: {
    value(newValue) {
      if (newValue.safetyDataSheet?.name) {
        this.setDefaultValueInput();
      }
    },
  },
  methods: {
    async setDefaultValueInput() {
      const file = new File([], this.value.safetyDataSheet?.name, {
        type: this.value.safetyDataSheet.filetype,
      });
      this.defaultValueInput = file;
    },
    handleFile(file) {
      if (!file) {
        this.value.safetyDataSheet = null;
      } else {
        this.createBase64(file);
      }
    },
    createBase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.value.safetyDataSheet = {
          filetype: fileObject.name.match(/\.[0-9a-z]+$/i)[0].split(".")[1],
          name: fileObject.name,
          content: e.target.result.split(",")[1],
        };
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>
