<template>
  <div v-if="!!content" class="mb-16">
    <div class="mx-8 mt-12">
      <h3 class="mb-4">{{ content.data.title }}</h3>
      <div class="mb-16 pr-0 pb-16 relative_container">
        <v-card outlined>
          <v-card-text>
            <div class="mb-4 text-subtitle-1">
              <b>{{ content.data.advisory.title }}</b>
            </div>
            <div v-html="content.data.advisory.text"></div>
            <v-btn
              large
              :href="content.data.advisory.buttonUrl"
              target="_blank"
              color="primary"
              class="action_button"
            >
              {{ content.data.advisory.buttonText }}
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card outlined>
          <v-card-text>
            <div class="mb-4 text-subtitle-1">
              <b>{{ content.data.support.title }}</b>
            </div>
            <div v-html="content.data.support.text"></div>

            <v-btn
              large
              :href="content.data.support.buttonUrl"
              target="_blank"
              color="primary"
              class="action_button"
            >
              {{ content.data.support.buttonText }}
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="mx-8 mb-8">
      <h2 class="mb-2">{{ $t("general.partners") }}</h2>
      <v-card
        class="mt-4 pb-4 pr-0"
        flat
        outlined
        v-for="(item, index) in content.partner"
        v-bind:key="index"
      >
        <v-card-text>
          <div class="mb-4 text-subtitle-1">
            <b>{{ item.title }}</b>
          </div>
          <div class="d-flex flex-row">
            <div
              class="mt-3"
              style="margin-right: 250px"
              v-html="item.text"
            ></div>
            <v-img
              width="200px"
              class="partner-image"
              :src="item.imageUrl"
            ></v-img>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            large
            :href="item.buttonUrl"
            target="_blank"
            color="primary"
            class="ml-2"
          >
            {{ item.buttonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import * as consultingContentEnglish from "../../components/api/content/consultingContent-en.json";
import * as consultingContentGerman from "../../components/api/content/consultingContent-de.json";

export default {
  data() {
    return {
      content: undefined,
    };
  },
  mounted() {
    this.updateContent();
  },
  watch: {
    "$i18n.locale": function () {
      this.updateContent();
    },
  },
  methods: {
    updateContent() {
      this.content =
        localStorage.getItem("locale") === "en"
          ? consultingContentEnglish.default
          : consultingContentGerman.default;
    },
  },
};
</script>
<style scoped>
.relative_container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.relative_container > * {
  flex: 1;
  padding-bottom: 50px !important;
}

.action_button {
  position: absolute;
  bottom: 1rem;
}

.divider {
  position: relative;
  top: -20px;
  min-height: 135% !important;
}

.partner-image {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.mb-8 {
  margin-bottom: 32px; /* Assuming the default margin-bottom for mb-8 is 32px */
}

.mb-16 {
  margin-bottom: 64px; /* Increased margin-bottom */
}
</style>
