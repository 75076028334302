<template>
  <div class="d-flex">
    <div>
      <managed-instruction-assignment-overdue-send-reminder
        v-if="!isCompleted(item)"
        v-bind:employee="item?.assignments?.employee"
        v-bind:instruction-id="item?._id"
      />
      <v-tooltip v-else right>
        <template v-slot:activator="{ attrs, on }">
          <v-icon color="success" v-bind="attrs" v-on="on">check</v-icon>
        </template>

        {{
          $t(
            "managedInstructions.assignedDialog.tableSlotComponents.action.completedInstruction"
          )
        }}
      </v-tooltip>
    </div>
    <div>
      <v-tooltip right>
        <template v-slot:activator="{ attr, on }">
          <v-btn
            v-on="on"
            v-bind="attr"
            text
            icon
            color="error"
            v-on:click="openDeleteDialog(item)"
          >
            <v-icon>mdi-account-remove</v-icon>
          </v-btn>
        </template>
        {{ $t("managedInstructionAssignment.removeAssignment.remove") }}
      </v-tooltip>
    </div>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
  </div>
</template>
<script>
import ManagedInstructionAssignmentOverdueSendReminder from "@/views/managedInstruction/managedInstructionAssignmentOverdueSendReminder.vue";
import deleteDialog from "../../../_utils/dataTableHelpers/actions/deleteDialog.vue";
export default {
  components: { ManagedInstructionAssignmentOverdueSendReminder, deleteDialog },
  props: ["item"],
  data: () => ({
    deleteDialog: {
      open: false,
      title: "managedInstructionAssignment.removeAssignment.deleteDialog.title",
      description: "",
    },
  }),
  methods: {
    isCompleted(item) {
      return item?.assignments?.completedAt ?? false;
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description = this.$t("managedInstructionAssignment.removeAssignment.deleteDialog.deleteTitleLong", {
        name: item.assignments.employee.name,
      });
    },
    deleteItem(item) {
      this.$store.dispatch("removeEmployeeFromAssignment", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
  },
};
</script>
