import axios from "axios";

const actions = {
  createInstruction(context, value) {
    return axios
      .post("instruction", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unterweisung " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  createInstructionProof(context, value) {
    return axios
      .post("instruction/" + value.id + "/proof", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unterweisungsnachweis erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteInstruction(context, val) {
    return axios
      .delete("instruction/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Unterweisung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateInstruction(context, value) {
    return axios
      .put("instruction/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unterweisung " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleInstruction(context, id) {
    return axios
      .get("instruction/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getPdfInstruction(context, id) {
    return axios
      .get("instruction/" + id + "/pdf")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
