import localforage from "localforage";

const actions = {
  setSurveyResult(context, data) {
    return localforage
      .setItem(data.key, data.value)
      .then(function (value) {
        return value;
      })
      .catch(function (err) {
        context.dispatch("triggerError", err);
      });
  },

  getSingleSurveyResult(context, data) {
    return localforage
      .getItem(data.key)
      .then(function (value) {
        return value;
      })
      .catch(function (err) {
        context.dispatch("triggerError", err);
      });
  },

  getAllTicketsFromStorage(context) {
    let tickets = [];
    let values = [];
    return localforage
      .iterate(function (value, key) {
        //check that ticket is present
        if (Object.keys(value.ticket).length != 0) {
          tickets.push(value.ticket);
        }
        //push answeres
        values.push({ key: key, value: value });
      })
      .then(function () {
        return { tickets: tickets, values: values };
      })
      .catch(function (err) {
        context.dispatch("triggerError", err);
      });
  },
};

export default actions;
