<template>
  <div>
    <heading :helpInfo="sidebarCard">{{ $t("sidebar.medkitlogs") }}</heading>
    <medkitlogTable />
  </div>
</template>

<script>
import medkitlogTable from "@/views/medkitlog/medkitlogTable.vue";
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
export default {
  name: "medkitlog",
  components: { medkitlogTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.medkitlog;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.medkitlog;
      }
      return sidebarTextDE.default.medkitlog;
    },
  },
};
</script>
