<template>
  <div v-if="item?.assignments?.employee?.email">
    {{ item?.assignments?.employee?.email }}
  </div>
  <div v-else>
    {{ item?.email }}
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>
