<template>
  <div>
    <v-switch
      :label="$t('medkitlogs.firstResponderNeeded')"
      data-cy="firstAid"
      v-model="value.firstAid"
    ></v-switch>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {},
};
</script>
