<template>
  <div class="pa-1 ml-6 mr-6">
    <v-form @submit.prevent="login()" ref="form" lazy-validation>
      <h3 class="body-3 mb-4">{{ $t("login.login") }}</h3>
      <v-text-field
        v-model="logForm.email"
        prepend-icon="mail"
        name="email"
        :label="$t('login.email')"
        type="email"
        :rules="requiredRule"
        :disabled="isLoading"
      ></v-text-field>
      <v-text-field
        class="mb-4"
        v-model="logForm.password"
        id="password"
        prepend-icon="lock"
        name="password"
        :label="$t('login.password')"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :rules="requiredRule"
        hide-details
        :disabled="isLoading"
      ></v-text-field>
      <v-btn
        class="mt-12"
        color="primary"
        block
        type="submit"
        :loading="isLoading"
      >
        {{ $t("login.login") }}
      </v-btn>
      <v-btn
        x-small
        text
        block
        color="primary"
        @click="openPasswordResetDialog"
        class="mt-2"
        >{{ $t("login.passwordReset") }}
      </v-btn>
    </v-form>
    <orSeparator />
    <v-btn color="primary" outlined block @click="openRegister()">
      {{ $t("login.register") }}
    </v-btn>
    <passwordDialog ref="passwordDialog" :passwordDialog="passwordDialog" />
  </div>
</template>
<script>
import passwordDialog from "./passwordDialog.vue";
import OrSeparator from "@/components/layout/orSeperator.vue";
import { removeDataFromLocalStorage } from "../../api.js";

export default {
  components: { OrSeparator, passwordDialog },
  data() {
    return {
      showPassword: false,
      logForm: {
        email: "",
        password: "",
      },
      requiredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
      passwordDialog: {
        open: false,
      },
      isLoading: false,
    };
  },
  created() {
    //reset snackbars and alerts
    this.removeDataFromLocalStorage();
  },
  mounted() {
    //react on url parameter reason
    if (
      this.$route.params.reason != "" &&
      this.$route.params.reason != "1" &&
      this.$route.params.reason === "isAuthenticated"
    ) {
      this.$store.dispatch("triggerUniversalAlert", {
        text: "Nach einer Inaktivität von 30 Minuten ist aus Sicherheitsgründen eine neue Anmeldung erforderlich. Bitte beachten Sie das diese Meldung auch durch einen weiteren Login auf einem anderen Gerät ausgelöst werden kann. Sollte diese Meldung dauerhaft auftreten, kontaktieren Sie bitte den Support.",
        heading: "Sie wurden automatisch abgemeldet",
        type: "warning",
      });
    }
  },
  methods: {
    removeDataFromLocalStorage,
    createRedirectLink() {
      //create redirect link in the following format:
      let href;
      if (this.$route.params.redirect != 1) {
        //use redirect link as base link
        href = new URL(decodeURIComponent(this.$route.params.redirect));

        href.searchParams.append("formTypeParam", this.$route.params.mode);
        href.searchParams.append("redirectParam", this.$route.params.redirect);

        window.location.replace(href.toString());
      } else {
        //use default
        this.$router.push("/app/tickets/all");
      }
    },
    login() {
      let vm = this;
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        //format email
        this.$store
          .dispatch("login", {
            email: this.logForm.email.trim().toLowerCase(),
            password: this.logForm.password.trim(),
          })
          .then((r) => {
            //redirect
            //this.$router.push("/app/tickets/all");
            //create redirect link when login success otherwise redirect to log-in
            r
              ? vm.createRedirectLink()
              : this.$router.push("/login/login/1/1/1");
            vm.loading = false;
          })
          .finally(() => (this.isLoading = false));
      }
    },
    openRegister() {
      this.$router.push("/survey/register");
    },
    openPasswordResetDialog() {
      //check if email can be preset
      if (this.logForm.email) {
        this.$refs.passwordDialog.setEmail(
          this.logForm.email.trim().toLowerCase()
        );
      }
      this.passwordDialog.open = true;
    },
  },
};
</script>
