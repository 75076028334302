<template>
  <div class="pictogram-row">
    <div
      v-for="pictogram of firstThreePictograms"
      :key="pictogram"
      class="pictogram"
    >
      <pictogram :image-path="pictogram" :show-text="false" />
    </div>
    <div v-if="hasMoreThanThreePictograms">
      (+{{ item.pictograms.length - 3 }})
    </div>
  </div>
</template>
<script>
import Pictogram from "@/components/layout/pictogram.vue";

export default {
  components: { Pictogram },
  props: ["item"],
  computed: {
    firstThreePictograms() {
      return this.item?.pictograms?.slice(0, 3) ?? [];
    },
    hasMoreThanThreePictograms() {
      return this.item?.pictograms?.length > 3 ?? false;
    },
  },
};
</script>

<style scoped>
.pictogram-row {
  display: flex;
  column-gap: 4px;
  align-items: center;
}
.pictogram {
  display: flex;
  align-items: center;
  max-width: 40px;
}
</style>
