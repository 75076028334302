<template>
  <div>
    <v-list disabled>
      <!--v-subheader>REPORTS</v-subheader-->
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="secondary">help</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <b
              >Gibt es in Ihrem Unternehmen eine ausreichende Beleuchtung an den
              von Ihnen bereitgestellten Arbeitsplätzen? (Normaler
              Büroarbeitsplatz 500lx)</b
            >
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <p class="caption"></p>
    <radioGroupConfig
      v-model="val"
      :config="radioGroupConfig"
      @postValue="postAnswere"
    />
  </div>
</template>
<script>
import radioGroupConfig from "./_defaults/radioGroupConfig.vue";
export default {
  props: ["item"],
  components: { radioGroupConfig },
  data() {
    return {
      val: null,
      radioGroupConfig: {
        buttons: [
          { label: "Ja", value: true },
          { label: "Nein", value: false },
        ],
      },
    };
  },
  created() {
    //set value from localforage if present
    this.$store
      .dispatch("getSingleSurveyResult", {
        key: this.item.component,
      })
      .then((r) => {
        if (r) {
          //preset value
          this.val = r.value;
        }
      });
  },
  methods: {
    postAnswere(value) {
      //check if ticket is needed
      let ticket = {};
      if (!value) {
        ticket = {
          title: "Arbeitsplätze mit ausreichender Beleuchtung ausstatten",
          description:
            "<a href='https://help.safetyplanner.de/help/de-de/34-arbeitsumgebung/19-beleuchtung' target='_blank'>weitere Informationen</a>",
          priority: 2,
        };
      }
      //save / update data and ticket
      this.$store
        .dispatch("setSurveyResult", {
          key: this.item.component,
          value: { value: value, ticket: ticket },
        })
        .then(() => {
          //this.$emit("nextQuestion");
        });
    },
  },
};
</script>
