<template>
  <!--translate-->
  <div>
    <v-card class="mx-auto my-4" max-width="800" flat outlined>
      <v-container class="mx-4 my-4">
        <v-row>
          <b>Multiuser (FASI) einladen</b>
          <div class="flex-grow-1"></div>
        </v-row>
      </v-container>
      <v-form class="pa-4 pt-0" ref="form" lazy-validation>
        <p class="mt-6 mb-3">
          Laden sie eine registrierte Fachkraft für Arbeitssicherheit per Email
          ein.
        </p>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <formComponent
              ref="formComponent"
              :form="userForm"
              v-model="userForm.formData"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary" outlined @click="saveForm(true)">
          Einladung versenden
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mx-auto my-4" max-width="800" flat outlined>
      <v-container class="mx-4 my-4">
        <v-row>
          <b>Multiuser (FASI) Übersicht</b>
          <div class="flex-grow-1"></div>
        </v-row>
      </v-container>
      <multiUserTable />
      <v-card-actions>
        <!--div class="flex-grow-1"></div>
        <v-btn color="primary" outlined @click="saveForm(true)">
          Einladung versenden
        </v-btn-->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
function getDefaultData() {
  return {
    email: "",
    code: "",
  };
}
import formComponent from "../../components/_utils/formComponent/formComponent.vue";
import multiUserTable from "./multiUser/multiUserTable.vue";
export default {
  components: { formComponent, multiUserTable },
  data: () => ({
    userForm: {
      formData: getDefaultData(),
      form: [
        [
          //v-cols
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-0",
            id: 1,
            componentName: "inviteEmail",
          },
        ],
      ],
    }
  }),
  created() {},
  methods: {
    saveForm(createNew) {
      if (this.$refs.formComponent.validateAllFields()) {
        //edit item
        if (this.userForm.formData.email != "") {
          this.$store
            .dispatch("multiuserInvite", this.userForm.formData)
            .then(() => {
              if (createNew) {
                //reset contact data to create new one
                this.resetData();
              }
            });
        }
      }
    },
    resetData() {
      //reset whole form data
      this.userForm.formData = getDefaultData();
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.formComponent.resetForm();
      }
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
