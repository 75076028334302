<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.inspections") }}
    </heading>
    <inspectionTable />
  </div>
</template>

<script>
import inspectionTable from "@/views/inspection/inspectionTable.vue";
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
export default {
  name: "inspection",
  components: { inspectionTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.inspection;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.inspection;
      }
      return sidebarTextDE.default.inspection;
    },
  },
};
</script>
