<template>
  <v-layout class="align-center justify-center login-background">
    <v-flex style="max-width: 650px">
      <v-card flat>
        <div class="login-wrapper">
          <v-card-text class="d-none d-md-flex">
            <v-flex col class="fill-height">
              <img
                src="../../assets/SafetyPlanner_logo.jpg"
                width="200"
                alt="logo"
              />
              <div class="d-flex flex-column justify-center fill-height pb-8">
                <h3>Wilkommen bei</h3>
                <h1 class="mt-2 mb-8">SafetyPlanner!</h1>
              </div>
            </v-flex>
          </v-card-text>

          <v-divider vertical class="d-sm-none d-md-flex"></v-divider>

          <!-- login -->
          <v-card-text style="flex-grow: 1">
            <login ref="login" v-if="$route.params.mode === 'login'" />
            <resetPassword
              ref="resetPassword"
              v-if="$route.params.mode === 'resetPassword'"
            />
          </v-card-text>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import login from "../../views/login/login.vue";
import resetPassword from "../../views/login/resetPassword.vue";

export default {
  components: { login, resetPassword },
  data: () => ({}),
  methods: {
    routerPush({ name, params }) {
      if (this.$route.params.mode === params.mode) return;

      this.$router.push({
        name,
        params,
      });
    },
  },
};
</script>
<style scoped>
.login-wrapper {
  display: flex;
  justify-content: flex-start;
}

.login-background {
  position: relative;
}

.login-background > * {
  z-index: 1;
}

.login-background::after {
  content: "";

  position: absolute;
  inset: 0;

  background-color: rgba(0, 0, 0, 5%);
  backdrop-filter: blur(500px);

  z-index: 0;
}

.login-background::before {
  content: "";
  background: linear-gradient(0.125turn, #e7eff9, #cfd6e6);

  position: fixed;
  inset: 0;

  opacity: 0;
  border-radius: 15%;

  z-index: 0;
}
</style>
