<template>
  <div>
    <!-- laod different components-->
    <scrum
      v-if="$route.params.mode === 'all' || $route.params.mode === 'user'"
    />
    <recurringTicketsTable v-if="$route.params.mode === 'recurring'" />
  </div>
</template>

<script>
import scrum from "./scrumboard/scrum.vue";
import recurringTicketsTable from "./recurringTickets/recurringTicketsTable.vue";
export default {
  components: { scrum, recurringTicketsTable },
  data: () => ({}),
};
</script>
