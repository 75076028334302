import axios from "axios";
import i18n from "../../../i18n";

const actions = {
  createDangerousGood(context, value) {
    return axios
      .post("dangerousGood", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("dangerousGoods.dangerousGoodsList.createdMessage", {
            name: r.data.name,
          })
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  deleteDangerousGood(context, val) {
    return axios
      .delete("dangerousGood/" + val.id)
      .then(function (res) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("dangerousGoods.dangerousGoodsList.deletedMessage")
        );
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  updateDangerousGood(context, value) {
    return axios
      .put("dangerousGood/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          i18n.t("dangerousGoods.dangerousGoodsList.updatedMessage", {
            name: r.data.name,
          })
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  getSingleDangerousGood(context, id) {
    return axios
      .get("dangerousGood/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
