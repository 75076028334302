<template>
  <div>
    <v-autocomplete
      v-model="value.responsible"
      :items="userDataMap"
      :loading="getAllUsersStatus"
      :rules="requiredRule"
      hide-no-data
      item-text="Description"
      item-value="id"
      :label="$t('settings.responsible')"
      data-cy="users"
      dense
    >
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  data: () => ({
    requiredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
  }),
  mounted() {
    this.$store.dispatch("getUsers");
  },
  computed: {
    ...mapGetters(["getAllUsers", "getAllUsersStatus"]),
    userDataMap() {
      return this.getAllUsers.map((user) => {
        const Description = user.name + " - " + user.short;
        const id = user._id;
        return Object.assign({}, user, {
          Description,
          id,
        });
      });
    },
  },
  methods: {},
};
</script>
