<template>
  <!--translate-->
  <v-card :loading="portalDataLoading" min-height="250px">
    <v-card-title>SafetyPlanner Rechnungen / Gutschriften</v-card-title>
    <v-divider></v-divider>
    <v-simple-table class="ma-4" v-if="portalData">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Rechnungsnummer</th>
            <th class="text-left">Datum</th>
            <th class="text-left">Gesamtbetrag (brutto)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in portalData.RecentInvoices" :key="item.Id">
            <td>{{ item.InvoiceNumber }}</td>
            <td>{{ formatDate(item.InvoiceDate) }}</td>
            <td>{{ formatPrice(item.TotalGross) }}€</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="downloadInvoice(item.Id)" v-on="on">
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Rechnung herunterladen</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
  </v-card>
</template>
<script>
import { dateUtil, formatCurrency } from "../../../../api.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "portalData",
      "portalDataLoading",
      "billwerkToken",
      "billwerkApiUrl",
    ]),
  },

  methods: {
    formatDate(date) {
      return dateUtil(new Date(date), false, "FEHLER");
    },
    formatPrice(price) {
      return formatCurrency(price);
    },
    downloadInvoice(id) {
      this.$store.dispatch("getBillwerkToken").then((r) => {
        const downloadUrl =
          this.billwerkApiUrl +
          "CustomerSelfService/Download/" +
          id +
          "?token=" +
          r;
        let link = document.createElement("a");
        link.href = downloadUrl;
        link.download = id + ".pdf";
        link.dispatchEvent(new MouseEvent("click"));
      });
    },
  },
};
</script>
