<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="2"> </v-col>
      <v-col cols="12" sm="12" md="8">
        <v-card class="mx-auto my-5 mb-10" max-width="1000">
          <v-app-bar flat>
            <span class="headline">{{ $t(formCompData.title) }}</span>
          </v-app-bar>
          <v-card-text>
            <v-container>
              <formComponent
                ref="formComponent"
                :form="formComp"
                v-model="formComp.eventForm"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey" outlined @click="resetData()">{{
              $t("general.back")
            }}</v-btn>
            <v-btn color="primary" @click="saveForm()" data-cy="saveEvent">
                {{ $t("general.saveChanges") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="2"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const getDefaultData = () => ({
  name: "",
  members: [],
  examinationAssignments: [
    {
      examination: null,
      interval: 11,
    },
  ],
});

import formComponent from "../../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../../components/api/api";
export default {
  components: { formComponent },
  props: ["data"],
  computed: {
    ...mapGetters(["allGroups", "allGroupsPending"]),
    allOtherGroupNames() {
      return this.allGroups
        .filter((group) => group._id !== this.formComp?.eventForm._id)
        .map((group) => group.name);
    },
    formCompData() {
      return {
        title: "examination.assignExaminationsForm.formTitle",
        item: null,
      };
    },
  },
  data: () => ({
    formComp: {
      eventForm: getDefaultData(),
      form: [
        [
          {
            //v-text-field
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 pt-4",
            id: getUniqueId(),
            componentName: "examinationGroupName",
          },
        ],
        // [
        //   {
        //     //v-select
        //     cols: 12,
        //     sm: 12,
        //     md: 12,
        //     class: "pb-0 mt-0",
        //     id: getUniqueId(),
        //     componentName: "selectEmployees",
        //     config: {},
        //   },
        // ],
        [
          {
            //multiple v-select
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-2",
            id: getUniqueId(),
            componentName: "selectExaminationAssignments",
            config: {
              rules: [(v) => !!v || "Dieses Feld wird benötigt"],
            },
          },
        ],
      ],
    },
  }),
  mounted() {
    this.switchFormModes(this.$route.params.mode, this.$route.params);
    this.$store.dispatch("getAllEmployeeGroups");
  },
  methods: {
    switchFormModes(mode, params) {
      switch (mode) {
        case "edit":
          this.$store
            .dispatch("getSingleEmployeeGroup", params.id)
            .then((item) => {
              this.formComp.eventForm = item;
            });
          break;
      }
    },
    saveForm() {
      if (this.allOtherGroupNames.includes(this.formComp.eventForm.name)) {
        this.$store.dispatch(
          "triggerErrorSnackbar",
          "Der Gruppenname existiert bereits."
        );
        return;
      }
      if (this.$refs.formComponent.validateAllFields()) {
        //edit item
        this.$store
          .dispatch("updateEmployeeGroup", this.formComp.eventForm)
          .then(() => {
            this.resetData();
          });
      }
    },
    openDialog(val) {
      this.occupiedDialog.open = true;
      this.occupiedDialog.events = val;
    },
    resetData() {
      this.reloadData();
      this.$router.back();
      this.formComp.eventForm = getDefaultData();
      this.$refs.formComponent.resetForm();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
