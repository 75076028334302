<template>
  <div>
    <v-tooltip v-if="classification && classification.text" bottom>
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          class="storage-classification-wrapper with-tooltip"
          :class="classification?.class"
        >
          {{ item.storageClassification }}
        </div>
      </template>
      <span v-if="classification.text" v-html="classification.text"></span>
    </v-tooltip>
    <div
      v-else
      class="storage-classification-wrapper"
      :class="classification?.class"
    >
      {{ item.storageClassification }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["item"],
  computed: {
    ...mapGetters(["allStorageClassifications", "tableDataGetter"]),
    allItems() {
      return this.tableDataGetter.allList.data;
    },
    allOtherItems() {
      return this.allItems.filter(
        (dangerousGood) => dangerousGood.id !== this.item.id
      );
      // .map((dangerousGood) => dangerousGood.storageClassification);
    },
    currentClassification() {
      return this.allStorageClassifications[this.item.storageClassification];
    },
    classificationValidationResult() {
      const result = {};

      for (const otherItem of this.allOtherItems) {
        result[otherItem.id] =
          this.currentClassification[otherItem.storageClassification];
      }

      return result;
    },
    containsRedClassification() {
      return Object.values(this.classificationValidationResult).some(
        (result) => result === "-"
      );
    },
    containsYellowClassification() {
      return Object.values(this.classificationValidationResult).some((result) =>
        ["1", "2", "3", "4", "5", "6", "7"].includes(result)
      );
    },
    classificationText() {
      let text = "";
      for (const [key, value] of Object.entries(
        this.classificationValidationResult
      )) {
        const status = this.getStorageClassification(value);
        const otherDangerousGood = this.allItems.find((i) => i.id === key);
        if (status === "forbidden") {
          text =
            text +
            `<br>${this.$t(
              "dangerousGoods.dangerousGoodsList.tableData.storageClassification.forbiddenMessage",
              { name: otherDangerousGood.name }
            )}`;
        } else if (status === "warning") {
          text =
            text +
            `<br>Der Gefahrstoff "${otherDangerousGood.name}" darf nur mit besonderen Maßnahmen mit diesem gelagert werden.`;
        }
      }

      return text.slice(4);
    },
    classificationBackgroundClass() {
      return this.containsRedClassification
        ? "bg-red"
        : this.containsYellowClassification
        ? "bg-yellow"
        : "bg-green";
    },
    classification() {
      if (!this.currentClassification) return null;

      return {
        text: this.classificationText,
        class: this.classificationBackgroundClass,
      };
    },
  },
  methods: {
    getStorageClassification(classificationSymbol) {
      if (classificationSymbol === "-") {
        return "forbidden";
      } else if (
        ["1", "2", "3", "4", "5", "6", "7"].includes(classificationSymbol)
      ) {
        return "warning";
      } else {
        return "allowed";
      }
    },
  },
};
</script>
<style scoped>
.storage-classification-wrapper {
  border-radius: 4px;
  padding: 2px 4px;
  text-align: center;
}
.storage-classification-wrapper.with-tooltip {
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
  text-align: center;
}
.storage-classification-wrapper.bg-red {
  background: red;
}
.storage-classification-wrapper.bg-green {
  background: green;
}
.storage-classification-wrapper.bg-yellow {
  background: yellow;
}
</style>
