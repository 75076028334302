<template>
  <div>
    <div class="text-center">
      <v-snackbar
        v-model="error.visible"
        :timeout="3000"
        left
        color="#ff5252"
        >{{ error.message }}</v-snackbar
      >
      <v-snackbar
        v-model="snackbar.visible"
        :timeout="3000"
        left
        color="secondary"
        >{{ snackbar.message }}</v-snackbar
      >
    </div>
    <!-- vuex controlled default alert dialog-->
    <v-row justify="center">
      <v-dialog v-model="universalAlert.visible" persistent max-width="550">
        <v-card>
          <v-card-title class="headline">{{
            universalAlert.heading
          }}</v-card-title>
          <v-card-text>
            <v-alert dense border="left" :type="universalAlert.type">
              {{ universalAlert.text }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="$store.dispatch('closeUniversalAlert')"
              >{{ $t("general.back") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["error", "snackbar", "universalAlert"]),
  },
};
</script>
