import axios from "axios";

const actions = {
  createMedkitlog(context, value) {
    return axios
      .post("medkitlog", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Verbandbucheintrag " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteMedkitlog(context, val) {
    return axios
      .delete("medkitlog/" + val.id)
      .then(function (res) {
        context.commit(
          "CREATE_SNACKBAR",
          "Verbandbucheintrag erfolgreich gelöscht"
        );
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateMedkitlog(context, value) {
    return axios
      .put("medkitlog/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Verbandbucheintrag " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleMedkitlog(context, id) {
    return axios
      .get("medkitlog/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  createMedkitlogPdf(context, value) {
    return axios
      .get(
        "medkitlog/pdf?start=" +
          new Date(value.start).toISOString() +
          "&end=" +
          new Date(value.end).toISOString()
      )
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
