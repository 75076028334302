<template>
  <div>{{ displayName }}</div>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    displayName() {
      if (this.item.incidentReportType === "long") {
        const { firstName, lastName } =
          this.item.longIncidentReport?.insuredPerson;
        return `${firstName ?? ''}${lastName ? " " + lastName : ""}`;
      } else {
        return this.item.name;
      }
    },
  },
};
</script>
