<template>
  <div>
    <surveyTemplateAppBar />
    <v-row align="start" class="my-16">
      <v-col cols="12" md="2" sm="2"> </v-col>
      <v-col cols="12" md="8" sm="8">
        <surveyStepper @footerReload="$refs.surveyFooter.reload()" />
      </v-col>
      <v-col cols="12" md="2" sm="2"> </v-col>
    </v-row>
    <surveyStartDialog :surveyStartDialog="surveyStartDialog" />
    <surveyFooter ref="surveyFooter" />
  </div>
</template>
<script>
//import localforage from "localforage";
import surveyTemplateAppBar from "./surveyTemplateAppBar.vue";
import surveyStepper from "./surveyStepper.vue";
import surveyStartDialog from "./surveyStartDialog.vue";
import surveyFooter from "./surveyFooter.vue";
export default {
  components: {
    surveyTemplateAppBar,
    surveyStepper,
    surveyStartDialog,
    surveyFooter,
  },
  data() {
    return {
      surveyStartDialog: {
        open: false,
      },
    };
  },
  mounted() {
    //check and react on mode
    if (this.$route.params.mode === "register") {
      //open pre dialog
      this.surveyStartDialog.open = true;
    }
  },
};
</script>
