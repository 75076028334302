<template>
  <div>
    <v-icon> {{ fileIcons[item.filetype] }} </v-icon>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      fileIcons: {
        zip: "mdi-folder-zip",
        rar: "mdi-folder-zip",
        htm: "mdi-language-html5",
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-json",
        md: "mdi-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        JPG: "mdi-file-image",
        jpeg: "mdi-file-image",
        mp4: "mdi-filmstrip",
        mkv: "mdi-filmstrip",
        avi: "mdi-filmstrip",
        wmv: "mdi-filmstrip",
        mov: "mdi-filmstrip",
        txt: "mdi-file-document",
        docx: "mdi-file-document",
        doc: "mdi-file-document",
        xls: "mdi-file-excel",
        xlsx: "mdi-file-excel",
        other: "mdi-file-outline",
      },
    };
  },
};
</script>
