import axios from "axios";

const actions = {
  multiuserClientsSwitch(context, value) {
    return axios
      .post("multiuser/clients/switch", value)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Multiuser erfolgreich geändert");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  multiuserCodeRedeem(context, value) {
    return axios
      .post("multiuser/invite/" + value.code + "/redeem", value)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Multiuser erfolgreich hinzugefügt");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  multiuserRevoke(context, value) {
    return axios
      .post("multiuser/revoke", value)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Multiuser erfolgreich entfernt");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  multiuserInvite(context, value) {
    return axios
      .post("multiuser/invite", value)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Multiuser erfolgreich eingeladen");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getMultiuserClients(context) {
    return axios
      .get("multiuser/clients")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  switchMultiuserClients(context, item) {
    return axios
      .post("multiuser/clients/switch", item)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
