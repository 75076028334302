<template>
  <div class="examination_assignments_table">
    <div class="d-flex justify-end">
      <v-btn
        color="secondary"
        :dark="true"
        :outlined="true"
        class="mt-4 mr-4"
        @click="newDocument()"
        >{{ $t("examination.new") }}
        <v-icon right :dark="true"> add </v-icon>
      </v-btn>
    </div>
    <v-data-table
      v-bind:headers="headers"
      v-bind:items="allExaminations"
      :options.sync="tableDataGetter.pagination"
      :footer-props="{
        itemsPerPageOptions: tableDataGetter.pagination.rowsPerPageItems,
        itemsPerPageText: $t('general.rowsPerPage'),
      }"
      @click:row="openAssigned($event._id)"
    >
      <template v-slot:item.examinationOptional="{ item }">
        <div v-if="item.optional">
          {{ $t("examination.tableHeader.proposalShort") }}
        </div>
        <div v-else>
          {{ $t("examination.tableHeader.obligatoryShort") }}
        </div>
      </template>
      <template v-slot:item.examinationAssigned="{ item }">
        <v-chip
          color="primary"
          outlined
          class="px-4"
          ripple
          v-on:click.stop="openAssigned(item._id)"
        >
          {{ item.assignedCount ?? 0 }}
        </v-chip>
      </template>
      <template v-slot:item.examinationOpen="{ item }">
        <v-chip
          color="red"
          outlined
          class="px-4"
          ripple
          v-on:click.stop="openOpenAssignments(item._id)"
        >
          {{ item.openAssignedCount ?? 0 }}
        </v-chip>
      </template>
      <template v-slot:item.examinationAction="{ item }">
        <examinationAction :item="item" @reloadData="reloadData()" />
      </template>
    </v-data-table>

    <examination-assignments-assigned-dialog
      ref="assigned"
      :examination-id="dialogAssigned.examinationId"
      @close="reloadData()"
    />
  </div>
</template>

<script>
import ExaminationAssignmentsAssignedDialog from "@/views/examination/examinationAssignmentsAssignedDialog.vue";
import { examinationAction } from "@/components/tableSlotComponents";
import { mapGetters } from "vuex";

export default {
  components: { ExaminationAssignmentsAssignedDialog, examinationAction },
  computed: {
    ...mapGetters([
      "allExaminations",
      "allExaminationsPending",
      "tableDataGetter",
    ]),
    headers() {
      return [
        {
          text: this.$t("examination.tableHeader.examination"),
          value: "name",
        },
        {
          text: this.$t("examination.tableHeader.optional"),
          value: "examinationOptional",
        },
        {
          text: this.$t("examination.tableHeader.assigned"),
          value: "examinationAssigned",
        },
        {
          text: this.$t("examination.tableHeader.open"),
          value: "examinationOpen",
        },
        {
          text: "",
          value: "examinationAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  data: () => ({
    dialogAssigned: {
      examinationId: undefined,
    },
  }),
  created() {
    this.$store.dispatch("getAllExaminations");
  },
  methods: {
    reloadData() {
      this.$store.dispatch("getAllExaminations");
    },
    _open(examinationId, tabNumber) {
      this.dialogAssigned.examinationId = examinationId;
      this.$nextTick(() => this.$refs.assigned.openDialog(tabNumber));
    },
    openAssigned(examinationId) {
      this._open(examinationId, 0);
    },
    openOpenAssignments(examinationId) {
      this._open(examinationId, 1);
    },
    newDocument() {
      this.$router.push({
        name: "examinationForm",
        params: { id: "1", mode: "new", data: 1 },
      });
    },
  },
};
</script>

<style>
div.examination_assignments_table th[role="columnheader"].sortable {
  white-space: nowrap;
}
div.examination_assignments_table tr {
  cursor: pointer;
}
</style>
