<template>
  <!--translate-->
  <v-dialog v-model="data.open" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(data.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="userForm"
            v-model="userForm.formData"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{
          $t("general.back")
        }}</v-btn>
        <v-btn color="primary" @click="saveForm(false)" data-cy="saveEvent">
          <div v-if="data.method === 'edit'">Passswort ändern</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function getDefaultData() {
  return {};
}

import formComponent from "../../_utils/formComponent/formComponent.vue";

export default {
  components: { formComponent },
  props: ["data"],
  data: () => ({
    userForm: {
      formData: getDefaultData(),
      form: [
        [
          //v-cols
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "pb-0 mt-0",
            id: 1,
            componentName: "password",
          },
        ],
      ],
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm(createNew) {
      // createNew : Boolean
      //check if new contact form should be opened after save
      //validate forms
      if (this.$refs.formComponent.validateAllFields()) {
        //edit item
        if (this.userForm.formData.password != "") {
          this.$store
            .dispatch("updateUser", this.userForm.formData)
            .then(() => {
              if (createNew) {
                //reset contact data to create new one
                this.resetData();
              } else {
                //close form
                this.closeform();
              }
            });
        }
      }
    },
    resetData() {
      //reset whole form data
      this.userForm.formData = getDefaultData();
      //reset form component form only after contact create
      if (this.data.method === "new") {
        this.$refs.formComponent.resetForm();
      }
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
    triggerEdit(item) {
      //set edit item from contactOverview Page trigger
      this.userForm.formData = item;
    },
  },
};
</script>
