<template>
  <div>
    <heading :helpInfo="sidebarCard">
      {{ $t("sidebar.incidentReports") }}
    </heading>
    <incidentReportTable />
  </div>
</template>

<script>
import incidentReportTable from "@/views/incidentReport/incidentReportTable.vue";
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
export default {
  name: "incidentReport",
  components: { incidentReportTable },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.incidentReport;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.incidentReport;
      }
      return sidebarTextDE.default.incidentReport;
    },
  },
};
</script>
