<template>
  <div class="row">
    <v-chip v-for="hAndP of firstThreeSentences" :key="hAndP">
      {{ hAndP }}
    </v-chip>
    <div v-if="hasMoreThanThreeHAndPSentences">
      (+{{ combinedHAndPSentences.length - 3 }})
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    combinedHAndPSentences() {
      return [...(this.item?.h ?? []), ...(this.item?.p ?? [])];
    },
    firstThreeSentences() {
      return this.combinedHAndPSentences.slice(0, 3);
    },
    hasMoreThanThreeHAndPSentences() {
      return this.combinedHAndPSentences.length > 3;
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  column-gap: 4px;
  align-items: center;
}
</style>
