<template>
  <div>
    <div class="d-none d-md-block">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-badge
            bordered
            overlap
            :color="item.proofs?.length > 0 ? 'primary' : 'error'"
            :content="computedItemProofLength"
            class="mr-6"
          >
            <v-icon
              medium
              v-on="on"
              @click.stop="openInstructionProofList()"
              data-cy="copy"
              >mdi-file-pdf</v-icon
            ></v-badge
          >
        </template>
        <span>{{ $t("instructions.instructionProof") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            medium
            class="mr-4"
            v-on="on"
            @click.stop="openInstructionProof()"
            data-cy="copy"
            >cloud_upload</v-icon
          >
        </template>
        <span>{{ $t("instructions.addProof") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            medium
            class="mr-2"
            v-on="on"
            @click.stop="downloadItem(item)"
            data-cy="copy"
            >download</v-icon
          >
        </template>
        <span>{{ $t("instructions.download") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            medium
            class="mr-2"
            v-on="on"
            @click.stop="openDeleteDialog(item)"
            data-cy="delete"
          >
            delete</v-icon
          >
        </template>
        <span>{{ $t("instructions.deleteInstruction") }}</span>
      </v-tooltip>
    </div>
    <div class="d-block d-md-none">
      <div class="text-center">
        <v-menu bottom :close-on-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>Untersuchungsnachweise</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Untersuchungsnachweis hinzufügen</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Herunterladen</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Löschen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
    <instructionUploadProof
      ref="instructionUploadProof"
      :data="instructionUploadProofData"
      :item="item"
      @reloadData="reloadDataFunc"
    />
    <instructionProofList
      ref="instructionProofList"
      :data="instructionProofListData"
      :item="item"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
import instructionUploadProof from "./instructionUploadProof.vue";
import instructionProofList from "./instructionProofList.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
    instructionUploadProof,
    instructionProofList,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "instructions.deleteTitle",
      description: "",
    },
    instructionUploadProofData: {
      open: false,
      title: "instructions.addProof",
      item: null,
      method: "new",
    },
    instructionProofListData: {
      open: false,
      title: "instructions.instructionProof",
      item: null,
      method: "new",
    },
  }),
  computed: {
    computedItemProofLength() {
      return String(this.item.proofs?.length ?? 0);
    },
  },
  methods: {
    createFormPath(item) {
      if (item) {
        return (
          "Unterweisungen/" +
          item.name +
          "_" +
          item.location +
          "." +
          item.filetype
        );
      }
    },
    async downloadItem(item) {
      //open url to download file
      window.open(await this.getDownloadItemLink(item), "_blank");
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description = this.$t("instructions.deleteTitleLong", {
        title: item.name,
      });
    },
    deleteItem(item) {
      this.$store.dispatch("deleteInstruction", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
    openInstructionProof() {
      this.instructionUploadProofData.open = true;
    },
    openInstructionProofList() {
      this.instructionProofListData.open = true;
    },
  },
};
</script>
