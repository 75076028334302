<template>
  <div>
    <v-autocomplete
      v-model="value.board"
      :items="creatBoardsDataMap"
      :loading="allBoardsPending"
      hide-no-data
      item-text="Description"
      item-value="id"
      :label="$t('view.board')"
      data-cy="board"
      :rules="requieredRule"
      dense
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  data: () => ({
    requieredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
  }),
  computed: {
    ...mapGetters(["allBoards", "allBoardsPending"]),
    creatBoardsDataMap() {
      return this.allBoards.map((board) => {
        const Description = board.name;
        const id = board._id;
        return Object.assign({}, board, {
          Description,
          id,
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch("getAllBoards");
  },
  methods: {},
};
</script>
