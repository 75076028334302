<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div>
          {{
            this.$t(
              "employeeGroup.instructionsDialog.assignedInstructions.title"
            )
          }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="instructionAssignments"
          :headers="headers"
          :items-per-page="10"
          :search="search"
          :options.sync="tableDataGetter.pagination"
          :footer-props="{
            itemsPerPageOptions: tableDataGetter.pagination.rowsPerPageItems,
            itemsPerPageText: $t('general.rowsPerPage'),
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                style="max-width: 370px"
                v-model="search"
                :label="$t('general.search')"
                id="search-all"
                single-line
                hide-details
                dense
                clearable
                @click:clear="handleClickClear()"
              ></v-text-field>
              <v-btn text icon color="secondary">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.instruction.name[0].name="{ item }">
            {{ item.instruction.name[0].name }}
          </template>
          <template v-slot:item.interval="{ item }">
            {{
              $tc(
                "employeeGroup.instructionsDialog.assignedInstructions.interval",
                item.interval,
                { count: item.interval }
              )
            }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-spacer />

      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" v-on:click="isOpen = false">{{
          $t("general.back")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "employeeGroupTableInstructionAssignmentsDialog",
  props: ["instructionAssignments"],
  computed: {
    ...mapGetters(["tableDataGetter"]),
  },
  data() {
    return {
      search: "",
      isOpen: false,
      headers: [
        {
          text: this.$t("employeeGroup.instructionsDialog.name"),
          value: "instruction.name[0].name",
          align: "left",
        },
        {
          text: this.$t("employeeGroup.instructionsDialog.interval"),
          value: "interval",
          align: "left",
        },
      ],
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
  },
};
</script>
