<template>
  <div class="ma-4 mt-8">
    <scrumbar @openAddBoard="openAddBoard" />
    <div class="pt-2 pb-14 scrum-row mx-2">
      <div v-bind:key="item.id" v-for="item in boardList" class="scrum-column">
        <v-card outlined color="grey lighten-4" class="fill-height ma-0 pa-0">
          <v-card-title>
            <div class="d-flex justify-space-between">
              <h3>{{ handleName(item.name) }}</h3>
            </div>
            <div class="flex-grow-1"></div>
            <div class="mr-1" v-if="item.tickets.length !== 0">
              <b> {{ item.tickets.length }}</b>
            </div>
          </v-card-title>
          <v-card-text class="column-content">
            <!-- add new ticket -->
            <v-btn block dense color="primary" @click="createTicket(item)" class="add-button">
              <v-icon left>add</v-icon>
              {{ $t("tickets.scrum.addTicket") }}
            </v-btn>
            <draggable
              class="draggable-column"
              :list="item.tickets"
              ghost-class="ghost"
              group="tickets"
              :emptyInsertThreshold="-400"
              @change="checkTicketMove($event, item)"
            >
              <transition-group
                name="flip-list"
                class="d-inline-block fill-width fill-height"
              >
                <card
                  v-for="element in item.tickets"
                  v-bind:key="element._id"
                  v-bind:item="element"
                  @reloadData="reloadDataFunc"
                  v-on:click="openCard(element)"
                />
              </transition-group>
            </draggable>
          </v-card-text>
        </v-card>
      </div>
      <div class="scrum-column">
        <v-card
          outlined
          color="grey lighten-4"
          class="d-flex align-center fill-height"
          v-ripple
          v-on:click="openAddBoard"
        >
          <v-btn color="primary" text style="width: 80%" class="mx-auto">
            <v-icon left>add</v-icon>
            {{ $t("tickets.scrum.addColumn") }}
          </v-btn>
        </v-card>
      </div>
    </div>
    <ticketForm
      ref="ticketForm"
      :data="ticketForm"
      @reloadData="reloadDataFunc"
    />
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="deleteDialog.item"
      :deleteDialog="deleteDialog"
    />
    <ticketView
      ref="ticketView"
      @reloadData="reloadDataFunc"
      @editTicket="editTicket"
      @openDeleteDialog="openDeleteDialog"
      @sendEmailTicket="sendEmailTicket"
      :data="ticketView"
    />
    <emailForm ref="emailForm" :data="emailForm" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import card from "./card.vue";
import scrumbar from "./scrumbar.vue";
import ticketForm from "./form/ticketForm.vue";
import ticketView from "./ticketView/ticketView.vue";
import emailForm from "./form/emailForm.vue";
import deleteDialog from "../../../components/_utils/dataTableHelpers/actions/deleteDialog.vue";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Scrumboard",
  },
  components: {
    draggable,
    card,
    scrumbar,
    ticketForm,
    ticketView,
    deleteDialog,
    emailForm,
  },
  computed: {
    ...mapGetters(["populatedBoardsPending"]),
  },
  mounted() {
    this.init();
  },
  watch: {
    "$route.params.mode": function () {
      this.init();
    },
  },
  data() {
    return {
      boardList: [],
      deleteDialog: {
        open: false,
        item: null,
        title: this.$t("tickets.scrum.deleteTicket"),
        description: "",
      },
      ticketForm: {
        open: false,
        title: this.$t("tickets.scrum.createTicket"),
        item: null,
        method: "new",
      },
      emailForm: {
        open: false,
        title: this.$t("tickets.scrum.emailTicket"),
        item: null,
      },
      ticketView: {
        open: false,
        item: null,
      },
    };
  },
  methods: {
    init() {
      //check if user id must be sent
      if (this.$route.params.mode === "user") {
        //user tickets
        this.$store
          .dispatch(
            "getBoardByUser",
            JSON.parse(localStorage.getItem("userData"))._id
          )
          .then((r) => {
            this.boardList = r;
          });
      } else {
        //all tickets
        this.$store.dispatch("getBoard").then((r) => {
          this.boardList = r;
        });
      }
    },
    reloadDataFunc() {
      this.init();
      //close dialog
      this.ticketView = {
        open: false,
        item: null,
      };
    },
    checkTicketMove(event, item) {
      //react on added event -> moved between boards
      let added = event.added;
      if (added) {
        //update board id
        added.element.board = item._id;
        this.$store.dispatch("updateTicket", added.element);
      }
    },
    createTicket(row) {
      this.ticketForm = {
        open: true,
        title: this.$t("tickets.scrum.createTicket"),
        item: null,
        method: "new",
      };
      //preset data
      if (row) {
        this.$refs.ticketForm.presetData({ board: row._id });
      }
    },
    editTicket(item) {
      this.ticketForm = {
        open: true,
        title: this.$t("tickets.scrum.editTicket"),
        item: null,
        method: "edit",
      };
      //clone table item to decouple reference from table
      this.$refs.ticketForm.triggerEdit(Object.assign({}, item));
    },
    sendEmailTicket(item) {
      this.emailForm = {
        open: true,
        title: this.$t("tickets.scrum.emailTicket"),
        item: item,
      };
    },
    openCard(item) {
      //get item populated
      this.$store.dispatch("getSingleTicket", item._id).then((r) => {
        //populate board info if it is final
        this.$store
          .dispatch("getSettingData", "standardClosedBoard")
          .then((item) => {
            r.board.final = item.value === r.board.id;
            this.ticketView = {
              open: true,
              item: r,
            };
          });
      });
    },
    openDeleteDialog(item) {
      //close ticket dialog
      this.ticketView.open = false;
      //open delete dialog
      this.deleteDialog.open = true;
      this.deleteDialog.item = item;
      this.deleteDialog.description = this.$t("tickets.scrum.deleteTitle", {
        title: item.title,
      });
    },
    deleteItem(item) {
      this.$store.dispatch("deleteTicket", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    openAddBoard() {
      this.$router.push({ name: "boardSettings" });
    },
    handleName(name) {
      if (localStorage.getItem("locale") !== "en") return name;

      switch (name) {
        case "Erledigt":
          return "Done";
        case "Offen":
          return "Open";
        case "In Bearbeitung":
          return "In process";
        default:
          return name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid rgba(black, 10%);

.scrum-row {
  display: flex;
  flex-direction: row;
  gap: 15px;

  position: absolute;
  overflow-x: scroll;
  overflow-y: hidden;
  left: 5px;
  right: 5px;

  .scrum-column {
    border: $border;
    min-width: 400px;

    margin-right: 15px;

    position: relative;

    .column-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      .add-button {
        flex: 0 1 auto;
      }

      .draggable-column {
        flex: 1 1 auto;
        max-height: 1000px;
        inset: 0;
        top: 128px;

        padding: 16px 16px 24px 0;

        border-top: $border;

        overflow-y: auto;
      }

      .fill-width {
        width: 100%;
      }
    }
  }
}

.sortable {
  cursor: move;
}

.sortable-drag {
  opacity: 1;
}

.flip-list-move {
  transition: transform 0.6s;
}

.ghost {
  border-left: 1px solid #43a047;
  opacity: 0.7;
}

.list-group {
  min-height: 100%;
}
</style>
