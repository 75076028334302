<template>
  <div>
    <v-autocomplete
      v-model="value.instruction"
      :items="managedInstructionOrderDataMap"
      :loading="allManagedInstructionsPending"
      hide-no-data
      item-text="Description"
      item-value="id"
      :label="$t('instructions.instruction')"
      data-cy="instruction"
      dense
    >
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  components: {},
  data: () => ({ standardOpenManagedInstruction: { value: "" } }),
  computed: {
    ...mapGetters(["allManagedInstructions", "allManagedInstructionsPending"]),
    managedInstructionOrderDataMap() {
      return this.allManagedInstructions.map((managedInstruction) => {
        const Description = managedInstruction.name[0].name;
        const id = managedInstruction._id;
        return Object.assign({}, managedInstruction, {
          Description,
          id,
        });
      });
    },
  },
  created() {
    //get getAllManagedInstructions
    this.$store.dispatch("getAllManagedInstructions");
  },
  methods: {},
};
</script>
