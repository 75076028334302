<template>
  <div>
    <div v-if="item.firstAid">{{ $t("medkitlogs.yes") }}</div>
    <div v-else>{{ $t("medkitlogs.no") }}</div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  methods: {},
};
</script>
