<template>
  <div>
    <singleDateForm
      v-model="value.dueDate"
      :label="$t('managedInstructions.managedInstructionsDueTo')"
      data-cy="dueDate"
      prependIcon="event"
      :rules="[]"
    />
  </div>
</template>
<script>
import singleDateForm from "../../_utils/general/singleDateForm.vue";
export default {
  props: ["value"],
  components: { singleDateForm },
  data: () => ({}),
  methods: {},
};
</script>
