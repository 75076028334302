import axios from "axios";

const actions = {
  getAllManagedInstructions(context) {
    context.commit("SET_ALL_MANAGEDINSTRUCTIONS");
    return axios
      .get("managedInstruction?analytics=1")
      .then(function (r) {
        context.commit("SET_ALL_MANAGEDINSTRUCTIONS", r.data.data);
        return r.data.data;
      })
      .catch(function (e) {
        context.commit("SET_ALL_MANAGEDINSTRUCTIONS", e);
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
