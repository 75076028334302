import axios from "axios";

const actions = {
  createInspection(context, value) {
    return axios
      .post("inspection", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Begehung " + r.data.title + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteInspection(context, val) {
    return axios
      .delete("inspection/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Begehung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateInspection(context, value) {
    return axios
      .put("inspection/" + value.id, value)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleInspection(context, id) {
    return axios
      .get("inspection/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getPdfInspection(context, id) {
    return axios
      .get("inspection/" + id + "/pdf")
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  addProof(context, value) {
    return axios
      .post("inspection/" + value.id + "/proof", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Begehungsnachweis erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  uploadAttachmentToTicketFromTask(context, value) {
    return axios
        .post(`inspection/${value.id}/ticket/${value.ticketId}/attachment`, value)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
