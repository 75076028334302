<template>
  <v-textarea
    :rules="config.rules"
    :label="config.label"
    :data-cy="config.dataCy"
    :rows="config.rows"
    v-model="inputVal[config.vModel]"
    dense
  ></v-textarea>
</template>
<script>
export default {
  props: ["config", "value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
