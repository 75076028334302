import { getBillwerkApiUrl } from "../../api.js";

const getters = {
  portalData: (state) => state.portalData.data[0],
  portalDataLoading: (state) => state.portalData.status.pending,
  portalDataOnlyPlugins: (state) => {
    if (state.portalData.data[0]) {
      const components = state.portalData.data[0].Products.Components;
      let plugins = [];
      components.forEach((item) => {
        //check if external id starts with plugin
        if (item.ExternalId.substring(0, 3) === "pl-") {
          plugins.push(item);
        }
      });
      return plugins;
    }
  },
  portalDataOnlyLicenses: (state) => {
    if (state.portalData.data[0]) {
      const components = state.portalData.data[0].Products.Components;
      let plugins = [];
      components.forEach((item) => {
        //check if external id starts with license
        if (item.ExternalId.substring(0, 7) === "license") {
          item.formattedDescription = JSON.parse(item.Description._c);
          plugins.push(item);
        }
      });
      return plugins;
    }
  },
  billwerkToken: (state) => state.demoToken,
  billwerkApiUrl: () => getBillwerkApiUrl(),
};

export default getters;
