<template>
  <div>
    <heading :helpInfo="sidebarCard">{{ $t("sidebar.files") }}</heading>
    <file-browser :endpoints="endpoints" :axiosConfig="axiosConfig" />
  </div>
</template>

<script>
import * as sidebarTextDE from "../../components/api/content/sidebarText-de.json";
import * as sidebarTextEN from "../../components/api/content/sidebarText-en.json";
import FileBrowser from "s3-vuetify-file-browser";
import { getTokenFromLocalStorage, getBackendServer } from "../../api.js";
//import axios from "axios";
export default {
  components: {
    FileBrowser,
  },
  computed: {
    sidebarCard() {
      if (this.$i18n.locale == "de") {
        return sidebarTextDE.default.files;
      } else if (this.$i18n.locale == "en") {
        return sidebarTextEN.default.files;
      }
      return sidebarTextDE.default.files;
    },
  },
  data: () => ({
    endpoints: {
      list: {
        url: "document/url",
        method: "get",
      },
      upload: {
        url: "document/uploadurl",
        method: "put",
      },
      download: {
        url: "document/downloadurl",
        method: "get",
      },
      delete: {
        url: "document",
        method: "delete",
      },
    },
    axiosConfig: {
      baseURL: getBackendServer(),
      headers: {
        "Content-Type": "application/json",
        Authorization: getTokenFromLocalStorage(),
      },
    },
  }),
};
</script>
<style>
.container.fill-height {
  align-items: center;
  display: block;
}
</style>
