<template>
  <!--translate-->
  <div>
    <div v-if="item.type === 'ticket'">Aufgabe</div>
    <div v-else>Sonstiges</div>
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>
