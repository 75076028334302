<template>
  <div>
    <v-img class="my-4 rounded" height="128px" width="128px" :src="item.previewImage"></v-img>
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>
