import StoreUtil from "../store.util.js";

const mutations = {
  SET_PORTAL_DATA(state, payload) {
    state.portalData = StoreUtil.updateState(
      state.portalData,
      payload
    );
  },
};

export default mutations;
