<template>
  <!--translate-->
  <v-dialog v-model="riskNohlDialog.open" max-width="900" persistent>
    <v-card>
      <v-card-title class="headline"
        >Gefährdungsbeurteilung Risikomatrix nach Nohl</v-card-title
      >
      <br />
      <v-card-text>
        <div class="subtitle-1 text-center mb-8">Mögliche Schadensschwere</div>
        <v-row>
          <v-col cols="12" sm="1" md="1"
            ><div
              class="subtitle-1"
              style="
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                margin-top: 155px;
              "
            >
              Warscheinlichkeit
            </div></v-col
          >
          <v-col cols="12" sm="11" md="11">
            <v-row>
              <v-col cols="12" sm="2" md="2"
                ><div class="subtitle-1">Risikomatrix (nach Nohl)</div></v-col
              >
              <v-col cols="12" sm="2" md="2">
                <b>Leichte Verletzungen oder Erkrankungen</b></v-col
              >
              <v-col cols="12" sm="2" md="2">
                <b> Mittelschwere Verletzung oder Erkrankungen</b>
              </v-col>
              <v-col cols="12" sm="2" md="2"
                ><b>Schwere Verletzung oder Erkrankungen</b></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><b>Möglicher Tod, Katastrophe</b></v-col
              >
              <v-col cols="12" sm="1" md="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" md="2"><b>sehr gering</b></v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn block color="success" @click="setRisk('1')"
                  >1</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="success" @click="setRisk('2')"
                  >2</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="warning" @click="setRisk('3')"
                  >3</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="warning" @click="setRisk('4')"
                  >4</v-btn
                ></v-col
              ><v-col cols="12" sm="1" md="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" md="2"><b>gering</b></v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn block color="success" @click="setRisk('2')"
                  >2</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="warning" @click="setRisk('3')"
                  >3</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="warning" @click="setRisk('4')"
                  >4</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="error" @click="setRisk('5')"
                  >5</v-btn
                ></v-col
              ><v-col cols="12" sm="1" md="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" md="2"><b>mittel</b></v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn block color="warning" @click="setRisk('3')"
                  >3</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="warning" @click="setRisk('4')"
                  >4</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="error" @click="setRisk('5')"
                  >5</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="error" @click="setRisk('6')"
                  >6</v-btn
                ></v-col
              ><v-col cols="12" sm="1" md="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" md="2"><b>hoch</b></v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn block color="warning" @click="setRisk('4')"
                  >4</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="error" @click="setRisk('5')"
                  >5</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="error" @click="setRisk('6')"
                  >6</v-btn
                ></v-col
              >
              <v-col cols="12" sm="2" md="2"
                ><v-btn block color="error" @click="setRisk('7')"
                  >7</v-btn
                ></v-col
              ><v-col cols="12" sm="1" md="1"></v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="body-1 text-center mt-8">
          Aktuelle Risikobewertung: <b>{{ inputVal }}</b>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" outlined @click="riskNohlDialog.open = false">{{
          $t("general.back")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'risk-nohl-dialog',
  props: ["riskNohlDialog", "value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    setRisk(risk) {
      this.inputVal = risk;
      //close dialog
      this.riskNohlDialog.open = false;
    },
  },
};
</script>
