<template>
  <div>
    <v-radio-group v-model="value.didStartWorkAgain">
      <template v-slot:label>
        {{ $t("incidentReports.incidentReportFormLong.didStartWorkAgain.label") }}
      </template>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.didStartWorkAgain.no')"
        value="no"
      ></v-radio>
      <v-radio
        :label="$t('incidentReports.incidentReportFormLong.didStartWorkAgain.laterAt')"
        value="laterAt"
      ></v-radio>
      <div v-if="value.didStartWorkAgain === 'laterAt'" class="ml-7">
        <v-menu
          v-model="datePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          nudge-width="200"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              label="Datum"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="value.startedAgainDate"
            locale="de"
            @input="closeDatePicker"
          ></v-date-picker>
        </v-menu>
      </div>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({
    datePickerMenu: false,
  }),
  computed: {
    formattedDate() {
      return this.value.startedAgainDate
        ? new Date(this.value.startedAgainDate).toLocaleDateString("de-DE")
        : "";
    },
  },
  methods: {
    closeDatePicker() {
      this.datePickerMenu = false;
    },
  },
};
</script>
