<template>
  <div>
    <tableComponent
      class="pa-5 pl-0"
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../../components/api/api";

export default {
  data: () => ({
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupAssignedExaminations",
          componentName: "employeeGroupAssignedExaminations",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeGroupActionExamination",
          componentName: "employeeGroupActionExamination",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "employeeGroup",
      uriPartAppend: "",
      populate: ["members", "examinationAssignments.examination"],
      sort: "-createdAt",
      editFormPushName: "examinationEmployeeGroupForm",
    },
  }),
  computed: {
    tableSettings() {
      return {
        title: "",
        showSelect: false,
        showHeaderToolbar: true,
        headerButtons: [],
        multipleSelectButtons: [],
      };
    },
    headers() {
      return [
        {
          text: this.$t("employeeGroup.tableHeader.name"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("examination.assignedExaminationsTable.tableHeader.assignedExaminations"),
          value: "employeeGroupAssignedExaminations",
        },
        {
          text: "",
          value: "employeeGroupActionExamination",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
};
</script>
