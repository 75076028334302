<template>
  <div>{{ displayDate }} Uhr</div>
</template>
<script>
import { dateUtil } from "../../api/api";
export default {
  props: ["item"],
  computed: {
    displayDate() {
      if (this.item.incidentReportType === "long") {
        return this.formatDate(
          this.item.longIncidentReport?.accidentDetails.incidentDate
        );
      } else {
        return this.formatDate(this.item.date);
      }
    },
  },
  methods: {
    dateUtil,
    formatDate(date) {
      return dateUtil(new Date(date), true, "FEHLER");
    },
  },
};
</script>
