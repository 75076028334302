<template>
  <div>
    <v-tooltip bottom v-if="item?.incidentReportType === 'long'">
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="generateIncidentReport(item)"
          data-cy="copy"
          >picture_as_pdf</v-icon
        >
      </template>
      <span>{{ $t("incidentReports.generateIncidentReport") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="copyItem(item)"
          data-cy="copy"
          >content_copy</v-icon
        >
      </template>
      <span>{{ $t("incidentReports.copyIncidentReports") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="editItem(item)"
          data-cy="edit"
        >
          edit</v-icon
        >
      </template>
      <span>{{ $t("incidentReports.editIncidentReports") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          medium
          class="mr-2"
          v-on="on"
          @click.stop="openDeleteDialog(item)"
          data-cy="delete"
        >
          delete</v-icon
        >
      </template>
      <span>{{ $t("incidentReports.deleteIncidentReports") }}</span>
    </v-tooltip>
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="item"
      :deleteDialog="deleteDialog"
    />
  </div>
</template>

<script>
import deleteDialog from "../../_utils/dataTableHelpers/actions/deleteDialog.vue";
export default {
  props: ["item"],
  components: {
    deleteDialog,
  },
  data: () => ({
    deleteDialog: {
      open: false,
      title: "incidentReports.deleteIncidentReports",
      description: "",
    },
  }),
  methods: {
    getNavigationName(item) {
      if (item.incidentReportType === "long") {
        return "incidentReportFormLong";
      } else {
        return "incidentReportForm";
      }
    },
    editItem(item) {
      this.$router.push({
        name: this.getNavigationName(item),
        params: { id: item.id, mode: "edit" },
      });
    },
    copyItem(item) {
      this.$router.push({
        name: this.getNavigationName(item),
        params: { id: item.id, mode: "copy" },
      });
    },
    openDeleteDialog(item) {
      this.deleteDialog.open = true;
      this.deleteDialog.description = this.$t(
        "incidentReports.deleteTitleLong",
        {
          title: item.name,
        }
      );
    },
    deleteItem(item) {
      this.$store.dispatch("deleteIncidentReport", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    reloadDataFunc() {
      this.$emit("reloadData");
    },
    generateIncidentReport(item) {
      this.$store
        .dispatch(
          "createIncidentReport2Pdf",
          item.id,
        )
        .then((r) => {
          const base64 = "data:application/pdf;base64," + r.base64;
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<head><title>" +
              "Unfallmeldung" +
              "</title></head><iframe width='100%' height='100%' src='" +
              encodeURI(base64) +
              "'></iframe>"
          );
        });
    },
  },
};
</script>
