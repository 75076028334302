<template>
  <!--translate-->
  <div>
    <v-card :loading="portalDataLoading" min-height="250px" max-height="400px">
      <v-card-title>SafetyPlanner Paket</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="portalData">
        <v-container fluid>
          <v-row>
            <div class="float-left mr-5">
              <div>Aktuelles Paket</div>
              <p class="text-h6 text--primary">
                {{
                  portalData.CurrentPlan.PlanName
                    ? portalData.CurrentPlan.PlanName
                    : ""
                }}
                {{
                  portalData.Contract.LifecycleStatus == "InTrial"
                    ? "(Testzeitraum)"
                    : ""
                }}
              </p>
            </div>
            <div class="float-left mr-5">
              <div>Paketvariante</div>
              <p class="text-h6 text--primary">
                {{ portalData.CurrentPlan.PlanName ? "fehlt" : "" }}
              </p>
            </div>
          </v-row>
        </v-container>
        <div>
          <v-container fluid>
            <v-row>
              <div class="float-left mr-5">
                <div>Nächste Abrechnung</div>
                <p class="text-h6 text--primary">
                  {{
                    portalData.Contract.NextBillingDate
                      ? formatDate(portalData.Contract.NextBillingDate)
                      : ""
                  }}
                </p>
              </div>
              <div v-if="portalData.CurrentPlan.EndDate">
                <div>Paketende</div>
                <p class="text-h6 text--primary">
                  {{
                    portalData.CurrentPlan.EndDate
                      ? formatDate(portalData.CurrentPlan.EndDate)
                      : ""
                  }}
                </p>
              </div>
            </v-row>
          </v-container>
        </div>
        <div>
          <v-container fluid>
            <v-row>
              <div class="float-left mr-5">
                <div>Rechtliches</div>
                <p class="text-h6 text--primary">
                  <a
                    href="https://www.safetyplanner.de/impressum.html"
                    target="_blank"
                    >Impressum</a
                  ><br />
                  <a
                    href="https://www.safetyplanner.de/agb.html"
                    target="_blank"
                    >AGB's</a
                  >
                </p>
              </div>
            </v-row>
          </v-container>
        </div>
      </v-card-text>
      <v-card-actions
        ><v-spacer></v-spacer>
        <!--v-btn color="primary" @click="openChangePaymentData()"
          >Zahlungsmethode ändern</v-btn
        >
        <v-btn color="primary" @click="openChangePlanForm()"
          >Paket ändern / upgrade</v-btn
        -->
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { dateUtil } from "../../../../api.js";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["portalData", "portalDataLoading"]),
  },

  methods: {
    openChangePlanForm() {
      this.$router.push({
        name: "changePlan",
        params: {
          portalData: JSON.stringify(this.portalData),
          step: 1,
          selected: {},
        },
      });
    },
    formatDate(date) {
      return dateUtil(new Date(date), false, "FEHLER");
    },
    openChangePaymentData() {
      this.$router.push({
        name: "upgradePayment",
      });
    },
  },
};
</script>
