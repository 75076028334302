<template>
  <v-dialog v-model="surveyStartDialog.open" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline"
        >{{ $t("general.title") }}</v-card-title
      >
      <v-card-text
        >{{ $t("general.description") }}</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="surveyStartDialog.open = false"
          >{{ $t("general.start") }}</v-btn
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>
<script>
export default {
  props: ["surveyStartDialog"],
  data() {
    return {};
  },
  methods: {},
};
</script>
