import axios from "axios";

const actions = {
  createManagedInstructionAssignment(context, value) {
    return axios
      .post("managedInstructionAssignment", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unterweisung erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  removeEmployeeFromAssignment(context, val) {
    return axios
      .put(`managedInstructionAssignment/${val._id}/employee/remove`, {employee: val.assignments.employee, _id: val._id})
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Zuweisung des Mitarbeiters erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
  deleteManagedInstructionAssignment(context, val) {
    return axios
      .delete("managedInstructionAssignment/" + val._id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Unterweisung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateManagedInstructionAssignment(context, value) {
    return axios
      .put("managedInstructionAssignment/" + value.id, value)
      .then(function (r) {
        context.commit("CREATE_SNACKBAR", "Unterweisung erfolgreich verändert");
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleManagedInstructionAssignment(context, id) {
    return axios
      .get("managedInstructionAssignment/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleManagedInstructionAssignmentPublic(context, item) {
    return axios
      .get(
        "managedInstructionAssignment/employee/" +
          item.managedInstructionAssignmentId +
          "/" +
          item.assignmentId +
          "/" +
          item.employeeId
      )
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
