<template>
  <div>{{ unableToWorkDays }} {{ $t("incidentReports.days") }}</div>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    unableToWorkDays() {
      if (this.item.incidentReportType === "long") {
        // Zwei Datumswerte
        let startDate = new Date(
          this.item.longIncidentReport?.accidentDetails?.incidentDate
        ); // Unfallzeitpunkt
        const endDate = new Date(
          this.item.longIncidentReport?.workTimeAndAbsence?.startedAgainDate
        ); // Enddatum

        if (
          this.item.longIncidentReport?.workTimeAndAbsence?.didStopWork ===
          "laterAt"
        ) {
          const stoppedWorkDate = new Date(
            this.item.longIncidentReport?.workTimeAndAbsence?.stoppedLaterDate
          ); // Arbeit später eingestellt
          startDate = stoppedWorkDate;
        }

        if (isNaN(startDate) || isNaN(endDate)) {
          return 0;
        }

        // Zeitstempel-Differenz in Millisekunden
        const diffInMilliseconds = endDate - startDate;

        // Umrechnung von Millisekunden in Tage
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

        return Math.ceil(diffInDays);
      } else {
        return this.item.unableToWorkDays;
      }
    },
  },
};
</script>
