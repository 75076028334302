<template>
  <v-card class="sortable mb-4 scrum-card"
          flat
          outlined
          v-bind:ripple="false"
          v-bind:data-priority="item.priority"
          v-on:click="$emit('click')/* Sadly only working this way :/ */"
  >
    <v-card-text class="pb-0 pt-1">
      <div class="d-flex">
        <p class="font-weight-bold mt-3">
          {{ item.title }}
        </p>
        <div class="flex-grow-1"></div>
      </div>
    </v-card-text>
    <v-card-actions
        class="pt-2 d-flex"
        v-if="item.description.length !== 0 || item.users.length !== 0 || item.comments.length !== 0 || item.dueDate != null"
    >
      <div class="ml-2 d-flex justify-space-between v-picker--full-width mr-3">
        <div>
          <v-icon v-if="item.description.length > 0" class="mr-2" disabled
          >notes
          </v-icon>
          <v-badge
              class="mr-4"
              overlap
              v-if="item.attachments.length > 0"
              color="primary"
              :content="item.attachments.length"
          >
            <v-icon disabled>attach_file</v-icon>
          </v-badge>
          <v-badge
              class="mr-4"
              overlap
              v-if="item.users.length > 0"
              color="primary"
              :content="item.users.length"
          >
            <v-icon disabled>people</v-icon>
          </v-badge>
          <v-badge
              class="mr-4"
              overlap
              v-if="item.comments.length > 0"
              color="primary"
              :content="item.comments.length"
          >
            <v-icon disabled>question_answer</v-icon>
          </v-badge>
        </div>
        <!-- remove element if it is final row -->
        <div class="d-flex justify-space-between align-center" v-if="!finalBoard">
        <span
            v-if="item.dueDate != null"
            class="caption text--disabled font-weight-medium"
        >
          <v-icon :style="isWeekPrior ? 'color: #b71c1c' : ''" class="mr-1 pb-1">
            alarm
          </v-icon>
          <b :style="isWeekPrior ? 'color: #b71c1c' : ''">
            {{ dateUtil(new Date(item.dueDate), false, "FEHLER") }}
          </b>
        </span>
        </div>
      </div>
      <div class="flex-grow-1"></div>
    </v-card-actions>
  </v-card>
</template>
<script>
import {dateUtil} from "../../../components/api/api";

export default {
  props: ["item"],
  components: {},
  data() {
    return {
      finalBoard: true,
    };
  },
  computed: {
    isWeekPrior() {
      if (this.item.dueDate) {
        //return true;
        //check if date is one week prior
        return new Date(this.item.dueDate) < new Date(Date.now() + 604800000);
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store
        .dispatch("getSettingData", "standardClosedBoard")
        .then((item) => {
          if (item.value === this.item.board) {
            //final board detected
            this.finalBoard = true;
          } else {
            this.finalBoard = false;
          }
        });
  },
  methods: {
    dateUtil,
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
<style scoped>
.scrum-card {
  border-right: 2px solid;
}
.scrum-card[data-priority="1"] {
  border-right-color: green;
}
.scrum-card[data-priority="2"] {
  border-right-color: orange;
}
.scrum-card[data-priority="3"] {
  border-right-color: red;
}
.scrum-card[data-priority="4"] {
  border-right: thick double red;
}
</style>