<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-tabs v-model="currentTab">
      <v-tab v-for="item in items" :key="item.text">
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="(item, index) in items" :key="item.text">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div>
              {{ title }}
            </div>
          </v-card-title>
          <v-banner>{{ item.bannerMessage }}</v-banner>
          <v-card-text v-if="index === currentTab">
            <tableComponent
              ref="tableComponent"
              :key="reloadTrigger"
              :headers="headers"
              :tableSettings="tableSettings"
              :tableData="tableData"
              @dataChanged="data = $event"
            />
          </v-card-text>

          <v-spacer />

          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" v-on:click="closeDialog()">{{
              $t("general.back")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
// import axios from "axios";
import { getUniqueId } from "../../components/api/api";

export default {
  props: {
    examinationId: {
      type: () => String | undefined,
      required: true,
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.reloadTrigger++;
      }
    },
  },
  computed: {
    existsEmployeeWithNotCompletedAssignment() {
      if (!this.data) {
        return false;
      }

      return this.data.some((item) => !this.isCompleted(item));
    },
    noDataText() {
      return this.currentTab === 0
        ? this.$t(
            "examination.assignedDialog.assignedAssignmentsTab.noDataText"
          )
        : this.$t("examination.assignedDialog.openAssignmentsTab.noDataText");
    },
    headers() {
      const headerButtons = [
        {
          text: this.$t("examination.assignedDialog.headerButtons.name"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("examination.assignedDialog.headerButtons.mail"),
          value: "email",
          align: "left",
        },
      ];
      return headerButtons;
    },
    uriPartPrepend() {
      return this.currentTab !== 0
        ? `/examination/${this.examinationId}/openassignments`
        : `/examination/${this.examinationId}/assigned`;
    },
    tableData() {
      const tableComponentSlots = [
        {
          id: getUniqueId(),
          slotName: "item.name",
          componentName: "examinationAssignmentAssignedDialogName",
        },
        {
          id: getUniqueId(),
          slotName: "item.email",
          componentName: "examinationAssignmentAssignedDialogEmail",
        },
      ];
      return {
        tableComponentSlots,
        itemKey: "_id",
        uriPartPrepend: this.uriPartPrepend,
        uriPartAppend: "",
        populate: [""],
        sort: "-createdAt",
      };
    },
    title() {
      if (this.currentTab === 0) {
        return this.$t(
          "examination.assignedDialog.assignedAssignmentsTab.title"
        );
      } else {
        return this.$t("examination.assignedDialog.openAssignmentsTab.title");
      }
    },
    items() {
      return [
        {
          text: this.$t(
            "examination.assignedDialog.assignedAssignmentsTab.title"
          ),
          bannerMessage: this.$t(
            "examination.assignedDialog.assignedAssignmentsTab.bannerMessage"
          ),
        },
        {
          text: this.$t("examination.assignedDialog.openAssignmentsTab.title"),
          bannerMessage: this.$t(
            "examination.assignedDialog.openAssignmentsTab.bannerMessage"
          ),
        },
      ];
    },
  },
  data: () => ({
    isOpen: false,
    data: undefined,
    remindEmployeeProgress: undefined,
    currentTab: 0,
    reloadTrigger: 0,
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      multipleSelectButtons: [],
      headerButtons: [],
    },
  }),
  methods: {
    isCompleted(item) {
      return item.assignments?.completedAt ?? false;
    },
    openDialog(tab) {
      this.isOpen = true;
      this.currentTab = tab ?? 0;
    },
    closeDialog() {
      this.isOpen = false;
      this.$emit("close");
    },
  },
};
</script>
