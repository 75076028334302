<template>
  <v-select
    :rules="config.rules"
    :items="config.items"
    item-text="name"
    item-value="value"
    :label="config.label"
    :data-cy="config.dataCy"
    v-model="inputVal[config.vModel]"
    dense
  ></v-select>
</template>
<script>
export default {
  props: ["config", "value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
