<template>
  <!--translate-->
  <v-card elevation="2" :loading="portalDataLoading">
    <v-card-title> Meine Kundendaten</v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="portalData">
      <v-container fluid>
        <v-row>
          <div class="float-left mr-5">
            <div>Rechnungsadresse</div>
            <p class="text-h6 text--primary">
              {{ portalData.Customer.CompanyName }}<br />
              {{
                portalData.Customer.FirstName
                  ? portalData.Customer.FirstName
                  : ""
              }}
              {{
                portalData.Customer.LastName
                  ? portalData.Customer.LastName
                  : ""
              }}<br
                v-if="
                  portalData.Customer.LastName || portalData.Customer.FirstName
                "
              />
            </p>
            <p class="text-h6 text--primary" v-if="portalData.Customer.Address">
              {{ portalData.Customer.Address.Street }}
              {{ portalData.Customer.Address.HouseNumber }}<br />
              {{
                portalData.Customer.Address.Country
                  ? portalData.Customer.Address.Country + "-"
                  : ""
              }}{{ portalData.Customer.Address.PostalCode }}
              {{ portalData.Customer.Address.City }}
            </p>
          </div>
        </v-row>
      </v-container>
      <div>
        <v-container fluid>
          <v-row>
            <div class="float-left mr-5">
              <div>Email</div>
              <p class="text-h6 text--primary">
                {{
                  portalData.Customer.EmailAddress
                    ? portalData.Customer.EmailAddress
                    : ""
                }}
              </p>
            </div>
            <div>
              <div>Rechnungsversand per Email</div>
              <p class="text-h6 text--primary">
                <v-icon
                  v-if="portalData.Customer.DefaultBearerMedium === 'Email'"
                  medium
                  color="success"
                  >mdi-check</v-icon
                >
                <v-icon v-else medium color="error">clear</v-icon>
              </p>
            </div>
          </v-row>
        </v-container>
        <v-container fluid>
          <v-row>
            <div
              class="float-left mr-5"
              v-if="portalData.Contract.PaymentBearer"
            >
              <div v-if="portalData.Contract">Zahlungsmethode</div>
              <p class="text-sm-h6 text--primary">
                <!-- invoice -->
                {{
                  portalData.Contract.PaymentProvider === "InvoicePayment"
                    ? "Rechnung"
                    : ""
                }}
                <!-- Lastschrift -->
                {{
                  portalData.Contract.PaymentBearer.IBAN
                    ? "SEPA Lastschrift"
                    : ""
                }}
                <!-- Kreditkarte -->
                {{
                  portalData.Contract.PaymentBearer.Type == "CreditCard"
                    ? "Kreditkarte"
                    : ""
                }}
              </p>
            </div>
            <div>
              <div v-if="portalData.Customer.VatId">Umsatzsteuer-ID</div>
              <p class="text-sm-h6 text--primary">
                {{ portalData.Customer.VatId ? portalData.Customer.VatId : "" }}
              </p>
            </div>
          </v-row>
        </v-container>
        <!-- Display only when payment method is Lastschrift-->
        <v-container fluid v-if="portalData.Contract.PaymentBearer">
          <v-row>
            <div class="float-left mr-5">
              <div v-if="portalData.Contract.PaymentBearer.Holder">
                Kontoinhaber
              </div>
              <p class="text-sm-h6 text--primary">
                {{
                  portalData.Contract.PaymentBearer.Holder
                    ? portalData.Contract.PaymentBearer.Holder
                    : ""
                }}
              </p>
            </div>
            <div>
              <div v-if="portalData.Contract.PaymentBearer.IBAN">IBAN</div>
              <p class="text-sm-h6 text--primary">
                {{
                  portalData.Contract.PaymentBearer.IBAN
                    ? portalData.Contract.PaymentBearer.IBAN
                    : ""
                }}
              </p>
            </div>
          </v-row>
        </v-container>
        <!-- Display only when payment method is credit card-->
        <v-container
          fluid
          v-if="
            portalData.Contract.PaymentBearer &&
            portalData.Contract.PaymentBearer.Type == 'CreditCard'
          "
        >
          <v-row>
            <div class="float-left mr-5">
              <div v-if="portalData.Contract.PaymentBearer.Holder">
                Karteninhaber
              </div>
              <p class="text-sm-h6 text--primary">
                {{
                  portalData.Contract.PaymentBearer.Holder
                    ? portalData.Contract.PaymentBearer.Holder
                    : ""
                }}
              </p>
            </div>
            <div>
              <div v-if="portalData.Contract.PaymentBearer.CardType">
                Kartentyp
              </div>
              <p class="text-sm-h6 text--primary">
                {{
                  portalData.Contract.PaymentBearer.CardType
                    ? portalData.Contract.PaymentBearer.CardType
                    : ""
                }}
              </p>
            </div>
          </v-row>
          <v-row>
            <div class="float-left mr-5">
              <div v-if="portalData.Contract.PaymentBearer.MaskedCardPan">
                Kartennummer
              </div>
              <p class="text-sm-h6 text--primary">
                {{
                  portalData.Contract.PaymentBearer.MaskedCardPan
                    ? portalData.Contract.PaymentBearer.MaskedCardPan
                    : ""
                }}
              </p>
            </div>
            <div>
              <div
                v-if="
                  portalData.Contract.PaymentBearer.ExpiryMonth &&
                  portalData.Contract.PaymentBearer.ExpiryYear
                "
              >
                Gültig bis
              </div>
              <p class="text-sm-h6 text--primary">
                {{
                  portalData.Contract.PaymentBearer.ExpiryMonth &&
                  portalData.Contract.PaymentBearer.ExpiryYear
                    ? portalData.Contract.PaymentBearer.ExpiryMonth +
                      " / " +
                      portalData.Contract.PaymentBearer.ExpiryYear
                    : ""
                }}
              </p>
            </div>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-actions
      ><v-spacer></v-spacer>
      <v-btn color="primary" text @click="openBillwerkPortal"
        >Daten ändern / Zahlungsmethode ändern</v-btn
      >
      <!--v-btn color="primary" text @click="openCustomerDataForm"
        >Daten ändern</v-btn
      -->
    </v-card-actions>
    <customerDataForm ref="customerDataForm" :data="customerData" />
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { getBillwerkPortalUrl } from "../../../../api.js";
import customerDataForm from "./forms/customerDataForm.vue";
export default {
  components: { customerDataForm },
  data() {
    return {
      customerData: {
        open: false,
        title: "Daten ändern",
        item: null,
        method: "edit",
      },
      paymentMethod: {
        open: false,
        title: "Zahlungsdaten ändern",
        item: null,
        method: "edit",
      },
    };
  },
  computed: {
    ...mapGetters(["portalData", "portalDataLoading"]),
  },
  methods: {
    openCustomerDataForm() {
      this.customerData.open = true;
      //clone table item to decouple reference from table
      this.$refs.customerDataForm.triggerEdit(
        Object.assign({}, this.portalData.Customer)
      );
    },
    openBillwerkPortal() {
      this.$store.dispatch("getBillwerkToken").then((r) => {
        const url = getBillwerkPortalUrl() + "portal/account.html#/" + r;
        window.open(url, "_blank");
      });
    },
  },
};
</script>
