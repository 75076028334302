<template>
  <div>
    <v-row class="my-3">
      <v-icon class="mr-2" disabled>attach_file</v-icon>
      <div class="subtitle-1">{{ $t("tickets.view.attachments") }}</div>
    </v-row>
    <div class="ml-5">
      <div>
        <div v-for="(item, index) in attachments" v-bind:key="index">
          <v-card flat outlined class="my-2">
            <v-card-title class="subtitle-2 py-1">
              <v-icon class="mr-2">{{ fileIcons[item.type] }}</v-icon>{{ item.name }}
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey"
                icon
                @click="downloadItem(item)"
                data-cy="back"
              >
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn
                v-if="isImage(item.type)"
                color="grey"
                icon
                @click="viewInScreen(item)"
              >
                <v-icon>visibility</v-icon>
              </v-btn>
              <v-btn
                color="grey"
                icon
                @click="deleteAttachment(item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-card-title>
            <div v-if="isImage(item.type) && item.imageUrl" class="image-preview">
              <img :src="item.imageUrl" :alt="item.name" class="large-preview-image" />
            </div>
          </v-card>
        </div>
        <div class="subtitle-1 mt-5">{{ $t("tickets.view.addFile") }}</div>
        <input
          @change="handleImage"
          class="mt-2"
          type="file"
          accept="image/png, image/jpeg, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      pushAttachment: {
        content: "",
        user: null,
        timestamp: null,
      },
      attachments: [],
      fileIcons: {
        zip: "mdi-folder-zip",
        rar: "mdi-folder-zip",
        htm: "mdi-language-html5",
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-json",
        md: "mdi-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        JPG: "mdi-file-image",
        jpeg: "mdi-file-image",
        mp4: "mdi-filmstrip",
        mkv: "mdi-filmstrip",
        avi: "mdi-filmstrip",
        wmv: "mdi-filmstrip",
        mov: "mdi-filmstrip",
        txt: "mdi-file-document",
        xls: "mdi-file-excel",
        xlsx: "mdi-file-excel",
        other: "mdi-file-outline",
      },
    };
  },
  methods: {
    isImage(type) {
      return ["png", "jpg", "jpeg"].includes(type.toLowerCase());
    },
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      if (selectedImage.size > 5000000) { // 5MB limit
        alert("File size should be less than 5MB");
        return;
      }
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //post attachment
        let attachment = {
          type: fileObject.name.match(/\.[0-9a-z]+$/i)[0].split(".")[1],
          name: fileObject.name,
          content: e.target.result.split(",")[1],
          id: this.item._id,
        };
        this.$store.dispatch("addAttachmentsToTicket", attachment).then(() => {
          //reload data
          this.attachments.push(attachment);
          if (this.isImage(attachment.type)) {
            this.getDownloadItemLink(attachment).then((url) => {
              attachment.imageUrl = url;
            });
          }
        });
      };
      reader.readAsDataURL(fileObject);
    },
    deleteAttachment(val) {
      this.$store
        .dispatch("removeAttachmentsfromTicket", {
          id: this.item._id,
          location: val.location,
        })
        .then(() => {
          //reload data
          this.attachments = this.attachments.filter(
            (attachment) => attachment.location !== val.location
          );
        });
    },
    loadAttachments() {
      //set attachments
      this.$store
        .dispatch("getAttachmentsForTicket", this.item._id)
        .then((r) => {
          this.attachments = r;
          const imageAttachments = this.attachments.filter((attachment) => this.isImage(attachment.type));
          Promise.all(
            imageAttachments.map(async (attachment) => {
              attachment.imageUrl = await this.getDownloadItemLink(attachment);
              return attachment;
            })
          ).then((updatedAttachments) => {
            this.attachments = [
              ...this.attachments.filter((attachment) => !this.isImage(attachment.type)),
              ...updatedAttachments,
            ];
          });
        });
    },
    createFormPath(item) {
      if (item) {
        return (
          "Tickets/" + this.item._id + "/" + item.location + "." + item.type
        );
      }
    },
    async downloadItem(item) {
      try {
        const url = await this.getDownloadItemLink(item);
        if (url) {
          window.open(url, "_blank");
        }
      } catch (error) {
        console.error("Failed to download the item", error);
      }
    },
    async viewInScreen(item) {
      try {
        const url = await this.getDownloadItemLink(item);
        if (url) {
          const image = new Image();
          image.src = url;
          const w = window.open("", "Image Preview");
          w.document.write(image.outerHTML);
        }
      } catch (error) {
        console.error("Failed to open the item in screen", error);
      }
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
  },
};
</script>

<style>
.image-preview {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  margin: 10px 0;
}

.large-preview-image {
  max-width: 200px;
  max-height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>