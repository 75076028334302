import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { de },
      current: 'de',
    },
    theme: {
      themes: {
        light: {
          primary: "#1A5561",
          secondary: "#45D6F4",
          accent: '#8c9eff',
          error: '#b71c1c',
        },
      },
    },
  icons: {
    iconfont: 'md',
  },
});
