<template>
  <div>
    <v-switch
      :label="$t('incidentReports.incidentReportFormLong.temporaryWorker')"
      v-model="value.temporaryWorker"
    ></v-switch>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data: () => ({}),
  methods: {},
};
</script>
