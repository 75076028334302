<template>
  <!--translate-->
  <div>
    <div v-if="item.role == 'User'">Benutzer</div>
    <div v-if="item.role == 'Owner'">Administrator</div>
    <div v-if="item.role == 'MultiUser'">Multiuser (FASI)</div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  methods: {},
};
</script>
